import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import React from "react";
import { LocalizedText } from "../LocalizedText";
import EventIcon from "@material-ui/icons/Event";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { MediaCardContainerProps, MediaCardProps } from "./interfaces";
import { useCardStyles, useStyles } from "./styles";
import TableContainer from "@material-ui/core/TableContainer";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { Skeleton } from "@material-ui/lab";
import Info from "@material-ui/icons/Info";
import Box from "@material-ui/core/Box";

/**
 * Card list view component
 * Displays the media cards
 *
 * This component is entirely "controlled", meaning that
 * it doesn't hold state about the current sort direction
 * - its parent is responsible for passing this back in
 * on each occurrence of onSort.
 *
 * This makes it easy to load the component with an initial
 * sort (e.g. when deeplinking)
 *
 * @see https://reactjs.org/docs/forms.html#controlled-components
 */
export const MediaCardContainer: React.FC<MediaCardContainerProps> = ({
  events,
  loading,
  error,
  onSort,
  sort,
  numMedia,
  onSelect,
  selected,
  collapsed,
}) => {
  const classes = useStyles({ loading });

  return (
    <TableContainer className={classes.cardContainer} component={Paper}>
      {loading && (
        <div
          data-testid="media-loading"
          // className={classes.cardContainerLoading}
        >
          <Grid className={classes.cards} container spacing={1}>
            <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
              <Skeleton variant="rect" height="180px" />
            </Grid>
            <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
              <Skeleton variant="rect" height="180px" />
            </Grid>
            <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
              <Skeleton variant="rect" height="180px" />
            </Grid>
            <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
              <Skeleton variant="rect" height="180px" />
            </Grid>
            <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
              <Skeleton variant="rect" height="180px" />
            </Grid>
            <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
              <Skeleton variant="rect" height="180px" />
            </Grid>
            <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
              <Skeleton variant="rect" height="180px" />
            </Grid>
            <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
              <Skeleton variant="rect" height="180px" />
            </Grid>
          </Grid>
        </div>
      )}
      {!loading && !error && events.length === 0 && (
        <div className={classes.emptyMessage}>
          <Alert severity="info">
            <LocalizedText text="no_media_in_date_range" />
          </Alert>
        </div>
      )}
      {error && (
        <div className={classes.emptyMessage}>
          <Alert severity="error">
            <LocalizedText text="error_loading_media" />
          </Alert>
        </div>
      )}
      <Grid className={classes.cards} container spacing={1}>
        {events.map((e) => (
          <Grid item xs={12} sm={6} md={collapsed ? 6 : 3} style={{}}>
            <MediaCard
              key={e.id}
              event={e}
              onSelect={onSelect}
              selected={selected?.id === e.id}
            />
          </Grid>
        ))}
      </Grid>
    </TableContainer>
  );
};

/**
 * An individual media card
 */
export const MediaCard: React.FC<MediaCardProps> = ({
  event,
  onSelect,
  selected,
}) => {
  const { vehicle } = event;
  // const { t } = useTranslation();
  const classes = useCardStyles({ event });

  return (
    <Card
      className={clsx({
        [classes.card]: true,
        [classes.selected]: selected,
      })}
      onClick={() => {
        onSelect(event);
      }}
    >
      {event.thumbnail ? (
        <CardMedia className={classes.media} image={event.thumbnail} />
      ) : (
        <Box
          className={classes.no_thumbnail}
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Alert severity="info" className={classes.alert}>
              <LocalizedText text="preview_unavailable" />
            </Alert>
          </Box>
        </Box>
      )}
      <CardContent className={classes.content}>
        <Grid container>
          <Grid container item xs={12} alignItems="center">
            <DriveEtaIcon className={classes.icon} />
            <span className={classes.text}>
              {vehicle.registration} {vehicle.driver && `(${vehicle.driver})`}
            </span>
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Tooltip title="Event Date" placement="right-start">
              <EventIcon className={classes.icon} />
            </Tooltip>
            <div className={classes.text}>{event.date}</div>
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Tooltip title="Type" placement="right-start">
              <Info className={classes.icon} />
            </Tooltip>
            <div className={classes.text}>
              <LocalizedText text={event.type.toLowerCase()} />
              {event.severity !== "NONE" && ` - ${event.severity}`}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
