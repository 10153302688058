import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "640px",
      display: "flex",
      flexDirection: "column",
    },
    playerContainer: {
      marginBottom: "4px",
    },
    player: {
      height: "100%",
      width: "100%",
      background: "black",
    },
    placeholder: {
      height: "100%",
      width: "100%",
      backgroundColour: "#222",
    },
    error: {
      color: "red",
      textAlign: "center",
    },
    description: {
      color: theme.palette.secondary.main,
    },
  })
);
