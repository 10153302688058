import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

/**
 * Determines whether the viewport is tablet or smaller size. Updates on
 * window resize thanks to useMediaQuery
 *
 * Important: this does not guarantee an actual mobile device is being used,
 * just that the viewport is roughly the right size for one (we don't do any
 * user agent sniffing, for example)
 */
export function useIsMobileSize(): {
  isMobileSize: boolean;
  isTabletSize: boolean;
} {
  const theme = useTheme();
  return {
    isMobileSize: useMediaQuery(theme.breakpoints.down("sm")),
    isTabletSize: useMediaQuery(theme.breakpoints.only("md")),
  };
}
