import { useState } from "react";
import useLiveApi from "./use-live-api";
import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
import { Journey, JourneySummary } from "../interfaces";

interface JourneysApi {
  getJourneySummaries: ({
    vehicleId,
    date,
  }: {
    vehicleId: string;
    date: string;
  }) => Promise<JourneySummary[] | undefined>;
  requestVideoForDate: (
    date: string,
    vehicleId: string,
    location: string,
    heading: number
  ) => Promise<void>;
  getJourney: ({
    vehicleId,
    journeyId,
  }: {
    vehicleId: string;
    journeyId: string;
  }) => Promise<Journey | undefined>;
  loading: boolean;
  error: boolean;
}

export const useJourneysApi = (): JourneysApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getJourneySummaries = async ({
    vehicleId,
    date,
  }: {
    vehicleId: string;
    date: string;
  }) => {
    try {
      const url = buildJourneySummariesUrl(vehicleId, date);
      setLoading(true);
      const response = await apiGatewayFetch(url, "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const journeySummaries = result as JourneySummary[];

      return journeySummaries;
    } catch (error) {
      setError(true);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const getJourney = async ({
    vehicleId,
    journeyId,
  }: {
    vehicleId: string;
    journeyId: string;
  }) => {
    try {
      const url = `/journey/${vehicleId}/${journeyId}`;

      setLoading(true);
      const response = await apiGatewayFetch(url, "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const journey = result as Journey;

      return journey;
    } catch (e) {
      console.error("error fetching journey", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const requestVideoForDate = async (
    date: string,
    vehicleId: string,
    location: string,
    heading: number
  ) => {
    const id = uuid();

    const body = {
      id,
      vehicleId,
      date: date,
      location: location,
      heading: heading,
    };

    try {
      setLoading(true);
      const response = await apiGatewayFetch(
        "/event/vod",
        "post",
        JSON.stringify(body)
      );
      if (response.status !== 200) {
        setError(true);

        throw new Error(response.status.toString());
      }
    } catch (e) {
      console.error("error requesting video", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    getJourneySummaries,
    requestVideoForDate,
    getJourney,
    loading,
    error,
  };
};

export const buildJourneySummariesUrl = (
  vehicleId: string,
  date: string
): string => {
  //Preserve offset using setZone so we can recreate the date
  const iso = DateTime.fromISO(date, { setZone: true });

  return `/journeys/${vehicleId}?date=${iso.toFormat("yyyy-MM-dd")}`;
};
