import { CameraStatus } from "../../interfaces";
import { StyledTableCell, StyledTableRow } from "../CalendarRecordings/styles";
import { Table, TableBody, TableHead } from "@material-ui/core";
import { ReportTableProps } from "./interfaces";

export const ReportTable: React.FC<ReportTableProps> = ({
  reportData,
  headers,
}) => {
  return (
    <Table aria-label="Device Statuses">
      <TableHead>
        <StyledTableRow>
          {headers.map((header) => {
            return <StyledTableCell align="center">{header}</StyledTableCell>;
          })}
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {reportData &&
          reportData?.map((data, index) => (
            <ReportDataRow key={index} reportData={data} />
          ))}
      </TableBody>
    </Table>
  );
};

const ReportDataRow: React.FC<{ reportData: CameraStatus }> = ({
  reportData,
}) => {
  function convertUTCToLocal(utcTimeString: string | undefined): string {
    if (!utcTimeString) {
      return "Unknown";
    }
    const utcDate = new Date(utcTimeString);

    return utcDate.toLocaleString();
  }

  return (
    <>
      <StyledTableRow data-testid="report-data-row">
        <StyledTableCell component="th" scope="row" align="center">
          {reportData.accountName}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {reportData.salesforceId}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {reportData.imei}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {reportData.vehicleRegistration}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {reportData.deviceStatus?.toUpperCase()}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {reportData.commissionedStatus?.toUpperCase()}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {convertUTCToLocal(reportData.commissionedDate)}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {convertUTCToLocal(reportData.lastConnection)}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {convertUTCToLocal(reportData.lastDisconnection)}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {convertUTCToLocal(reportData.lastHeartbeat)}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};
