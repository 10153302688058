import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 0,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    datePickerPopup: {
      maxHeight: 600,
    },
  })
);
