import { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { ScrollToTopProps } from "./interfaces";

/**
 * This component is intended for use as the parent
 * of the <Switch> component within <Router>
 * It forces the page to return to the top when
 * navigating between routes.
 *
 * Example usage:
 *
 * <Router>
 *   <ScrollToTop>
 *     <Switch>
 *       <Route path="/events"><EventList /></Route>
 *       <Route path="/"><Home /></Route>
 *     </Switch>
 *   </ScrollToTop>
 * </Router>
 */
const ScrollToTopView: React.FC<ScrollToTopProps> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
};

export const ScrollToTop = withRouter(ScrollToTopView);
