import React from "react";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AccountIcon from "@material-ui/icons/EmojiTransportation";

import { LocalizedText } from "../LocalizedText";
import { Item, ItemList } from "../ItemList";
import { AddButton, DeleteButton, Panel, PanelHeader } from "../Panel";

import { AccountListViewProps } from "./interfaces";
import { accountsToItems, matchesCaseInsensitive } from "./transformers";
import { useTranslation } from "../../hooks/i18n";
import { LocalizedTextField } from "../Input";

export const AccountListView: React.FC<AccountListViewProps> = ({
  accounts,
  loading,
  error,
  onDelete,
  deleteSuccess,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [checkedItems, setCheckedItems] = React.useState<Item[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredAccounts = accounts?.filter((account) => {
    if (!searchTerm) return true;
    return matchesCaseInsensitive(searchTerm, account.name);
  });

  /**
   * Convert accounts response to <ItemList /> shape
   */
  const accountsAsItems = filteredAccounts
    ? accountsToItems(filteredAccounts)
    : [];

  /**
   * Navigate to specific account when selected
   */
  const handleItemClick = (item: Item) => {
    history.push(`/accounts/summary/${item.id}`);
  };

  /**
   * Add or remove an item from state when checked/unchecked
   */
  const handleItemCheck = (item: Item, checked: boolean) => {
    setCheckedItems((checkedItems) => {
      const newCheckedItems = [...checkedItems];
      if (checked) {
        newCheckedItems.push(item);
      } else {
        newCheckedItems.splice(checkedItems.indexOf(item), 1);
      }
      return newCheckedItems;
    });
  };

  /**
   * Handle delete button click
   */
  const handleDelete = async () => {
    if (!window.confirm(t("confirm_delete_items"))) return;
    onDelete(checkedItems);
  };

  /**
   * Navigate to create account when create button pressed
   */
  const handleCreate = () => {
    history.push("/accounts/create");
  };

  const header = (
    <PanelHeader icon={<AccountIcon />} title={t("accounts")}>
      {Boolean(checkedItems.length) && <DeleteButton onClick={handleDelete} />}

      <AddButton label="create_account" onClick={handleCreate} />
    </PanelHeader>
  );

  return (
    <Panel header={header}>
      {deleteSuccess && (
        <Alert severity="info">
          <LocalizedText text="delete_success" />
        </Alert>
      )}

      {error && (
        <Alert severity="error" data-testid="accounts-error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}

      <div aria-label={loading ? t("loading") : undefined}>
        <LocalizedTextField
          id="search"
          label="search"
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            setSearchTerm(value);
          }}
          value={searchTerm}
          placeholder="search_by_account"
          size="small"
          fullWidth
        />
        <ItemList
          items={accountsAsItems}
          loading={loading}
          onItemClick={handleItemClick}
          onItemCheck={handleItemCheck}
          checkboxes
        />
      </div>
    </Panel>
  );
};
