import React, { ChangeEvent } from "react";
import { RegionSelectorProps } from "./interfaces";
import { RegionSelectorView } from "./views";

export const RegionSelector: React.FC<RegionSelectorProps> = React.memo(
  ({ selected, onRegionChange }) => {
    const [region, setRegion] = React.useState(selected);

    const handleRegionChange = (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
      const value = event.target.value as string;
      setRegion(value);
      onRegionChange(value);
    };
    const regions = ["GB", "US", "CA"];

    return (
      <RegionSelectorView
        selected={region}
        regions={regions}
        onRegionChange={handleRegionChange}
      />
    );
  }
);
