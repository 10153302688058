import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Avatar } from "../Avatar";
import { LocalizedButton } from "../Button";
import { LocalizedText } from "../LocalizedText";
import { RequestedTooltipProps, RequestMediaModalProps } from "./interfaces";
import { useStyles } from "./styles";

/**
 * A modal to explain to the user that media requests will
 * only complete when the vehicle is on and has signal
 */
export const RequestMediaModal: React.FC<RequestMediaModalProps> = ({
  open = false,
  onClose,
  onConfirm,
}) => {
  const [checked, setChecked] = React.useState(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="simple-dialog-title">
        <LocalizedText text="confirm_media_title" />
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <LocalizedText text="confirm_media_body" />
        </DialogContentText>

        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              name="request-media-checkbox"
            />
          }
          label={<LocalizedText text="dont_ask_again" />}
        />
      </DialogContent>

      <DialogActions>
        <LocalizedButton onClick={onClose} color="primary" variant="text">
          cancel
        </LocalizedButton>
        <LocalizedButton
          onClick={() => {
            onConfirm(checked);
          }}
          autoFocus
        >
          confirm_media_ok
        </LocalizedButton>
      </DialogActions>
    </Dialog>
  );
};

/**
 * A tooltip view to show who has requested a media item
 */
export const RequestedTooltip: React.FC<RequestedTooltipProps> = ({
  firstName,
  lastName,
  isCurrentUser,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.requestedBy}>
        <Typography>
          {isCurrentUser ? (
            <LocalizedText text="requested_by_you" />
          ) : (
            <>
              <LocalizedText text="requested_by" /> {firstName} {lastName}
            </>
          )}
        </Typography>
        <Avatar firstName={firstName} lastName={lastName} size="xs" />
      </div>

      <Typography>
        <LocalizedText
          text={
            isCurrentUser
              ? "media_pending_hint_current_user"
              : "media_pending_hint"
          }
        />
      </Typography>
    </>
  );
};
