import { Box, Paper } from "@material-ui/core";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import React, { FormEvent, SyntheticEvent } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Button } from "../Button";
import { DrivingEvent } from "../../interfaces";
import { MediaStatuses } from "../EventDetail/constants";
import { useStyles } from "./styles";
import Alert from "@material-ui/lab/Alert";
import { LocalizedText } from "../LocalizedText";
import { TooltipPopover } from "../TooltipPopover";
import { RequestedTooltip } from "../RequestMediaModal";
import { UserContext } from "../../contexts/UserContext";
import { RequestVideoDialog } from "./RequestVideoDialog";

export const VideoScrubber: React.FC<{
  video: ".mp4" | string | undefined;
  event: DrivingEvent;
}> = ({ video, event }) => {
  const media = event.media[0];
  const { ctxUser } = React.useContext(UserContext);
  const classes = useStyles();
  /**
   * We need to know the frame rate to determine the step/interval of the slider
   */
  const FRAME_RATE = 5;
  /**
   * Current state of video
   */
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
  /**
   * Duration is required to set the values for the slider
   */
  const [duration, setDuration] = React.useState<number>();
  /**
   * The number of the currently displayed frame, as inferred from the video's
   * current time
   */
  const [frameNumber, setFrameNumber] = React.useState<number>(0);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  /**
   * Refs for our various elements
   */
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const slideRef = React.useRef<HTMLInputElement>(null);

  /**
   * Wait for video metadata to load before trying to get duration
   */
  const handleLoad = async () => {
    setDuration(videoRef?.current?.duration);
  };

  /**
   * Update the video time when the slider is moved
   */
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (videoRef?.current) {
      videoRef.current.currentTime = Number(e.currentTarget.value);
    }
  };

  /**
   * Update the currently selected frame and the slider when the video time changes
   */
  const handleTimeUpdate = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
    const { currentTime } = e.currentTarget;

    const frameNumber = Math.round(currentTime * FRAME_RATE);
    setFrameNumber(frameNumber);
    if (slideRef?.current) {
      /**
       * Update slider position
       */
      slideRef.current.value = currentTime.toString();

      //Stop playing at end of video and allow user to restart video
      if (slideRef.current.value === (duration && duration.toString())) {
        setIsPlaying(false);
      }
    }
  };

  const [requestData, setRequestData] = React.useState<{
    startDate: string;
    endDate: string;
    frameTime: string;
  }>({ startDate: "", endDate: "", frameTime: "" });

  React.useEffect(() => {
    if (isRequesting) {
      handlePause();
    }
  }, [isRequesting]);

  const handleRequest = () => {
    if (duration && event.hyperlapseFrames) {
      setRequestData({
        startDate: event.hyperlapseFrames[0],
        endDate: event.hyperlapseFrames[event.hyperlapseFrames.length - 1],
        frameTime: event.hyperlapseFrames[frameNumber],
      });
      setIsRequesting(true);
    }
  };

  const handlePause = () => {
    if (videoRef?.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const handlePlay = () => {
    setIsRequesting(false);
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleNext = () => {
    if (videoRef?.current) {
      let updatedTime = videoRef.current.currentTime;

      updatedTime += 1 / FRAME_RATE;
      videoRef.current.currentTime = updatedTime;
      setFrameNumber(Math.round(updatedTime * FRAME_RATE));
    }
  };

  const handlePrev = () => {
    if (videoRef?.current) {
      let updatedTime = videoRef.current.currentTime;

      updatedTime -= 1 / FRAME_RATE;
      videoRef.current.currentTime = updatedTime;
      setFrameNumber(Math.round(updatedTime * FRAME_RATE));
    }
  };

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  let infoAlert = null;
  let popoverContent = null;

  switch (media.status) {
    case MediaStatuses.pending:
      infoAlert = (
        <Alert className={classes.alert} severity="info">
          <LocalizedText text="video_requested" />
        </Alert>
      );

      if (media.requestedBy) {
        const { requestedBy } = media;
        popoverContent = (
          <RequestedTooltip
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the API guarantees that if media has been requested this property will be present
            firstName={requestedBy!.firstName}
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see above
            lastName={requestedBy!.lastName}
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see above
            isCurrentUser={ctxUser?.id === requestedBy!.id}
          />
        );
      }
      break;
    case MediaStatuses.unavailable:
    case MediaStatuses.failed:
      infoAlert = (
        <Alert className={classes.alert} severity="warning">
          <LocalizedText text="video_unavailable" />
        </Alert>
      );

      popoverContent = <LocalizedText text="media_unavailable_hint" />;
      break;
  }

  return (
    <Paper className={classes.card}>
      <div className={classes.media}>
        {!video && (
          <Alert severity="info">
            <LocalizedText text="media_not_requested" />
          </Alert>
        )}

        {video && (
          <video
            onLoadedMetadata={handleLoad}
            onTimeUpdate={handleTimeUpdate}
            ref={videoRef}
            src={video}
            preload="true"
          />
        )}
      </div>
      {/* Video Controls */}
      {video && Boolean(duration) && (
        <input
          ref={slideRef}
          style={{ width: "100%" }}
          type="range"
          defaultValue="0"
          min="0"
          max={duration}
          onChange={handleChange}
          step="0.001"
        />
      )}
      <div className={classes.toolbar}>
        {video && (
          <Box display="flex" justifyContent="center">
            <Box marginRight={1}>
              <Button onClick={handlePrev} disabled={isPlaying}>
                <NavigateBeforeIcon />
              </Button>
            </Box>
            <Box marginRight={1}>
              {!isPlaying && (
                <Button onClick={handlePlay}>
                  <PlayIcon />
                </Button>
              )}
              {isPlaying && (
                <Button onClick={handlePause}>
                  <PauseIcon />
                </Button>
              )}
            </Box>
            <Box marginRight={1}>
              <Button onClick={handleNext} disabled={isPlaying}>
                <NavigateNextIcon />
              </Button>
            </Box>
            <Box marginRight={1}>
              <Button onClick={handleRequest}>Request Clip</Button>
            </Box>
          </Box>
        )}
        {!video && (
          <Box
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {infoAlert}
            <TooltipPopover
              id="pending_hyperlapse_popover"
              open={popoverOpen}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {popoverContent}
            </TooltipPopover>
          </Box>
        )}
      </div>

      {requestData.endDate && requestData.frameTime && (
        <RequestVideoDialog
          show={isRequesting}
          onClose={() => setIsRequesting(false)}
          heading={event.heading}
          location={event.location}
          address={event.address}
          vehicle={event.vehicle}
          startDate={requestData.startDate}
          endDate={requestData.endDate}
          frameTime={requestData.frameTime}
          utc={requestData.frameTime}
        />
      )}
    </Paper>
  );
};
