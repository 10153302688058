import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";

import { theme } from "./theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./services/i18n";
import TagManager from "react-gtm-module";
import { authConfig } from "./interfaces";
import { AuthProvider } from "react-oauth2-code-pkce";

const useMocks = process.env.REACT_APP_USE_MOCKS;
if (useMocks && useMocks !== "0" && useMocks !== "false") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires -- we only want this import in dev, so has to be a require() call
  const { worker } = require("./mocks/browser");
  worker.start();
}

TagManager.initialize({
  gtmId: process.env.REACT_APP_TAG_MANAGER_ID || "",
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider authConfig={authConfig}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
