// Container should contain any external API requests
import React from "react";
import { RequestVideoDialogProps } from ".";
import { RequestVideoDialogView } from "./views";
import { useEventApi } from "../../../hooks/use-event";

export const RequestVideoDialog: React.FC<RequestVideoDialogProps> = (
  props
) => {
  const { requestVod } = useEventApi();
  const handleRequestVideo = (
    vehicleId: string,
    date: string,
    location: string | undefined,
    heading: number,
    address: string | null,
    utc: string,
    channel: number,
    userId: string
  ) => {
    return requestVod({
      vehicleId,
      date,
      location,
      heading,
      address,
      utc,
      channel,
      userId,
    });
  };
  return (
    <RequestVideoDialogView {...props} onRequestVideo={handleRequestVideo} />
  );
};
