import { Vehicle } from "../../interfaces";
import { VehicleFormData } from "./interfaces";

/**
 * Transforms a vehicle into the shape required for the form.
 *
 * - Flattens cameras into a list of IDs
 * - Removes alerts if present (we don't deal with them in this form)
 */
export function vehicleToFormValues(vehicle: Vehicle): VehicleFormData {
  return {
    registration: vehicle.registration,
    driver: vehicle.driver,
    description: vehicle.description,
    cameras: vehicle.cameras.map((camera) => camera.id),
  };
}
