import faker from "faker";
import { Vehicle } from "../interfaces";
import { makeCameras } from "./cameras";
import { randomArrayElement, randomBool } from "./helpers";

export function makeVehicle(
  data: Partial<Vehicle> = {},
  containUnknowns: boolean
): Vehicle {
  return {
    id: faker.datatype.uuid(),
    driver: `${faker.name.firstName()} ${faker.name.lastName()}`,
    cameras: makeCameras(2),
    description: faker.lorem.sentence(),
    registration: faker.vehicle.vrm(),
    accountId: faker.datatype.uuid(),
    cameraOverrun: 10,
    ...(((containUnknowns && randomBool(80)) || !containUnknowns) && {
      status: {
        providerId: "MFL:1001",
        status: randomArrayElement(["ONLINE", "OFFLINE"]),
        lastConnection: new Date().toISOString(),
        lastKnownLocation: `${faker.address.latitude(
          54,
          53
        )},${faker.address.longitude(-1, -2)}`,
        address: faker.address.streetAddress(),
        heading: Math.round(Math.random() * 360),
        speed: Math.random() * 60,
        ignitionState: randomArrayElement([true, false]),
        cameraOverrun: 10,
      },
    }),
    ...data,
  };
}

export function makeVehicles(
  num: number,
  containUnknowns: boolean,
  dataFunc?: () => Partial<Vehicle>
): Vehicle[] {
  return Array(num)
    .fill("")
    .map((_) => makeVehicle(dataFunc ? dataFunc() : {}, containUnknowns));
}
