import { useTranslation } from "../../hooks/i18n";

/**
 * Use the value set by i18n and the browser to change the speed to the correct unit based on locaton
 *
 * This is assuming the speed value given is in MPH
 * @param speed Miles per hour
 * @returns Value in correct local format
 */
export const LocalizedSpeed: React.FC<{ speed: number }> = ({ speed }) => {
  const { i18n } = useTranslation();

  const kph = () => {
    return Math.round(speed * 1.609344);
  };

  switch (i18n.language) {
    case "en-CA":
      speed = kph();
      break;
    default:
      break;
  }
  return <>{Math.round(speed)}</>;
};
