import { DateTime } from "luxon";

/**
 * Calculates a start and end date by adding the duration in minutes to the original start datetime
 * @param start Start DateTime in format dd/MM/yyyy hh:mm:ss
 * @param duration Duration in minutes
 */
export const calculateDatesFromDateAndDuration = (
  start: string,
  duration: number
): { startDate: string; endDate: string } => {
  // Attempt to parse the input as an ISO date first (handles "yyyy-MM-dd'T'HH:mm:ss")
  let parsedDate = DateTime.fromISO(start);

  // If the ISO parsing fails, try parsing it with the custom format "yyyy-MM-dd hh:mm:ss"
  if (!parsedDate.isValid) {
    parsedDate = DateTime.fromFormat(start, "yyyy-MM-dd HH:mm:ss");
  }

  return {
    startDate: parsedDate.toISO(),
    endDate: parsedDate.plus({ minutes: duration }).toISO(),
  };
};
