import { TAuthConfig } from "react-oauth2-code-pkce";
import { MediaStatuses } from "./components/EventDetail/constants";

export type Json =
  | string
  | number
  | boolean
  | null
  | { [property: string]: Json }
  | Json[];

export interface DrivingEvent {
  id: string;
  cameraId: string;
  vehicle: Vehicle;
  type:
    | "ACCELERATION"
    | "BRAKING"
    | "CORNERING"
    | "BUMP"
    | "IMPACT"
    | "USER"
    | "HYPERLAPSE"
    | "Hyperlapse"
    | "VOD";
  location: string;
  address: string | null;
  date: string;
  severity: "MILD" | "MODERATE" | "SEVERE" | "NONE";
  media: DrivingEventMediaItem[];
  images: number;
  videos: number;
  thumbnail?: string;
  heading: number;
  hyperlapseFrames?: string[];
}
export interface DrivingEventMediaItem {
  channel: number;
  channelDesc: string;
  mediaType: "THUMBNAIL" | "IMAGE" | "VIDEO";
  status: "UNAVAILABLE" | "FAILED" | "REQUESTABLE" | "PENDING" | "AVAILABLE";
  link: string | null;
  requestedBy?: User;
}

export interface JourneyPing {
  location: string;
  time: string;
  speed: number;
  heading: number;
  vehicleId: string;
  event: {
    id: string;
    media: JourneyPingMediaItem[];
    type?: string;
  };
  isEvent?: boolean; // Describes if this ping was created from an Event rather than a Journey
}

export type JourneyPingMediaItem = Omit<
  DrivingEventMediaItem,
  "link" | "channelDesc"
>;

export type Sort = "asc" | "desc";

export interface JourneySummary {
  id: string;
  duration?: string;
  start: JourneyPing & {
    address: string;
  };
  end: {
    location?: string;
    time?: string;
    speed?: number;
    heading?: number;
    address?: string;
    vehicleId: string;
    event: {
      id: string;
      media: JourneyPingMediaItem[];
      type?: string;
    };
    isEvent?: boolean; // Describes if this ping was created from an Event rather than a Journey
  };
  inProgress: boolean;
  hyperlapseEventId?: string;
  media?: DrivingEventMediaItem[];
}
export type MediaType = "VIDEO" | "IMAGE" | "HYPERLAPSE";

export interface MediaRequest {
  id: string; //SORT KEY
  eventId: string;
  vehicleId: string;
  mediaType: MediaType;
  requestDate: string;
  eventDate: string;
  mediaStatus: MediaStatuses;
  channel: number;
  vehicle: Vehicle;
  link: string;
  requestedBy: User;
}

export interface Journey {
  id: string;
  tracks: JourneyPing[];
  inProgress: boolean;
}

export type VehicleStatusText = "ONLINE" | "OFFLINE";

export interface VehicleStatus {
  providerId: string;
  status: VehicleStatusText;
  lastConnection: string;
  lastKnownLocation: string;
  address: string;
  heading: number;
  speed: number;
  ignitionState: boolean;
  cameraOverrun: number;
}
export interface Vehicle {
  id: string;
  registration: string;
  driver: string;
  description: string;
  accountId: string;
  cameras: Camera[];
  status?: VehicleStatus;
  cameraOverrun: number;
}
/**
 * The body we send when creating or updating a vehicle is slightly different
 * than the payload received when fetching a vehicle.
 *
 * When fetching, the camera list contains an array of camera objects.
 *
 * When creating or updating, it's just an array of camera IDs (strings)
 */
export interface VehicleUpdateBody {
  id: string;
  registration: string;
  driver: string | null;
  description: string;
  accountId: string;
  cameras: string[];
}

export interface User {
  id: string;
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  salesforceContactId?: string;
}

export interface Camera {
  id: string;
  accountId: string;
  provider: string;
  providerId: string;
  deviceNumber: string;
  channels: { number: number; description: string }[];
}
/**
 * The body we send when creating or updating a camera is slightly different
 * than the payload received when fetching a camera.
 *
 * When fetching, the camera has a `providerId` field which is constructed by
 * the backend from the provider name and device ID
 *
 * When creating or updating, we don't send this field
 */
export type CameraUpdateBody = Omit<Camera, "providerId">;

export interface Account {
  id: string;
  countryIso: string;
  name: string;
  packageType?: PackageType;
  trialStartDate?: string;
  providers: string[];
  alerts: AccountAlert;
  salesforceId?: string;
  enableMultiProviderFeatures?: boolean;
}
export interface AccountAlert {
  drivingStyle: string[];
  impacts: boolean;
  recipients: string[];
}
/**
 * The body we send when creating or update an account
 * is slightly different than what we receive.
 *
 * When retreiving, providers is just an array of strings.
 * When sending, we need to send the username and password
 * of the provider as well
 */
export interface AccountProvider {
  name: string;
  username: string;
  password: string;
  fleetId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We're forced to include this to allow passing this type to service functions which take JSON
  [property: string]: any;
}
export interface AccountUpdateBody {
  id: string;
  name: string;
  countryIso: string;
  salesforceId: string;
  providers?: AccountProvider[];
  alerts?: AccountAlert;
  enableMultiProviderFeatures?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We're forced to include this to allow passing this type to service functions which take JSON
  [property: string]: any;
}

export interface SupportRequest {
  subject: string;
  message: string;
}

export interface HyperlapseRequest {
  datetime: string;
  duration: string;
  vehicleId?: string;
  startLocation?: string;
  startAddress?: string;
  startHeading?: number;
  journeyId?: string;
}

export interface HyperlapseRequestBody {
  startDate: string;
  endDate: string;
  vehicleId: string;
  startLocation?: string;
  startAddress?: string;
  startHeading?: number;
  journeyId?: string;
  channel: number;
  userId: string;
}

export interface RamVehicleCalendar {
  deviceNumber: string;
  totalMins: number;
  oldestEntry: string;
  entries: RamCalendarEntry[];
}

export interface RamCalendarEntry {
  startTime: string;
  endTime: string;
}

export interface CameraStatus {
  accountName: string;
  salesforceId: string;
  imei: string;
  vehicleRegistration: string | undefined;
  deviceStatus: string;
  commissionedStatus: string;
  commissionedDate: string | undefined;
  lastConnection: string;
  lastDisconnection: string;
  lastHeartbeat: string;
}

export const authConfig: TAuthConfig = {
  clientId: "ram-live",
  authorizationEndpoint:
    process.env.REACT_APP_SSO_API_URL + "/oauth2/authorize",
  tokenEndpoint: process.env.REACT_APP_SSO_API_URL + "/oauth2/token",
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI + "/",
  decodeToken: true,
  scope: "all",
  autoLogin: false,
  refreshTokenExpiresIn: 600000,
};

export enum PackageType {
  PLUS = "PLUS",
  CORE = "CORE",
  LITE = "LITE",
}
