import { useCallback, useEffect, useState } from "react";
import useLiveApi from "./use-live-api";
import { DateTime } from "luxon";
import { Filters } from "../components/EventFilters";
import { Sort } from "../components/EventList";
import { DrivingEvent } from "../interfaces";

interface JourneyEventsApi {
  data: DrivingEvent[];
  loading: boolean;
  error: boolean;
  nextPage: () => Promise<void>;
  prevPage: () => Promise<void>;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

const fetchItems = async (
  apiGatewayFetch: (
    url: string,
    method: string,
    body?: string
  ) => Promise<Response>,
  filters: Filters,
  sort: Sort,
  paginationToken?: string
) => {
  const url = buildEventsUrl({ filters, sort, token: paginationToken });
  const response = await apiGatewayFetch(url, "get");
  if (response.status !== 200) {
    throw new Error(response.status.toString());
  }
  return await response.json();
};

interface EventsApiProps {
  filters: Filters;
  sort: Sort;
}

export const useJourneyEventsApi = ({
  filters,
  sort,
}: EventsApiProps): JourneyEventsApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<DrivingEvent[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [prevTokens, setPrevTokens] = useState<string[]>([]);

  const loadPage = useCallback(
    async (paginationToken?: string) => {
      setLoading(true);
      try {
        const data = await fetchItems(
          apiGatewayFetch,
          filters,
          sort,
          paginationToken
        );
        setData(data.items as DrivingEvent[]);
        setNextToken(data.paginationToken);
        if (paginationToken) {
          setPrevTokens((prev) => [...prev, paginationToken]);
        } else {
          setPrevTokens([]);
        }
      } catch (e) {
        setError(e.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    },
    [apiGatewayFetch, filters, sort]
  );

  const nextPage = useCallback(async () => {
    if (nextToken) {
      await loadPage(nextToken);
    }
  }, [nextToken, loadPage]);

  const prevPage = useCallback(async () => {
    const prevToken = prevTokens[prevTokens.length - 1];
    if (prevToken) {
      await loadPage(prevToken);
      setPrevTokens((prev) => prev.slice(0, -1));
    }
  }, [prevTokens, loadPage]);

  const hasNextPage = !!nextToken;
  const hasPrevPage = prevTokens.length > 0;

  useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, []);

  return { data, nextPage, prevPage, hasNextPage, hasPrevPage, loading, error };
};

interface UseEvents {
  filters: Filters;
  sort: Sort;
  token?: string;
}

/**
 * Builds a URL to fetch driving events, applying the provided
 * filters and sort
 */
export function buildEventsUrl({ filters, sort, token }: UseEvents): string {
  let url = `/events`;
  const filterStrings: string[] = [];
  // Event type
  if (filters.type.length) {
    filterStrings.push(filters.type.map((value) => `type=${value}`).join("&"));
  }
  // Event severity
  if (filters.severity.length) {
    filterStrings.push(
      filters.severity.map((value) => `severity=${value}`).join("&")
    );
  }
  // Vehicle(s)
  if (filters.vehicle.length) {
    filterStrings.push(
      filters.vehicle.map((value) => `vehicle=${value}`).join("&")
    );
  }
  // Date range
  const [start, end] = filters.date;
  if (start) {
    filterStrings.push(`start=${DateTime.fromISO(start).toUTC().toISO()}`);
  }
  if (end) {
    filterStrings.push(`end=${DateTime.fromISO(end).toUTC().toISO()}`);
  }
  if (filters.videoOnly) {
    filterStrings.push(`videoOnly=${filters.videoOnly}`);
  }
  // Sorting
  filterStrings.push(`sort=${sort}`);

  // Build final string
  const filterString = filterStrings.join("&");
  if (filterString) url = `${url}?${filterString}`;

  if (token) {
    url = `${url}&token=${token}`;
  }

  return url;
}
