import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { Select, Checkbox } from "./views";
import { SelectProps, CheckboxProps } from "./interfaces";

import { useTranslation } from "../../hooks/i18n";
import { SimpleInputProps } from "./interfaces";
import { SimpleInput } from "./views";

/**
 * SimpleInput with automatic translation of label and placeholder
 */
export const LocalizedSimpleInput: React.FC<SimpleInputProps> = (props) => {
  const { t } = useTranslation();

  const localizedProps = { ...props };
  localizedProps.label = t(localizedProps.label);
  if (localizedProps.placeholder)
    localizedProps.placeholder = t(localizedProps.placeholder);

  return <SimpleInput {...localizedProps} />;
};

/**
 * TextField with automatic translation of label, placeholder, and helperText
 *
 * Note that label and helperText must be plain strings, not JSX
 */
export const LocalizedTextField: React.FC<TextFieldProps> = (props) => {
  const { t } = useTranslation();

  const localizedProps = { ...props };
  localizedProps.label = t(localizedProps.label as string);
  if (localizedProps.helperText)
    localizedProps.helperText = t(localizedProps.helperText as string);
  if (localizedProps.placeholder)
    localizedProps.placeholder = t(localizedProps.placeholder);

  return <TextField {...localizedProps} />;
};

/**
 * Select with automatic translation of label, placeholder, and helperText
 *
 * Note that label and helperText must be plain strings, not JSX
 */
export const LocalizedSelect: React.FC<SelectProps> = (props) => {
  const { t } = useTranslation();

  const localizedProps = { ...props };
  localizedProps.label = t(localizedProps.label as string);
  if (localizedProps.helperText)
    localizedProps.helperText = t(localizedProps.helperText as string);
  if (localizedProps.placeholder)
    localizedProps.placeholder = t(localizedProps.placeholder);

  return <Select {...localizedProps} />;
};

/**
 * Checkbox with automatic translation of label and helperText
 *
 * Note that label and helperText must be a plain string, not JSX
 */
export const LocalizedCheckbox: React.FC<CheckboxProps> = (props) => {
  const { t } = useTranslation();

  const localizedProps = { ...props };
  localizedProps.label = t(localizedProps.label as string);
  if (localizedProps.helperText)
    localizedProps.helperText = t(localizedProps.helperText as string);

  return <Checkbox {...localizedProps} />;
};
