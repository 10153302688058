import { Panel, PanelButton, PanelHeader } from "../Panel";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { LocalizedText } from "../LocalizedText";
import GetAppIcon from "@material-ui/icons/GetApp";
import CsvLink from "react-csv-export";
import Alert from "@material-ui/lab/Alert";
import { ExportButtonProps, ReportListProps } from "./interfaces";
import { ReportTable } from "../ReportTable/views";

export const ReportListView: React.FC<ReportListProps> = ({
  data,
  loading,
  error,
}) => {
  const headers = [
    "Account Name",
    "Salesforce ID",
    "IMEI",
    "Vehicle Reg",
    "Device Status",
    "Commissioned Status",
    "Commissioned Date",
    "Last Connection",
    "Last Disconnection",
    "Last Heartbeat",
  ];

  const header = (
    <PanelHeader icon={<AssessmentIcon />} title={"Reports"}>
      <ExportButton label="create_account" reportData={data} />
    </PanelHeader>
  );

  return (
    <Panel header={header}>
      {error && (
        <Alert severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}

      {loading && (
        <Alert severity="info">
          <LocalizedText text="loading" />
        </Alert>
      )}
      <ReportTable reportData={data} headers={headers} />
    </Panel>
  );
};

const ExportButton: React.FC<ExportButtonProps> = ({ label, reportData }) => {
  return (
    <PanelButton aria-label={label}>
      {reportData && (
        <CsvLink data={reportData} fileName={"device-status.csv"}>
          <GetAppIcon color="inherit" />
        </CsvLink>
      )}
    </PanelButton>
  );
};
