import React from "react";

import { useTranslation } from "../../hooks/i18n";
import { FilterProps } from "./interfaces";
import { Filter, AutocompleteFilter } from "./views";

/**
 * Filter with automatic translation of button and option labels.
 */
export const LocalizedFilter: React.FC<FilterProps> = (props) => {
  const { t } = useTranslation();

  const localizedProps = { ...props };
  localizedProps.label = t(localizedProps.label);
  localizedProps.options = localizedProps.options.map(({ label, value }) => ({
    label: t(label),
    value,
  }));

  return <Filter {...localizedProps} />;
};

/**
 * Autocomplete filter with automatic translation of button and option labels.
 */
export const LocalizedAutocompleteFilter: React.FC<FilterProps> = (props) => {
  const { t } = useTranslation();

  const localizedProps = { ...props };
  localizedProps.label = t(localizedProps.label);
  localizedProps.options =
    localizedProps.options?.map(({ label, value }) => ({
      label: t(label),
      value,
    })) || [];

  return <AutocompleteFilter {...localizedProps} />;
};
