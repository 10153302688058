import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateRows: "1fr auto",
      minHeight: "100vh",

      [theme.breakpoints.down("xs")]: {
        backgroundColor: theme.palette.primary.main,
      },

      [theme.breakpoints.up("md")]: {
        gap: theme.spacing(2),
      },
    },
    signInContainer: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  })
);
