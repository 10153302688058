import { useEffect, useState } from "react";
import { LivestreamControlProps } from "./interfaces";
import { LiveStreamControlsView } from "./views";

export const LivestreamControls: React.FC<LivestreamControlProps> = (props) => {
  const lastStreamTimeString = localStorage.getItem("lastStreamTime");
  const lastStreamTime = Number.parseInt(lastStreamTimeString || "0");
  const isStreamAllowed =
    lastStreamTime > 0 ? new Date().getTime() - lastStreamTime >= 60000 : true;

  const [waitingTime, setWaitingTime] = useState(0);

  useEffect(() => {
    if (!isStreamAllowed) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - lastStreamTime;
      const remainingTime = Math.max(0, 60000 - timeDifference);
      setWaitingTime(remainingTime);

      // Start the interval to update waitingTime every second
      const interval = setInterval(() => {
        setWaitingTime((prevTime) => prevTime - 1000);
      }, 1000);

      // Clean up the interval on component unmount or dependency change
      return () => {
        clearInterval(interval);
      };
    }
  }, [isStreamAllowed, lastStreamTime]);

  return <LiveStreamControlsView {...props} waitingTime={waitingTime} />;
};
