import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      /**
       * This makes it easy to size filters - just wrap them in a container of
       * the width you want, whether that's a fixed size or a flex or grid item
       */
      width: "100%",
    },

    /**
     * The "+n" label shown when more than two filters in
     * an autocomplete are selected
     */
    moreTag: {
      display: "inline-block",
      marginLeft: theme.spacing(0.5),
      color: theme.palette.grey[600],
    },
  })
);
