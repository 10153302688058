import useLiveApi from "./use-live-api";

function buildLivestreamUrl(cameraId: string, channel: number) {
  return `/livestream/${cameraId}/stream/${channel}`;
}

function buildHeartbeatUrl(cameraId: string, channel: number) {
  return buildLivestreamUrl(cameraId, channel) + "/heartbeat";
}

export interface StartStreamResponse {
  data: {
    streamingUrl: string;
  };
}
interface LivestreamApi {
  requestStream: (
    cameraId: string,
    channel: number
  ) => Promise<StartStreamResponse>;
  stopStream: (cameraId: string, channel: number) => Promise<void>;
  sendHeartbeat: (cameraId: string, channel: number) => Promise<void>;
}

export const useLivestreamApi = (): LivestreamApi => {
  const { apiGatewayFetch } = useLiveApi();

  const requestStream = async (
    cameraId: string,
    channel: number
  ): Promise<StartStreamResponse> => {
    const response = await apiGatewayFetch(
      buildLivestreamUrl(cameraId, channel),
      "post"
    );
    return response.json();
  };

  const stopStream = async (
    cameraId: string,
    channel: number
  ): Promise<void> => {
    await apiGatewayFetch(buildLivestreamUrl(cameraId, channel), "delete");
  };

  const sendHeartbeat = async (
    cameraId: string,
    channel: number
  ): Promise<void> => {
    await apiGatewayFetch(buildHeartbeatUrl(cameraId, channel), "post");
  };

  return { requestStream, stopStream, sendHeartbeat };
};
