import Popover, { PopoverProps } from "@material-ui/core/Popover";
import React from "react";
import { useStyles } from ".";

export const TooltipPopover: React.FC<PopoverProps> = ({
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Popover
      className={classes.popover}
      PaperProps={{ className: classes.popoverPaper }}
      //Defaults
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      disableRestoreFocus
      {...props}
    >
      {children}
    </Popover>
  );
};
