import { useState } from "react";
import { User } from "../interfaces";
import useLiveApi from "./use-live-api";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUsersApi = () => {
  const { apiGatewayFetch } = useLiveApi();
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const getUsers = async (accountId: string) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(
        `/account/${accountId}/users`,
        "get"
      );
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const users = result as User[];
      setUsers(users);
      return users;
    } catch (e) {
      console.error("error fetching users", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async (id: string) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(`/user/${id}`, "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const user = result as User;

      setLoading(false);
      return user;
    } catch (e) {
      console.error("error fetching user", e);
      setError(true);
      setLoading(false);

      return undefined;
    }
  };

  const getUserByEmail = async (email: string) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(`/user/email/${email}`, "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const user = result as User;
      return user;
    } catch (e) {
      console.error("error fetching user", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (id: string) => {
    try {
      setLoading(true);
      setDeleting(true);
      const response = await apiGatewayFetch(`/user/${id}`, "delete");
      if (response.status !== 204) {
        throw new Error(response.status.toString());
      }
    } catch (e) {
      console.error("error deleting user", e);
      setError(true);
    } finally {
      setLoading(false);
      setDeleting(false);
    }
  };

  const updateUser = async (id: string, data: User) => {
    try {
      setUpdating(true);
      const response = await apiGatewayFetch(
        `/user/${id}`,
        "put",
        JSON.stringify(data)
      );
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }

      setUpdateSuccess(true);
    } catch (e) {
      console.error("error updating user", e);
      setUpdateSuccess(false);
    } finally {
      setUpdating(false);
    }
  };

  const createUser = async (data: User) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(
        "/user",
        "post",
        JSON.stringify(data)
      );
      if (response.status !== 201) {
        throw new Error(response.status.toString());
      }
    } catch (e) {
      console.error("error creating user", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    getUsers,
    deleteUser,
    updateUser,
    createUser,
    getUser,
    getUserByEmail,
    users,
    loading,
    error,
    deleting,
    updating,
    updateSuccess,
  };
};
