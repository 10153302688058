import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import LocationIcon from "@material-ui/icons/LocationOn";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "../../hooks/i18n";
import { JourneySummary, Vehicle } from "../../interfaces";
import { DatePager } from "../DatePager";
import { Item, ItemList } from "../ItemList";
import { LocalizedText } from "../LocalizedText";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import { JourneyCardProps, JourneyListViewProps } from "./interfaces";
import { useJourneyCardStyles, useStyles } from "./styles";
import { calculateDurationFromDates } from "./transformers";
import { convertUtcTimestamp, isTimestampOlderThanDays } from "../../utils";

const JourneyCard: React.FC<JourneyCardProps> = ({ data }) => {
  const { t } = useTranslation();

  const formattedDuration = calculateDurationFromDates(
    data.start.time,
    data.end.time
  );

  // Styles seems to only want to accept Partial<Props> so here we are cheating
  const classes = useJourneyCardStyles();

  return (
    <>
      <div className={classes.container} data-testid="journey-list__row">
        <div className={classes.journey}>
          <div className={classes.startEnd}>
            <CalendarTodayIcon className={classes.startIcon} />{" "}
            {DateTime.fromISO(data.start.time).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
            <LocationIcon className={classes.startIcon} /> {data.start.address}
          </div>

          <div className={classes.arrow} aria-label={t("to")}>
            <ArrowForwardIcon />
            <span
              className={data.inProgress ? classes.italics : ""}
              aria-label={`${t("duration")}: ${formattedDuration}`}
            >
              {formattedDuration}
            </span>
            {/* {data.inProgress && (
              <div>
                <Typography>(Ongoing)</Typography>
              </div>
            )} */}
          </div>

          <div className={classes.startEnd}>
            <CalendarTodayIcon
              className={clsx({
                [classes.endIcon]: true,
                [classes.inProgress]: data.inProgress,
              })}
            />{" "}
            {DateTime.fromISO(
              data.end.time || new Date().toISOString()
            ).toLocaleString(DateTime.DATETIME_SHORT)}
            <LocationIcon
              className={clsx({
                [classes.endIcon]: true,
                [classes.inProgress]: data.inProgress,
              })}
            />{" "}
            {!data.inProgress ? data.end.address : "(Ongoing)"}
          </div>
        </div>
      </div>
    </>
  );
};

export const JourneyListView: React.FC<JourneyListViewProps> = ({
  vehicle,
  data,
  loading,
  error,
  onJourneyClick,
  dates,
  onDatesChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <LastUpdateAlert vehicle={vehicle} />

      <div
        className={classes.container}
        aria-label={loading ? t("loading") : undefined}
      >
        <DatePager onChange={onDatesChange} dates={dates} allowRange={false} />

        {!loading && !error && (!data || data?.length === 0) && (
          <div>
            <Alert className={classes.message} severity="info">
              <LocalizedText text="no_journeys" />
            </Alert>
          </div>
        )}

        {error && (
          <div>
            <Alert className={classes.message} severity="error">
              <LocalizedText text="an_error_occurred" />
            </Alert>
          </div>
        )}

        <ItemList
          loading={loading}
          items={data as Item[]}
          onItemClick={onJourneyClick}
          renderItem={(item: Item) => {
            const journeySummary = (item as unknown) as JourneySummary;
            return <JourneyCard data={journeySummary} />;
          }}
        />
      </div>
    </>
  );
};

const LastUpdateAlert: React.FC<{ vehicle: Vehicle }> = ({ vehicle }) => {
  const classes = useStyles();

  const vehicleLastConnectionTime = convertUtcTimestamp(
    vehicle?.status?.lastConnection
  );

  if (vehicleLastConnectionTime) {
    if (isTimestampOlderThanDays(vehicleLastConnectionTime, 7)) {
      return (
        <Alert className={classes.message} icon={<RssFeedIcon />} color="error">
          {`Last Update: ${vehicleLastConnectionTime}`}
        </Alert>
      );
    } else if (isTimestampOlderThanDays(vehicleLastConnectionTime, 3)) {
      return (
        <Alert
          className={classes.message}
          icon={<RssFeedIcon />}
          color="warning"
        >
          {`Last Update: ${vehicleLastConnectionTime}`}
        </Alert>
      );
    } else {
      return (
        <Alert
          className={classes.message}
          icon={<RssFeedIcon />}
          color="success"
        >
          {`Last Update: ${vehicleLastConnectionTime}`}
        </Alert>
      );
    }
  }

  return (
    <Alert className={classes.message} icon={<RssFeedIcon />} color="error">
      We've not got an update from the device yet
    </Alert>
  );
};
