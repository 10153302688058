import { rest } from "msw";
import { setupWorker } from "msw";

import { apiUrl } from "../constants";
import { Json } from "../interfaces";
import { makeHandlers } from "./handlers";
import {
  drivingEvents,
  vehicles,
  journeySummaries,
  journeys,
  accounts,
  users,
  cameras,
} from "../fixtures/browser-defaults";

/**
 * Create MSW handlers using the above mock data
 */
const apiDelay = 750;
const handlers = makeHandlers(
  {
    drivingEvents,
    vehicles,
    journeySummaries,
    journeys,
    accounts,
    users,
    cameras,
  },
  apiDelay
);
export const worker = setupWorker(...handlers);

/**
 * Helper function to override the global mock responses
 * defined above.
 *
 * Useful for showing error or edge states in particular stories,
 * or for manual testing during development.
 *
 * Note: Any such override will be reset when a new story is loaded
 * - you don't need to clear these manually. @see .storybook/preview.js
 */
interface Options {
  method?: "get" | "post" | "put" | "delete";
  body?: Json;
  status?: number;
}
export function mockApiRequest(
  url: string,
  { method = "get", status = 200, body }: Options
): void {
  worker.use(
    rest[method](`${apiUrl}/${url}`, (_, res, ctx) => {
      return body
        ? res(ctx.status(status), ctx.json(body))
        : res(ctx.status(status));
    })
  );
}
