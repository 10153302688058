import { Account, AccountUpdateBody } from "../../interfaces";
import { AccountFormData, SubmittingAccountFormData } from "./interfaces";

/**
 * Transforms data from our form into the shape required for the
 * account create/update request
 */
export function formValuesToRequestBody(
  data: SubmittingAccountFormData
): Omit<AccountUpdateBody, "id"> {
  const supportedProviders: ("MFL" | "RAM")[] = ["MFL", "RAM"];
  const providers = supportedProviders
    .map((p) => ({
      name: p,
      ...(data.providers?.[p]?.username && {
        username: data.providers?.[p]?.username,
      }),
      ...(data.providers?.[p]?.password && {
        password: data.providers?.[p]?.password,
      }),
      ...(data.providers?.[p]?.fleetId && {
        fleetId: data.providers?.[p]?.fleetId,
      }),
    }))
    //Filter out providers that haven't been changed
    .filter((providerChanges) => Object.keys(providerChanges).length > 1);

  return {
    name: data.name,
    salesforceId: data.salesforceId,
    countryIso: data.countryIso,
    enableMultiProviderFeatures: data.enableMultiProviderFeatures || false,
    /**
     * Only include provider if credentials supplied
     */
    ...(providers.length && {
      providers: providers,
    }),
  };
}

/**
 * The reverse of formValuesToRequestBody. Transforms an Account API
 * response to the shape required for the form
 */
export function responseBodyToFormValues(data: Account): AccountFormData {
  return {
    name: data.name,
    countryIso: data.countryIso,
    salesforceId: data.salesforceId,
    enableMultiProviderFeatures: data.enableMultiProviderFeatures,
    providers: {
      MFL: {
        name: "MFL",
        username: "",
        password: "",
        fleetId: "",
      },
      RAM: {
        name: "RAM",
        username: "",
        password: "",
        fleetId: "",
      },
    },
  };
}

/**
 * Transforms a POST/PUT request body for the Account API to one that would be
 * returned by GET.
 *
 * The only difference is the providers array, which when fetching contains an
 * array of provider names, and when updating contains an array of Provider
 * objects with keys name, username and password
 *
 * Useful for triggering optimistic updates when saving an account.
 */
export function requestBodyToResponseBody(data: AccountUpdateBody): Account {
  return {
    ...data,
    providers: data.providers?.map((provider) => provider.name) || [],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- in the cases where alerts isn't defined it doesn't matter
    alerts: data.alerts!,
  };
}
