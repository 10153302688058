import Chip from "@material-ui/core/Chip";
import CameraIcon from "@material-ui/icons/CameraAlt";
import VideocamIcon from "@material-ui/icons/Videocam";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import React from "react";
import { useStyles } from "./styles";
import { CameraBadgeProps } from "./interfaces";
import { TooltipPopover } from "../TooltipPopover";
import TooltipDetails from "../TooltipDetails/views";

const StyledChip = withStyles((theme: Theme) =>
  createStyles({ root: { borderRadius: theme.shape.borderRadius } })
)(Chip);

export const CameraBadge: React.FC<CameraBadgeProps> = ({
  vehicle,
  useTooltip,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const showTooltip = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isLivestreamable = (): boolean => {
    const validProviders = ["RAM"];

    const cameraWithValidProviderAndChannel = vehicle.cameras.find((camera) => {
      const channel = camera.channels.find((c) => c.number === 1);
      return channel && validProviders.includes(camera.provider);
    });

    const isIgnitionOn = vehicle.status?.ignitionState;
    const isOnline = vehicle.status?.status === "ONLINE";

    return Boolean(
      cameraWithValidProviderAndChannel && isIgnitionOn && isOnline
    );
  };

  const icon =
    vehicle.status?.status !== undefined ? (
      isLivestreamable() ? (
        <VideocamIcon fontSize="small" style={{ color: "inherit" }} />
      ) : (
        <CameraIcon fontSize="small" style={{ color: "inherit" }} />
      )
    ) : undefined;

  const label =
    vehicle.status?.status !== undefined
      ? isLivestreamable()
        ? "LIVE"
        : vehicle.status.status
      : "UNKNOWN";

  return (
    <>
      <StyledChip
        size="small"
        icon={icon}
        label={label}
        classes={{
          root: vehicle.status
            ? vehicle.status.status === "ONLINE"
              ? classes.statusBadgeOnline
              : classes.statusBadgeOffline
            : classes.statusBadgeUnknown,
        }}
        onMouseEnter={useTooltip ? handlePopoverOpen : undefined}
        onMouseLeave={useTooltip ? handlePopoverClose : undefined}
      />
      {useTooltip && (
        <TooltipPopover open={showTooltip} anchorEl={anchorEl}>
          <TooltipDetails vehicle={vehicle} />
        </TooltipPopover>
      )}
    </>
  );
};
