import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Filter bar styles
     */
    toolbar: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 2),

        flexWrap: "wrap",

        "& > * + *": {
          marginLeft: theme.spacing(2),
        },
      },
    },
    filterWrap: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 0),
      },
      [theme.breakpoints.up("md")]: {
        width: 200,
      },
    },
    vehicleFilterWrap: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 0),
      },
      /**
       * Have vehicle filter take up remaining space in toolbar. This allows more
       * items to be shown as selected at once
       */
      [theme.breakpoints.up("md")]: {
        flex: 1,
      },
    },
  })
);
