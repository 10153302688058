import React from "react";
import clsx from "clsx";
import MaterialButton from "@material-ui/core/Button";

import { ButtonProps, SubmitButtonProps } from "./interfaces";
import { useStyles } from "./styles";
import { CircularProgress } from "@material-ui/core";

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <MaterialButton
      disableElevation
      variant="contained"
      color="secondary"
      {...props}
    />
  );
};

/**
 * A button with no styling whatsoever
 */
export const GhostButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <button className={clsx(className, classes.ghostButton)} {...props}>
      {props.children}
    </button>
  );
};

/**
 * A submit button with default styling with a circular spinner when submitting
 */
export const SubmitButton: React.FC<SubmitButtonProps> = ({
  isSubmitting,
  ...props
}) => {
  return (
    <MaterialButton
      disableElevation
      variant="contained"
      color="secondary"
      disabled={isSubmitting}
      type="submit"
      {...props}
    >
      {isSubmitting && <CircularProgress size={24} />}
      {!isSubmitting && props.children}
    </MaterialButton>
  );
};
