import React from "react";

import { useListDetailStyles } from "./styles";

/**
 * A container for laying out list/detail pairings.
 */
export const ListDetailLayout: React.FC = ({ children }) => {
  const classes = useListDetailStyles();

  return <div className={classes.container}>{children}</div>;
};
