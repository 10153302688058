import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      /**
       * Use grid by default so that if height is constrained, we can scroll within the list
       */
      display: "grid",
      gridTemplateRows: "auto 1fr auto",
      height: "100%",
    },
    noResults: {
      margin: theme.spacing(1, 0),
    },
  })
);

export const useVehicleRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
    },
    statuses: {
      display: "flex",
      justifyContent: "space-between",
    },
    status: {
      color: "red",
    },
  })
);
