import { Account } from "../../interfaces";
import { Item } from "../ItemList";

/**
 * Transforms accounts from an API response to a shape usable by <ItemList />
 */
export function accountsToItems(accounts: Account[]): Item[] {
  return accounts
    ? accounts.map(({ id, name }) => ({
        id,
        label: name,
      }))
    : [];
}

export function matchesCaseInsensitive(
  searchString: string,
  accountName: string
): boolean {
  const regex = new RegExp(searchString, "i");
  return regex.test(accountName);
}
