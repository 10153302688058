import React from "react";
import { useHistory } from "react-router";
import { pageSize } from ".";
import { DrivingEvent } from "../../interfaces";
import { TablePaging } from "../TablePaging";
import { MediaListProps } from "./interfaces";
import { MediaListContext } from "./MediaListContext";
import { useStyles } from "./styles";
import { formatMediaDataForDisplay } from "./transformers";
import { MediaCardContainer } from "./views";
import { useEventsApi } from "../../hooks/use-events";

export const MediaList: React.FC<MediaListProps> = ({ collapsed }) => {
  const history = useHistory();
  const classes = useStyles();
  //Optimistacally select an event for snappiness
  const [
    optSelectedMedia,
    setOptSelectedMedia,
  ] = React.useState<DrivingEvent>();

  const { selectedMedia, filters, sort, setSort } = React.useContext(
    MediaListContext
  );

  //Load data from useMedia
  const {
    data,
    loading: mediaLoading,
    error: mediaError,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useEventsApi({ filters, sort });

  const formattedData = data && data.map((m) => formatMediaDataForDisplay(m));

  return (
    <div className={classes.container}>
      <MediaCardContainer
        collapsed={collapsed}
        numMedia={pageSize}
        loading={!mediaError && !data}
        error={mediaError}
        events={formattedData || []}
        sort={sort}
        onSort={setSort}
        onSelect={(event) => {
          setOptSelectedMedia(event);
          history.push(`/video/${event.vehicle.id}/${event.id}`);
        }}
        selected={optSelectedMedia || selectedMedia}
      />
      <TablePaging
        onNext={nextPage}
        onPrev={prevPage}
        showNext={hasNextPage}
        showPrev={hasPrevPage}
        loading={!mediaError && mediaLoading}
      />
    </div>
  );
};
