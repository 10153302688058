import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "0 20px",
      color: theme.palette.primary.main,
      /**
       * Header contains logo, user image and burger menu
       */
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      /**
       * Maintain same height when collapsed and avatar is not rendered
       */
      height: "80px",
      minHeight: "8vh",
      backgroundColor: "#fff",

      /**
       * Rounded corners on large viewports only (component is full width on small viewports)
       */
      [theme.breakpoints.up("lg")]: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },

      /**
       * Maximise width when mobile menu appears
       */
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },

      /**
       * Set logo size
       */
      "& img": {
        maxWidth: "100%",
        width: 72,
      },
    },
    navButtons: {
      display: "flex",
      height: "100%",
      alignItems: "center",
    },
    navItem: {
      display: "flex",
      width: "60px",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      background: "none",
      border: "none",
      cursor: "pointer",
      padding: theme.spacing(1),
      "&:hover": {
        background: "#EEE",
      },

      // margin: theme.spacing(1),
    },
    active: {
      background: "#EEE",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",

      "& span": {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      "& div": {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    label: {
      flexDirection: "column",
    },

    //Menu
    noStyle: {
      textDecoration: "none !important",
      color: "unset",
      width: "100%",
    },
    menu: {
      borderRadius: "2px",
      marginTop: "1px",
      boxShadow: "0 2px 4px 0 rgb(0 0 0 / 25%)",
      border: "0.5px solid #C6C6C6",
      minWidth: "280px",

      [theme.breakpoints.down("sm")]: {
        width: "90%",
        height: "100vh",
        marginRight: "32px",
      },
      "& h3": {
        fontWeight: 500,
        margin: "5px 0 18px 0",
        color: "#565656",
        fontSize: "14px",
      },
      color: "#5F5F5F",
      fontSize: "14px",
      padding: "13px 11px",
    },
    ramExternalItem: {
      borderRadius: "10px",
      marginTop: theme.spacing(2),
      border: `1px solid #c7c7c7`,
      maxWidth: "300px",
      height: "90px",
      display: "flex",
      alignItems: "center",
    },
    logoContainer: {
      display: "flex",
      flex: 0.3,
      alignItems: "center",
    },
    spanContainer: {
      display: "flex",
      flex: 0.7,
      alignItems: "center",
    },
    ramExternalSpan: {
      whiteSpace: "normal",
      textAlign: "left",
      fontSize: "14px",
      color: "#565656",
      fontWeight: 300,
    },
    logo: {
      maxWidth: "100%",
      width: 72,
    },
    intercom_message_counter: {
      color: "white",
      background: theme.palette.secondary.main,
      // borderTopLeftRadius: '45%',
      // borderTopRightRadius: '45%',
      // borderBottomRightRadius: '45%',
      // borderBottomLeftRadius: '45%',
      borderRadius: "50%",
      padding: "4px 1px",
      display: "inline-flex",
      maxHeight: "21px",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "20px",
      fontSize: 11,
      top: "15px",
      left: "20px",
      position: "absolute",
    },
    intercomAbsContainer: {
      position: "absolute",
      left: 20,
      bottom: 0,
    },
  })
);
