import React, { useContext } from "react";
import { DateTime } from "luxon";
import { useHistory, useParams } from "react-router-dom";

import { Layout, ListDetailLayout } from "../Layout";
import { FilterBar, getInitialFilters } from "../../components/EventFilters";
import {
  EventList,
  EventListContext,
  initialSort,
} from "../../components/EventList";
import { EventDetail } from "../../components/EventDetail";
import { vehiclesToOptions } from "../../components/EventList/transformers";
import { DrivingEvent } from "../../interfaces";

import { vehicleFilterPageSize } from "./constants";
import { useStyles } from "./styles";
import { useEventApi } from "../../hooks/use-event";
import { useVehiclesForCurrentAccount } from "../../hooks/use-vehicles-for-current-account";

interface EventsPageParams {
  vehicleId: string;
  eventId: string;
}

export const EventsPage: React.FC = () => {
  const history = useHistory();
  const [filters, setFilters] = React.useState(getInitialFilters());
  const [sort, setSort] = React.useState(initialSort);
  const [selectedEvent, setSelectedEvent] = React.useState<
    DrivingEvent | undefined
  >();

  /**
   * Retrieve currently selected event
   */
  const { vehicleId, eventId } = useParams<EventsPageParams>();
  const {
    data: vehiclesData,
    loading: vehiclesLoading,
  } = useVehiclesForCurrentAccount(undefined, vehicleFilterPageSize);

  // Convert vehicle data for use in filter menu
  const vehicleOptions =
    vehiclesLoading || !vehiclesData ? [] : vehiclesToOptions(vehiclesData);

  const classes = useStyles({ detailOpen: Boolean(eventId) });

  return (
    <EventListContext.Provider
      value={{
        filters,
        sort,
        setFilters,
        setSort,
        selectedEvent,
        setSelectedEvent,
      }}
    >
      <Layout menuCollapsed={Boolean(eventId)}>
        <div className={classes.container}>
          <div className={classes.filterContainer}>
            <FilterBar
              vehicles={vehicleOptions}
              filters={filters}
              onFilter={(values) => {
                setFilters(values);
              }}
            />
          </div>
          {eventId ? (
            <ListDetailLayout>
              <EventList collapsed />
              {eventId && (
                <>
                  <EventsPageAutoFilter
                    vehicleId={vehicleId}
                    eventId={eventId}
                  />
                  <EventDetail onBack={() => history.push("/events")} />
                </>
              )}
            </ListDetailLayout>
          ) : (
            <EventList />
          )}
        </div>
      </Layout>
    </EventListContext.Provider>
  );
};

const EventsPageAutoFilter: React.FC<EventsPageParams> = ({
  eventId,
  vehicleId,
}) => {
  const [event, setEvent] = React.useState<DrivingEvent | undefined>(undefined);
  const { getEvent } = useEventApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getEvent({ vehicleId, eventId });
      setEvent(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId, eventId]);
  const { filters, setFilters, setSelectedEvent } = useContext(
    EventListContext
  );

  /**
   * Set event list date filter to the day of the selected event when
   * deeplinking (i.e. when first loading a page with a URL in the form
   * /events/:vehicleId/:eventId
   */
  const [
    hasSetFiltersFromDeeplinkedEvent,
    setHasSetFiltersFromDeeplinkedEvent,
  ] = React.useState(false);
  React.useEffect(() => {
    if (!event) return;
    setSelectedEvent(event);

    if (hasSetFiltersFromDeeplinkedEvent) return;

    /**
     * Get day of event and create date range to cover entire day
     */
    const dateChangeRequired = () => {
      if (filters.date.length <= 1) return false;

      const [start, end] = filters.date;

      const toDate = (date: string) => {
        const dateString = DateTime.fromISO(date).toFormat("yyyy-MM-dd");
        return DateTime.fromFormat(dateString, "yyyy-MM-dd");
      };
      const eventDateTime = toDate(event.date);
      const startDateTime = toDate(start);
      const endDateTime = toDate(end);

      const isBefore = eventDateTime.diff(startDateTime).milliseconds < 0;
      const isAfter = eventDateTime.diff(endDateTime).milliseconds > 0;

      return isBefore || isAfter;
    };

    if (dateChangeRequired()) {
      const date = DateTime.fromISO(event.date);
      setFilters({
        ...filters,
        date: [
          date.startOf("day").toISO(),
          date.startOf("day").plus({ days: 1 }).toUTC().toISO(),
        ],
      });
    }

    /**
     * We only want to do this once - the current event is driven by the URL,
     * which is changed each time an event is clicked.
     *
     * By storing that we've done the date range filter, we ensure it only
     * occurs when the page is loaded
     */
    setHasSetFiltersFromDeeplinkedEvent(true);
  }, [
    event,
    setSelectedEvent,
    filters,
    setFilters,
    hasSetFiltersFromDeeplinkedEvent,
  ]);

  return null;
};
