import { createContext } from "react";

export interface ImpersonatedUser {
  id: string;
  name: string;
}
export type ImpersonationContent = {
  iUser: ImpersonatedUser | undefined;
  setIUser: (u: ImpersonatedUser | undefined) => void;
};
export const ImpersonationContext = createContext<ImpersonationContent>({
  iUser: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIUser: () => {},
});
