import React from "react";
import { v4 as uuid } from "uuid";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { VehicleFormView } from "./views";
import {
  VehicleFormData,
  EditVehicleFormProps,
  VehicleFormParams,
  CreateVehicleFormProps,
} from "./interfaces";
import { useCamerasApi } from "../../hooks/use-cameras";
import { Camera, Vehicle } from "../../interfaces";
import { useVehicleApi } from "../../hooks/use-vehicle";

/**
 * A container to handle creating or editing a vehicle
 *
 * It decides whether to show a create or edit form based on whether
 * there is an vehicle ID present in the URL.
 */
export const VehicleForm: React.FC = () => {
  const { accountId, vehicleId } = useParams<VehicleFormParams>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Typing this is a bit arduous
  const location = useLocation<any>();
  const [cameras, setCameras] = React.useState<Camera[] | undefined>(undefined);
  const { error: camerasError, getCameras } = useCamerasApi();

  /**
   * Fetch cameras to display in dropdown list
   */
  React.useEffect(() => {
    async function fetchData() {
      const data = await getCameras(accountId);
      setCameras(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [accountId]);

  return vehicleId ? (
    <EditVehicleForm
      id={vehicleId}
      accountId={accountId}
      cameras={cameras}
      camerasError={camerasError}
      created={location.state?.created}
    />
  ) : (
    <CreateVehicleForm
      accountId={accountId}
      cameras={cameras}
      camerasError={camerasError}
    />
  );
};

const CreateVehicleForm: React.FC<CreateVehicleFormProps> = ({
  accountId,
  cameras,
  camerasError,
}) => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { createVehicle } = useVehicleApi();

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (data: VehicleFormData) => {
    setIsSubmitting(true);
    try {
      const id = uuid();
      await createVehicle({ ...data, id, accountId });
      setSuccess(true);
      /**
       * Navigate to edit screen for the newly-created vehicle
       */
      history.push(`/accounts/summary/${accountId}/vehicles/edit/${id}`, {
        created: true,
      });
    } catch (e) {
      setSuccess(false);
      setError(true);
      setIsSubmitting(false);
    }
  };

  return (
    <VehicleFormView
      isSubmitting={isSubmitting}
      accountId={accountId}
      cameras={cameras}
      error={error || camerasError}
      success={success}
      onSubmit={handleSubmit}
    />
  );
};

const EditVehicleForm: React.FC<EditVehicleFormProps> = ({
  id,
  accountId,
  created,
  cameras,
  camerasError,
}) => {
  const [data, setData] = React.useState<Vehicle | undefined>(undefined);
  const {
    loading,
    error,
    updateSuccess,
    getVehicle,
    updateVehicle,
    updating,
  } = useVehicleApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getVehicle(id);
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [id]);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (values: VehicleFormData) => {
    if (!data) return;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- by the time we get to submitting, there will be vehicle data
    updateVehicle({
      ...values,
      driver: values.driver === "" ? null : values.driver,
      id,
      accountId: data.accountId,
    });
  };

  return (
    <VehicleFormView
      vehicle={data}
      cameras={cameras}
      loading={loading}
      error={camerasError || error}
      accountId={accountId}
      onSubmit={handleSubmit}
      isSubmitting={updating}
      success={created || updateSuccess}
      isEdit
    />
  );
};
