import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    /**
     * Success/error messages displayed above form
     */
    alert: {
      marginBottom: theme.spacing(2),
    },
    /**
     * Fieldset container for channels
     */
    channels: {
      margin: theme.spacing(1, 0, 2, 0),
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[300]}`,

      "& legend": {
        display: "flex",
        alignItems: "center",
      },
    },
    channelsActions: {
      display: "flex",
      justifyContent: "space-between",
      margin: theme.spacing(-1, -1, 2, -1),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    /**
     * Each channel
     */
    channelRow: {
      /**
       * Lay items out horizontally, giving most width to description field
       */
      display: "grid",
      gridTemplateColumns: "180px 1fr 50px",
      gap: theme.spacing(1),
      alignItems: "start",
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],

      "& input": {
        backgroundColor: "#fff",
      },
    },
  })
);
