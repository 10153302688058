/**
 * Determines whether the current route is the "home" route
 */
export function isHome(): boolean {
  return window.location.pathname === "/";
}

/**
 * Determines whether a particular top-level route is active. Matches any
 * sub-routes as well
 */
export function routeIsActive(route: string): boolean {
  return window.location.pathname.startsWith(route);
}
