import { DateTime } from "luxon";
import { DrivingEvent } from "../../interfaces";

export const formatMediaDataForDisplay = (
  media: DrivingEvent
): DrivingEvent => {
  return {
    ...media,
    date: DateTime.fromISO(media.date).toLocaleString(
      DateTime.DATETIME_SHORT_WITH_SECONDS
    ),
  };
};
