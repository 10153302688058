// View should contain UI logic and state management for tha tUI
import {
  Box,
  DialogActions,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { useFormik } from "formik";
import React from "react";
import {
  RequestVideoChannelDropdownProps,
  RequestVideoDialogViewProps,
  RequestVideoFormProps,
} from ".";
import * as yup from "yup";

import { Button } from "../../Button";
import { CloseButton } from "../../Panel";
import { useFormStyles, useStyles } from "./styles";
import { dateToFormDate, formDateToDate } from "./transformers";
import { DateTime } from "luxon";
import { UserContext } from "../../../contexts/UserContext";

export const RequestVideoDialogView: React.FC<RequestVideoDialogViewProps> = ({
  show = false,
  startDate, // yyyy-MM-dd hh:mm:ss
  endDate, // yyyy-MM-dd hh:mm:ss
  frameTime, // dd/MM/yyyy, hh:mm:ss
  utc, // yyyy-MM-ddThh:mm:ssZ
  vehicle,
  location,
  heading,
  address = null,
  onRequestVideo,
  onRequested,
  onClose,
}) => {
  const classes = useStyles();

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [channel, setChannel] = React.useState<string>("1");
  const { ctxUser } = React.useContext(UserContext);

  const handleSubmit = (datetime: string) => {
    setSubmitting(true);

    const timeFromFormat = DateTime.fromFormat(datetime, "yyyy-MM-dd hh:mm:ss");
    const cameraZone = DateTime.fromISO(utc, { setZone: true }).zoneName;
    const adjustedUtcTime = timeFromFormat.setZone(cameraZone).toISO();

    if (ctxUser) {
      onRequestVideo(
        vehicle.id,
        datetime,
        location,
        heading,
        address,
        adjustedUtcTime,
        Number(channel),
        ctxUser?.id
      )
        .then(() => {
          setSubmitted(true);
          if (onRequested) onRequested(true);
        })
        .catch((err) => {
          console.error(err);
          if (onRequested) onRequested(false);
        })
        .then(() => setSubmitting(false));
    }
  };

  return (
    <>
      <Dialog open={show && !submitted}>
        <DialogTitle className={classes.title} disableTypography>
          <Typography variant="h6">Request Video Clip</Typography>
          <CloseButton onClick={onClose} />
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <RequestVideoChannelDropdown
                  channel={channel}
                  setChannel={setChannel}
                  vehicle={vehicle}
                />
              </Grid>
            </Grid>
            <RequestVideoForm
              datetime={frameTime}
              startDate={startDate}
              endDate={endDate}
              onSubmit={handleSubmit}
              submitting={submitting}
            />
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={show && submitted}>
        <DialogTitle>
          <Typography variant="h6">
            Video Clip Successfully Requested
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <p>
            Your request has been successful, when your video is available you
            will be notified via email. Your request will also be available to
            view in the Events page or Video Gallery (once uploaded).
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSubmitted(false);
              onClose();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const RequestVideoChannelDropdown: React.FC<RequestVideoChannelDropdownProps> = ({
  channel,
  setChannel,
  vehicle,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Channel</InputLabel>
      <Select
        value={channel}
        name="channel"
        onChange={(e) => setChannel(e.target.value as string)}
        label="channel"
      >
        {vehicle.cameras[0].channels.map((channel) => {
          return (
            <MenuItem value={channel.number}>{channel.description}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const RequestVideoForm: React.FC<RequestVideoFormProps> = ({
  datetime,
  startDate,
  endDate,
  submitting,
  onSubmit,
}) => {
  const classes = useFormStyles();

  /**
   * Validation rules for the form
   */
  const schema = yup.object({
    datetime: yup
      .string()
      .required("date_required")
      .test("validatorDateWithinBounds", "date_invalid", (value) => {
        if (!value) return false;
        const date = formDateToDate(value);

        const format = "yyyy-MM-dd hh:mm:ss";
        const d = DateTime.fromFormat(date, format);
        const min = DateTime.fromFormat(startDate, format);
        const max = DateTime.fromFormat(endDate, format);

        /**
         * If diff is positive, then d is before comparison
         *
         * If diff is negative, then d is after the comparison
         **/
        if (d.diff(min).milliseconds < 0) {
          return false;
        }
        if (d.diff(max).milliseconds > 0) {
          return false;
        }

        return true;
      }),
  });

  const formik = useFormik({
    initialValues: {
      datetime: dateToFormDate(datetime),
    },
    validateOnBlur: true,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: (values) => {
      onSubmit(formDateToDate(values.datetime));
    },
  });

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid container spacing={1}>
          <Grid xs={12} sm={3} item>
            <FormControl fullWidth>
              <TextField
                label="Min"
                type="text"
                disabled
                value={startDate.split(" ")[1]}
              />
            </FormControl>
          </Grid>

          <Grid xs={12} sm={6} item>
            <FormControl fullWidth>
              <TextField
                id="time"
                label="Selected"
                name="datetime"
                type="datetime-local"
                value={formik.values.datetime}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.datetime)}
                disabled={submitting}
                inputProps={{
                  step: 1, // 1 min
                  min: dateToFormDate(startDate),
                  max: dateToFormDate(endDate),
                }}
              />
            </FormControl>
          </Grid>

          <Grid xs={12} sm={3} item>
            <FormControl fullWidth>
              <TextField
                label="Max"
                type="text"
                disabled
                value={endDate.split(" ")[1]}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="right">
            <Button
              className={classes.submit}
              disabled={submitting}
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
