import { useState } from "react";
import useLiveApi from "./use-live-api";
import { Camera, CameraUpdateBody } from "../interfaces";

interface CamerasApi {
  loading: boolean;
  error: boolean;
  getCameras: (accountId: string) => Promise<Camera[] | undefined>;
  getCamera: (id: string) => Promise<Camera | undefined>;
  createCamera: (updatedCamera: CameraUpdateBody) => Promise<void>;
  updateCamera: (id: string, updatedCamera: CameraUpdateBody) => Promise<void>;
  deleteCamera: (id: string) => Promise<void>;
  deleting: boolean;
  isUpdating: boolean;
  updateSuccess: boolean;
}

export const useCamerasApi = (): CamerasApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const getCameras = async (accountId: string) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(
        `/account/${accountId}/cameras`,
        "get"
      );
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const cameras = result as Camera[];

      return cameras;
    } catch (e) {
      console.error("error fetching cameras", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getCamera = async (id: string) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(`/camera/${id}`, "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const camera = result as Camera;
      setLoading(false);

      return camera;
    } catch (e) {
      console.error("error fetching camera", e);
      setError(true);
      setLoading(false);

      return undefined;
    }
  };

  const createCamera = async (createdCamera: CameraUpdateBody) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(
        "/camera",
        "post",
        JSON.stringify(createdCamera)
      );
      if (response.status !== 201) {
        throw new Error("There was an issue creating the camera");
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setError(true);
      setLoading(false);
    }
  };

  const updateCamera = async (id: string, updatedCamera: CameraUpdateBody) => {
    try {
      setLoading(true);
      setIsUpdating(true);
      const response = await apiGatewayFetch(
        `/camera/${id}`,
        "put",
        JSON.stringify(updatedCamera)
      );
      if (response.status !== 200) {
        throw new Error("There was an issue updating the camera");
      }
      setUpdateSuccess(true);
      setLoading(false);
      setIsUpdating(false);
    } catch (e) {
      console.error("error updating camera", e);
      setError(true);
      setUpdateSuccess(false);
      setLoading(false);
      setIsUpdating(false);
    }
  };

  const deleteCamera = async (id: string) => {
    try {
      setLoading(true);
      setDeleting(true);
      const response = await apiGatewayFetch(`/camera/${id}`, "delete");
      if (response.status !== 204) {
        throw new Error(response.status.toString());
      }
    } catch (e) {
      console.error("error deleting camera", e);
      setError(true);
    } finally {
      setLoading(false);
      setDeleting(false);
    }
  };
  return {
    loading,
    error,
    getCameras,
    getCamera,
    createCamera,
    updateCamera,
    deleteCamera,
    deleting,
    isUpdating,
    updateSuccess,
  };
};
