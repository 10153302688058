import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { visuallyHidden } from "../../theme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Container styles
     */
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.up("lg")]: {
        /**
         * Don't allow the container to
         * stretch out its grid parent
         */
        minWidth: 0,
      },
    },
    /**
     * Filter bar styles
     */
    toolbar: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1),

        "& > * + *": {
          marginTop: theme.spacing(1),
        },
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 2),

        flexWrap: "wrap",

        "& > * + *": {
          marginLeft: theme.spacing(2),
        },
      },
    },
    filterWrap: {
      width: 200,
    },
    vehicleFilterWrap: {
      /**
       * Have vehicle filter take up remaining space in toolbar. This allows more
       * items to be shown as selected at once
       */
      flex: 1,
    },
    /**
     * Card container styles
     */
    cardContainer: {
      position: "relative",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: "8px",
      /**
       * Ensure there is always enough room to show an error message
       */
      minHeight: 300,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100vw",
      },
    },
    /**
     * Accessibly hide loading message text
     */
    cardContainerLoading: {
      ...visuallyHidden,
    },
    /**
     * Message shown when no results found
     */
    emptyMessage: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    /**
     * Error styles
     */
    error: {
      display: "flex",
      ">*": {
        flexShrink: 1,
      },
    },
    cards: {
      background: "white",
      width: "100%",
    },
  })
);

export const useCardStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        display: "flex",
        flexDirection: "column",
        padding: "0.2rem",
        "&:last-child": {
          paddingBottom: "0.2rem",
        },
        "&:hover": {
          cursor: "pointer",
        },
        height: "100%",
        justifyContent: "flex-end",
      },
      no_thumbnail: {
        backgroundColor: theme.palette.grey[100],
        display: "flex",
        flexGrow: 1,
        minHeight: 165,
      },
      text: {
        verticalAlign: "center",
      },
      selected: {
        backgroundColor: theme.palette.primary.light,
      },
      icon: {
        transform: "scale(0.8)",
        color: theme.palette.primary.main,
      },
      type: {
        transform: "scale(1.5)",
        color: theme.palette.secondary.main,
      },
      container: {
        height: "100%",
      },
      content: {
        padding: "8px",
        "&:last-child": {
          paddingBottom: "8px",
        },
        height: "auto",
      },
      header: {
        background: "green",
      },
      media: {
        minHeight: 165,
        paddingTop: "56.25%",
        backgroundColor: theme.palette.grey[100],
      },
      alert: {},
    }),
  { index: 1 }
);
