import React from "react";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import AccountIcon from "@material-ui/icons/EmojiTransportation";
import InfoIcon from "@material-ui/icons/Info";
import PeopleIcon from "@material-ui/icons/People";
import CarIcon from "@material-ui/icons/DriveEta";
import CameraIcon from "@material-ui/icons/Videocam";
import { useHistory } from "react-router-dom";
import { ImpersonationContext } from "../../contexts/ImpersonationContext";

import { CloseButton, Panel, PanelHeader } from "../Panel";
import { LocalizedButton } from "../Button";
import { LocalizedText } from "../LocalizedText";
import { AccountSummaryViewProps } from "./interfaces";
import { useStyles } from "./styles";

const CardContent: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cardContent}>{children}</div>;
};
const CardActions: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cardActions}>{children}</div>;
};

/**
 * Displays a summary of an account, including user, vehicle and camera counts,
 * with buttons to view those resources
 */
export const AccountSummaryView: React.FC<AccountSummaryViewProps> = ({
  account,
  loading,
  error,
}) => {
  const { setIUser } = React.useContext(ImpersonationContext);
  const history = useHistory();
  const classes = useStyles();

  const headerText = loading ? (
    <LocalizedText text="loading" />
  ) : (
    <>
      <LocalizedText text="summary" />: {account?.name}
    </>
  );

  const header = (
    <PanelHeader icon={<AccountIcon />} title={headerText}>
      {account?.id && (
        <LocalizedButton
          onClick={() => {
            setIUser({ id: account.id, name: account.name });
            history.push("/");
          }}
        >
          impersonate
        </LocalizedButton>
      )}
      <CloseButton onClick={() => history.push("/accounts")} />
    </PanelHeader>
  );

  return (
    <Panel header={header}>
      {error && (
        <Alert severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}

      <div className={classes.cards}>
        <Panel
          compact
          header={
            <PanelHeader
              icon={<InfoIcon color="inherit" />}
              title={<LocalizedText text="details" />}
            ></PanelHeader>
          }
        >
          <CardContent>
            <Typography>Providers</Typography>
            <Typography variant="h5">
              {account?.providers.join(", ")}
            </Typography>
            <Typography>Salesforce ID</Typography>
            <Typography variant="h5">{account?.salesforceId}</Typography>

            <CardActions>
              <LocalizedButton
                onClick={() =>
                  history.push(`/accounts/summary/${account?.id}/edit`)
                }
              >
                edit_details
              </LocalizedButton>
            </CardActions>
          </CardContent>
        </Panel>

        <Panel
          compact
          header={
            <PanelHeader
              icon={<PeopleIcon color="inherit" />}
              title={<LocalizedText text="users" />}
            ></PanelHeader>
          }
        >
          <CardContent>
            <Typography>
              <LocalizedText text="users_overview" />
            </Typography>

            <CardActions>
              <LocalizedButton
                className={classes.cardButton}
                onClick={() =>
                  history.push(`/accounts/summary/${account?.id}/users/create`)
                }
              >
                create_user
              </LocalizedButton>

              <LocalizedButton
                className={classes.cardButton}
                variant="outlined"
                onClick={() =>
                  history.push(`/accounts/summary/${account?.id}/users`)
                }
              >
                manage_users
              </LocalizedButton>
            </CardActions>
          </CardContent>
        </Panel>

        <Panel
          compact
          header={
            <PanelHeader
              icon={<CameraIcon color="inherit" />}
              title={<LocalizedText text="cameras" />}
            ></PanelHeader>
          }
        >
          <CardContent>
            <Typography>
              <LocalizedText text="cameras_overview" />
            </Typography>

            <CardActions>
              <LocalizedButton
                className={classes.cardButton}
                onClick={() =>
                  history.push(
                    `/accounts/summary/${account?.id}/cameras/create`
                  )
                }
              >
                create_camera
              </LocalizedButton>

              <LocalizedButton
                className={classes.cardButton}
                variant="outlined"
                onClick={() =>
                  history.push(`/accounts/summary/${account?.id}/cameras`)
                }
              >
                manage_cameras
              </LocalizedButton>
            </CardActions>
          </CardContent>
        </Panel>

        <Panel
          compact
          header={
            <PanelHeader
              icon={<CarIcon color="inherit" />}
              title={<LocalizedText text="vehicles" />}
            ></PanelHeader>
          }
        >
          <CardContent>
            <Typography>
              <LocalizedText text="vehicles_overview" />
            </Typography>

            <CardActions>
              <LocalizedButton
                className={classes.cardButton}
                onClick={() =>
                  history.push(
                    `/accounts/summary/${account?.id}/vehicles/create`
                  )
                }
              >
                create_vehicle
              </LocalizedButton>

              <LocalizedButton
                className={classes.cardButton}
                variant="outlined"
                onClick={() =>
                  history.push(`/accounts/summary/${account?.id}/vehicles`)
                }
              >
                manage_vehicles
              </LocalizedButton>
            </CardActions>
          </CardContent>
        </Panel>
      </div>
    </Panel>
  );
};
