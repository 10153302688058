export const sizes = {
  xs: 32,
  s: 48,
  m: 64,
  l: 96,
  xl: 128,
};
export const fontSizes = {
  xs: "body2",
  s: "body1",
  m: "h5",
  l: "h3",
  xl: "h2",
};
