import faker from "faker";
import { Camera } from "../interfaces";

export function makeCamera(data: Partial<Camera> = {}): Camera {
  const deviceNumber = String(
    faker.datatype.number({ min: 1000000, max: 200000 })
  );
  return {
    id: faker.datatype.uuid(),
    channels: [
      {
        number: 1,
        description: "Forward Facing",
      },
      {
        number: 2,
        description: "Forward Facing",
      },
    ],
    provider: "MFL",
    providerId: `MFL:${deviceNumber}`,
    accountId: faker.datatype.uuid(),
    deviceNumber,
    ...data,
  };
}

export function makeCameras(
  num: number,
  dataFunc?: () => Partial<Camera>
): Camera[] {
  return Array(num)
    .fill("")
    .map((_) => makeCamera(dataFunc ? dataFunc() : {}));
}
