import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { JourneyCardProps } from ".";
import { MediaStatuses } from "../EventDetail/constants";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      /**
       * Use grid by default so that if height is constrained, we can scroll within the list
       */
      display: "grid",
      gridTemplateRows: "auto 1fr auto",
      height: "100%",
      width: "100%",
    },

    /**
     * Error or no results message
     */
    message: {
      margin: theme.spacing(1, 0),
    },
  })
);

export const useJourneyCardStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },

    journey: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down("md")]: {
        /**
         * Start/arrow/end are stacked on smaller viewports - add some spacing between them
         */
        "& > * + *": {
          marginTop: theme.spacing(1),
        },
      },

      /**
       * Lay start/arrow/end out in a row on larger viewports
       */
      [theme.breakpoints.up("md")]: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
      },
    },

    options: {
      textAlign: "right",
    },
    italics: {
      fontStyle: "italic",
    },
    arrow: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      /**
       * Start/arrow/end are stacked on smaller viewports, so rotate the arrow to
       * point down instead of right
       */
      [theme.breakpoints.down("md")]: {
        "& > svg": { transform: "rotate(90deg)" },
      },
    },

    /**
     * Start/end datetime & location blocks
     */
    startEnd: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gap: theme.spacing(1),
    },

    /**
     * Colorise start/end icons
     */
    startIcon: {
      color: theme.palette.success.dark,
    },
    endIcon: {
      color: theme.palette.error.dark,
    },

    inProgress: {
      color: theme.palette.secondary.main,
    },

    hyperlapse: {
      backgroundColor: (props: Partial<JourneyCardProps>) => {
        const colorsByStatus = {
          [MediaStatuses.available]: theme.palette.success.main,
          [MediaStatuses.pending]: theme.palette.info.main,
          [MediaStatuses.requestable]: theme.palette.secondary.main,
          [MediaStatuses.failed]: theme.palette.warning.main,
          [MediaStatuses.unavailable]: theme.palette.error.main,
        };

        const status =
          props.data?.media?.[0]?.status || MediaStatuses.requestable;

        return colorsByStatus[status];
      },
      "&:hover": {
        backgroundColor: (props: Partial<JourneyCardProps>) => {
          const status =
            props.data?.media?.[0]?.status || MediaStatuses.requestable;
          const colorsByStatus = {
            [MediaStatuses.available]: theme.palette.success.main,
            [MediaStatuses.pending]: theme.palette.info.main,
            [MediaStatuses.requestable]: theme.palette.secondary.main,
            [MediaStatuses.failed]: theme.palette.warning.main,
            [MediaStatuses.unavailable]: theme.palette.error.main,
          };
          return colorsByStatus[status];
        },
        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4) 0 0)",
      },
    },
  })
);
