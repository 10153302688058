import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      "& .MuiAlert-icon": {
        padding: 0,
      },
      "& .MuiAlert-message": {
        paddingTop: 1,
        paddingBottom: 1,
      },
    },
    card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      transition: "box-shadow 1s",
    },
    /**
     * Image/video area
     */
    media: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      /**
       * Ensure that placeholder is prominent
       * and image-shaped when media not yet requested
       */
      minHeight: 200,
      backgroundColor: theme.palette.grey[100],
    },
    /**
     * Bars on cards for labels and buttons
     */
    toolbar: {
      flex: 0,
      display: "flex",
      justifyContent: "center",
      /**
       * Make height consistent when no buttons contained within
       * toolbar
       */
      minHeight: "3.325rem",
      padding: theme.spacing(1),
    },
  })
);
