import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateRows: "auto 1fr",

      [theme.breakpoints.down("sm")]: {
        /**
         * Take up the entire viewport, minus the header height
         */
        maxHeight: `calc(100vh - ${theme.spacing(20)}px)`,
      },
    },
    searchBarContainer: {
      /**
       * The list/detail layout hides the list view on tablet and mobile when
       * the detail view is open, so we hide the filters on these screen sizes
       * when the detail view is open
       */
      [theme.breakpoints.down("md")]: {
        display: "initial",
      },
    },
  })
);
