import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      /**
       * Expand to fill available space
       */
      flex: 1,

      /**
       * Stretch single child
       */
      display: "flex",
      flexDirection: "column",
      justifyContent: "stretch",
      "& > *": {
        flex: 1,
      },
    },
    /**
     * The header bar containing the driver and date and back button
     */
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    /**
     * Basic info about the event (type, severity address, etc)
     */
    summary: {
      [theme.breakpoints.down("md")]: {
        /**
         * The grid has no spacing on tablet/mobile, so we need to add a
         * gutter beneath the grid to separate it from the cards below
         */
        marginBottom: theme.spacing(1),
      },
    },
    /**
     * Bars on cards for labels and buttons
     */
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      /**
       * Make height consistent when no buttons contained within
       * toolbar
       */
      minHeight: "3.325rem",
      padding: theme.spacing(1),
    },
  })
);
