import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { visuallyHidden } from "../../theme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Container
     */
    container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.primary.main,

      [theme.breakpoints.up("sm")]: {
        width: "18rem",
        minHeight: 0,
        boxShadow: theme.shadows[2],
        borderRadius: theme.shape.borderRadius,
      },
    },
    /**
     * Header area - contains logo and error messages
     */
    header: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2),
      backgroundColor: "#fff",

      "& > img": {
        width: 120,
      },

      "& > * + *": {
        marginTop: theme.spacing(1),
      },

      [theme.breakpoints.up("sm")]: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: "#fff",
      },
    },
    /**
     * Content wrapper
     */
    content: {
      display: "flex",
      padding: theme.spacing(2),

      "& > * + *": {
        marginTop: theme.spacing(1),
      },

      [theme.breakpoints.up("sm")]: {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
    /**
     * Form
     */
    form: {
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
    message: {
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      fontSize: "0.875rem",
      color: "#fff",
    },
    label: {
      ...visuallyHidden,
    },
    /**
     * Submit button
     */
    button: {
      marginTop: theme.spacing(2),
    },
  })
);
