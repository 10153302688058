import React from "react";
import { Item } from "../ItemList";
import { AccountListView } from "./views";
import { Account } from "../../interfaces";
import { useAccountsAsAdminApi } from "../../hooks/use-accounts-as-admin";

/**
 * Shows a list of accounts (fleets) and allows:
 * - Navigating to an edit screen for individual accounts
 * - Deleting one or more accounts
 */
export const AccountList: React.FC = () => {
  const [data, setData] = React.useState<Account[]>([]);
  const {
    loading,
    error,
    getAccounts,
    deleteAccount,
    deleting,
  } = useAccountsAsAdminApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getAccounts();
      setData(data ? data : []);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, []);
  /**
   * Used to determine whether to show a dismissable badge
   * confirming that a delete operation succeeded
   *
   * TODO: handle error via same mechanism
   */
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);

  const handleDelete = async (items: Item[]) => {
    try {
      for (const item of items) {
        await deleteAccount(item.id);
      }

      // Update the vehicle list state to remove the deleted vehicle
      setData((prevList) =>
        prevList.filter(
          (vehicle) => !items.some((item) => item.id === vehicle.id)
        )
      );

      setDeleteSuccess(true);
    } catch (error) {
      console.error("Failed to delete vehicle:", error);
      setDeleteSuccess(false);
      // Handle errors here, possibly set an error state
    }
  };

  return (
    <div>
      <AccountListView
        accounts={data}
        loading={loading || deleting}
        error={error}
        onDelete={handleDelete}
        deleteSuccess={deleteSuccess}
      />
    </div>
  );
};
