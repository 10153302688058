import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionDetails: {
      flexDirection: "column",
    },
    accordionEntry: {
      display: "block",
      width: "100%",
    },
    hidden: {
      display: "none",
    },
  })
);
