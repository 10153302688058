import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useCurrentSsoUser } from "../../hooks/auth";
import {
  CreateUserFormProps,
  EditUserFormProps,
  UserFormData,
  UserFormParams,
} from "./interfaces";
import { UserFormView } from "./views";
import { useUsersApi } from "../../hooks/use-users";
import { User } from "../../interfaces";

/**
 * A container to handle creating or editing a user
 *
 * It decides whether to show a create or edit form based on whether
 * there is an user ID present in the URL.
 */
export const UserForm: React.FC = () => {
  const { accountId, userId } = useParams<UserFormParams>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Typing this is a bit arduous
  const location = useLocation<any>();

  return userId ? (
    <EditUserForm
      id={userId}
      accountId={accountId}
      created={location.state?.created}
    />
  ) : (
    <CreateUserForm accountId={accountId} />
  );
};

/**
 * A container to handle updating the current user's profile
 */
export const ProfileForm: React.FC = () => {
  const { user } = useCurrentSsoUser();

  if (!user) return null;

  return <EditUserForm id={user.id} accountId={user.accountId} isProfile />;
};

const CreateUserForm: React.FC<CreateUserFormProps> = ({ accountId }) => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { createUser } = useUsersApi();

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (values: UserFormData) => {
    setIsSubmitting(true);
    try {
      const id = uuid();
      await createUser({ ...values, accountId, id });
      /**
       * Navigate to edit screen for the newly-created user
       */
      history.push(`/accounts/summary/${accountId}/users/edit/${id}`, {
        created: true,
      });
    } catch (e) {
      setSuccess(false);
      setError(true);
      setIsSubmitting(false);
    }
  };

  return (
    <UserFormView
      isSubmitting={isSubmitting}
      accountId={accountId}
      error={error}
      success={success}
      onSubmit={handleSubmit}
    />
  );
};

const EditUserForm: React.FC<EditUserFormProps> = ({
  id,
  accountId,
  created,
  isProfile,
}) => {
  const [data, setData] = React.useState<User | undefined>(undefined);
  const {
    loading,
    error,
    updateUser,
    updating,
    updateSuccess,
    getUser,
  } = useUsersApi();
  const { revalidate } = useCurrentSsoUser();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getUser(id);
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [id]);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (values: UserFormData) => {
    await updateUser(id, { ...values, accountId, id });
    if (isProfile) {
      await revalidate();
    }
  };

  return (
    <UserFormView
      user={data}
      id={id}
      loading={loading}
      error={error}
      accountId={accountId}
      onSubmit={handleSubmit}
      isSubmitting={updating}
      success={created || updateSuccess}
      isProfile={isProfile}
      isEdit
    />
  );
};
