import faker from "faker";
import { DateTime } from "luxon";
import { MediaStatuses, MediaTypes } from "../components/EventDetail/constants";
import {
  JourneySummary,
  Journey,
  JourneyPing,
  JourneyPingMediaItem,
} from "../interfaces";
import { coinFlip } from "./helpers";
import { makeUser } from "./users";

export function makeJourneyPing(data: Partial<JourneyPing>): JourneyPing {
  return {
    location: `${faker.address.latitude(54, 53)},${faker.address.longitude(
      -1,
      -2
    )}`,
    speed: parseFloat((Math.random() * 30).toFixed(2)),
    heading: Math.random() * 360,
    time: faker.date.recent().toISOString(),
    event: {
      id: faker.datatype.uuid(),
      media: [makeJourneyPingMediaItem()],
    },
    vehicleId: faker.datatype.uuid(),
    ...data,
  };
}
export function makeJourneySummary(
  data: Partial<JourneySummary> = {}
): JourneySummary {
  const start = faker.date.recent().toISOString();
  const end = faker.date.between(start, new Date().toISOString()).toISOString();
  const duration = DateTime.fromISO(end)
    .diff(DateTime.fromISO(start))
    .toFormat("hh:mm:ss");

  return {
    id: faker.datatype.uuid(),
    duration: duration,
    start: {
      ...makeJourneyPing({ time: start }),
      address: faker.address.streetAddress(),
    },
    end: {
      ...makeJourneyPing({ time: end }),
      address: faker.address.streetAddress(),
    },
    ...data,
    inProgress: false,
  };
}

export function makeJourneySummaries(
  num: number,
  dataFunc?: () => Partial<JourneySummary>
): JourneySummary[] {
  return Array(num)
    .fill("")
    .map((_) => makeJourneySummary(dataFunc ? dataFunc() : {}));
}

export function makeJourneyPingMediaItem(
  data: Partial<JourneyPingMediaItem> = {}
): JourneyPingMediaItem {
  return {
    channel: 1,
    mediaType: MediaTypes.video,
    status: coinFlip() ? MediaStatuses.requestable : MediaStatuses.available,
    requestedBy: makeUser(),
    ...data,
  };
}

/**
 * In reality, there would be a ping every 20 seconds, but this is a
 * reasonable approximation of the number of pings for the length of journey
 * this fixture will generate
 */
const defaultNumPings = 100;
export function makeJourneyFromJourneySummary(
  journeySummary: JourneySummary,
  { numPings }: { numPings: number } = { numPings: defaultNumPings }
): Journey {
  const startTime = new Date(journeySummary.start.time).getTime();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const endTime = new Date(journeySummary.end.time!).getTime();
  const startLatLng = journeySummary.start.location.split(",");
  const startLat = parseFloat(startLatLng[0]);
  const startLng = parseFloat(startLatLng[1]);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const endLatLng = journeySummary.end.location!.split(",");
  const endLat = parseFloat(endLatLng[0]);
  const endLng = parseFloat(endLatLng[1]);

  return {
    id: journeySummary.id,
    tracks: Array(numPings)
      .fill("")
      .map((_, i) => {
        const [lng, lat] = interpolate(
          { x: startLng, y: startLat },
          { x: endLng, y: endLat },
          i / numPings
        );
        return {
          location: `${lat},${lng}`,
          time: new Date(
            startTime + ((endTime - startTime) * i) / numPings
          ).toISOString(),
          speed: parseFloat((Math.random() * 60).toFixed(1)),
          heading: Math.random() * 360,
          address: faker.address.streetAddress(),
          event: {
            id: faker.datatype.uuid(),
            media: [makeJourneyPingMediaItem()],
            type: "journey",
          },
          vehicleId: faker.datatype.uuid(),
        };
      }),
    inProgress: false,
  };
}

/**
 * Useful when you want to make a journey with specific data in the pings.
 * Otherwise prefer makeJourneyFromJourneySummary
 */
export function makeJourneyWithPings(pings: JourneyPing[]): Journey {
  return {
    id: faker.datatype.uuid(),
    tracks: pings,
    inProgress: faker.datatype.boolean(),
  };
}

function interpolate(
  a: { x: number; y: number },
  b: { x: number; y: number },
  frac: number
): [number, number] {
  const nx = a.x + (b.x - a.x) * frac;
  const ny = a.y + (b.y - a.y) * frac;
  return [nx, ny];
}
