import { useState } from "react";
import useLiveApi from "./use-live-api";
import { Vehicle, VehicleUpdateBody } from "../interfaces";

interface VehicleApi {
  loading: boolean;
  error: boolean;
  updating: boolean;
  updateSuccess: boolean;
  getVehicle: (id: string) => Promise<Vehicle | undefined>;
  updateVehicle: (data: VehicleUpdateBody) => Promise<void>;
  createVehicle: (data: VehicleUpdateBody) => Promise<void>;
  deleteVehicle: (id: string) => Promise<void>;
  deleting: boolean;
}

export const useVehicleApi = (): VehicleApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const getVehicle = async (id: string) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(`/vehicle/${id}`, "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const vehicle = result as Vehicle;
      setLoading(false);

      return vehicle;
    } catch (e) {
      console.error("error fetching vehicle", e);
      setError(true);
      setLoading(false);

      return undefined;
    }
  };

  const updateVehicle = async (data: VehicleUpdateBody) => {
    try {
      setLoading(true);
      setUpdating(true);
      const response = await apiGatewayFetch(
        `/vehicle/${data.id}`,
        "put",
        JSON.stringify(data)
      );
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      setUpdateSuccess(true);
      await getVehicle(data.id);
    } catch (e) {
      console.error("error updating vehicle", e);
      setError(true);
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  };

  const deleteVehicle = async (id: string) => {
    try {
      setDeleting(true);
      const response = await apiGatewayFetch(`/vehicle/${id}`, "delete");
      if (response.status !== 204) {
        throw new Error(response.status.toString());
      }
    } catch (e) {
      console.error("error deleting vehicle", e);
      setError(true);
    } finally {
      setDeleting(false);
    }
  };

  const createVehicle = async (data: VehicleUpdateBody) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(
        "/vehicle",
        "post",
        JSON.stringify(data)
      );
      if (response.status !== 201) {
        throw new Error(response.status.toString());
      }
    } catch (e) {
      console.error("error creating vehicle", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    updating,
    updateSuccess,
    getVehicle,
    updateVehicle,
    createVehicle,
    deleteVehicle,
    deleting,
  };
};
