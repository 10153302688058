import road from "./assets/road.png";
import steeringwheel from "./assets/steeringwheel.png";
import warning from "./assets/warning.png";
import camera from "./assets/camera.png";
import background from "./assets/background.png";
import { Button } from "../Button";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useContext } from "react";

interface RamLiveDemoProps {
  salesforceId: string;
  salesforceContactId: string;
}

const containerStyle: React.CSSProperties = {
  position: "relative",
  height: "100vh",
  width: "100%",
  overflow: "hidden",
};

const backgroundStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  zIndex: -1,
  backgroundSize: "cover",
  backgroundPosition: "center",
  filter: "blur(8px)",
};

const RamLiveDemo: React.FC<RamLiveDemoProps> = ({
  salesforceId,
  salesforceContactId,
}) => {
  const auth: IAuthContext = useContext(AuthContext);

  const handleBackToTracking = () => {
    const url = "https://www.ramwebtracking.com/";

    auth.logOut();
    window.location.replace(url);
  };

  const liveCameraWatchDemoLinkClick = () => {
    Intercom("trackEvent", "livecamera-watchdemo-requested_v2");

    const url =
      "https://ram2ams.azurewebsites.net/api/RAMMarketing?" +
      "code=FZDqro1VuczY2-HPWgRzRoqwDUCfh0rMvKg9jRfrCjhYAzFu01v-tw==" +
      "&sf_campaign=Camera%20Icon" +
      "&utm_medium=Watch%20Demo&utm_campaign=Upgrade&utm_source=From%20RAM%20Tracking" +
      "&destinationURL=https%3A%2F%2Fwww2.ramtracking.com%2Fram-live-thank-you" +
      "&account_id=" +
      salesforceId +
      "&user_id=" +
      salesforceContactId;

    window.open(url, "_blank", "noopener");
  };

  const liveCameraUpgradeLinkClick = () => {
    Intercom("trackEvent", "livecamera-upgrade-requested_v2");

    const url =
      "https://ram2ams.azurewebsites.net/api/RAMMarketing?" +
      "code=FZDqro1VuczY2-HPWgRzRoqwDUCfh0rMvKg9jRfrCjhYAzFu01v-tw==" +
      "&sf_campaign=Camera%20Icon&utm_medium=Upgrade%20Options" +
      "&utm_campaign=Upgrade&utm_source=From%20RAM%20Tracking" +
      "&destinationURL=https%3A%2F%2Fwww2.ramtracking.com%2Fram-live-thank-you" +
      "&account_id=" +
      salesforceId +
      "&user_id=" +
      salesforceContactId;

    window.open(url, "_blank", "noopener");
  };

  return (
    <div style={containerStyle}>
      <div style={backgroundStyle}>
        <img alt={""} src={background} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
          padding: "20px",
          position: "relative",
          zIndex: 1,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          borderRadius: "10px",
          maxWidth: "90vh",
          maxHeight: "90vh",
          minWidth: "400px",
          boxShadow: "0px 0px 4px 0px lightgrey",
        }}
      >
        <div
          style={{
            display: "flex",
            maxWidth: "1200px",
            gap: "40px",
          }}
        >
          {/* Left Section (Text and Buttons) */}
          <div style={{ flex: 1 }}>
            <h2 style={{ fontSize: 20, fontWeight: 700 }}>
              Introducing RAM LIVE! - Live Streaming Dash Cams
            </h2>
            <p style={{ fontSize: 14, marginTop: "10px" }}>
              Upgrade your fleet management today with RAM Live - the ultimate
              connected dashcam solution offering:
            </p>

            <ul
              style={{ listStyleType: "none", padding: 0, marginTop: "20px" }}
            >
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={camera}
                  alt="Camera"
                  style={{ width: "40px", marginRight: "10px" }}
                />
                <span style={{ fontSize: "14px" }}>
                  Stream live to your vehicle’s forward and driver-facing
                  cameras
                </span>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={steeringwheel}
                  alt="Steering Wheel"
                  style={{ width: "40px", marginRight: "10px" }}
                />
                <span style={{ fontSize: "14px" }}>
                  Receive and monitor driver behavior video footage
                </span>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={road}
                  alt="Road"
                  style={{ width: "40px", marginRight: "10px" }}
                />
                <span style={{ fontSize: "14px" }}>
                  View routes driven and request footage of the journey
                </span>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={warning}
                  alt="Warning"
                  style={{ width: "40px", marginRight: "10px" }}
                />
                <span style={{ fontSize: "14px" }}>
                  Be notified instantly of impacts as they occur (First
                  Notification Of Loss)
                </span>
              </li>
            </ul>

            <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
              <Button
                id="liveCameraDialogueDemoButton"
                className="popupdialogueCtaButton"
                style={{ flex: 1 }}
                onClick={() => liveCameraWatchDemoLinkClick()}
                title="Watch Demo"
                variant="contained"
                color="primary"
              >
                Watch Demo
              </Button>
              <Button
                id="liveCameraDialogueCTAButton"
                className="popupdialogueCtaButton"
                style={{ flex: 1 }}
                onClick={() => liveCameraUpgradeLinkClick()}
                title="Upgrade Options"
                variant="contained"
                color="primary"
              >
                Upgrade Options
              </Button>
            </div>
            <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
              <Button
                id="backToTracking"
                style={{ flex: 1 }}
                onClick={() => handleBackToTracking()}
                title="Take me back to RAM Tracking"
                variant="contained"
                color="primary"
              >
                Take me back to RAM Tracking
              </Button>
            </div>
          </div>

          {/* Right Section (Video Feeds) */}
          <div style={{ flex: 1 }}>
            <iframe
              title="Example Front Camera Footage"
              height="210px"
              allowFullScreen
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "20px",
              }}
              src="https://fast.wistia.net/embed/iframe/ca21vnshpa"
            ></iframe>

            <iframe
              title="Example Driver Camera Footage"
              height="210px"
              allowFullScreen
              style={{ width: "100%", borderRadius: "10px" }}
              src="https://fast.wistia.net/embed/iframe/eh3pt8dihy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RamLiveDemo;
