import { useContext } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { apiUrl } from "../constants";
import { ImpersonationContext } from "../contexts/ImpersonationContext";

const useLiveApi = (): {
  apiGatewayFetch: (
    url: string,
    method: string,
    body?: string
  ) => Promise<Response>;
  checkOk: (response: Response) => Promise<Response>;
  buildHeaders: (bodyPresent: boolean) => Headers;
} => {
  const auth: IAuthContext = useContext(AuthContext);
  const { iUser } = useContext(ImpersonationContext);

  const apiGatewayFetch = async (
    endpoint: string,
    method: string,
    body?: string
  ) => {
    return await fetch(`${apiUrl}${endpoint}`, {
      method: method,
      headers: buildHeaders(body !== undefined),
      body: body,
    });
  };

  const buildHeaders = (bodyPresent: boolean) => {
    const headers = new Headers({
      Authorization: "Bearer " + auth.token,
    });

    if (bodyPresent) {
      headers.append("Content-Type", "application/json");
    }

    if (iUser) {
      headers.append("x-impersonation-accountid", iUser.id);
    }

    return headers;
  };

  const checkOk = (response: Response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  };

  return { apiGatewayFetch, checkOk, buildHeaders };
};

export default useLiveApi;
