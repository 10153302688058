import React, { Children } from "react";
import { CarMarkerProps } from "..";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import { usePingMarkerStyles } from "../styles";

export const GroupMarker: React.FC<CarMarkerProps> = ({
  heading,
  children,
}) => {
  const [open, setOpen] = React.useState(false);

  const classes = usePingMarkerStyles({ heading });
  const onClick = () => {
    setOpen(true);
  };
  const onMouseLeave = () => {
    setOpen(false);
  };

  if (open) {
    return (
      <div
        className={classes.group}
        onMouseLeave={onMouseLeave}
        style={{
          width: Children.count(children) * 24 + 20 + "px",
        }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      className={classes.parent}
      onMouseEnter={onClick}
      data-testid="group__marker"
    >
      <AddLocationIcon />
    </div>
  );
};
