import React from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import CameraIcon from "@material-ui/icons/Videocam";
import DeleteIcon from "@material-ui/icons/Delete";

import { AddButton, CloseButton, Panel, PanelHeader } from "../Panel";
import { FieldWrapper, LocalizedSelect, LocalizedTextField } from "../Input";
import { LocalizedButton, LocalizedIconButton } from "../Button";
import { LocalizedText } from "../LocalizedText";
import { SkeletonForm } from "../SkeletonForm";

import { CameraFormData, CameraFormViewProps } from "./interfaces";
import { cameraToFormValues } from "./transformers";
import { useStyles } from "./styles";
import { useTranslation } from "../../hooks/i18n";

/**
 * Validation rules for the form
 */
const schema = yup.object({
  deviceNumber: yup.number().required("device_number_required"),
  provider: yup.string().required("provider_required"),
  channels: yup
    .array()
    .of(
      yup.object({
        number: yup.number().required("channel_number_required"),
        description: yup.string().required("channel_description_required"),
      })
    )
    .min(1, "Please add at least one channel"),
});

/**
 * A form for creating or editing a camera
 */
export const CameraFormView: React.FC<CameraFormViewProps> = ({
  camera,
  loading,
  error,
  success,
  accountId,
  isSubmitting,
  onSubmit,
  isEdit,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [showSkeleton, setShowSkeleton] = React.useState(isEdit);

  const formik = useFormik<CameraFormData>({
    initialValues: {
      deviceNumber: "",
      provider: "",
      channels: [{ number: 0, description: "" }],
    },
    validateOnBlur: true,
    validationSchema: schema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  /**
   * Once camera has loaded, fill the form
   */
  React.useEffect(() => {
    if (!camera) return;

    formik.setValues(cameraToFormValues(camera));

    setShowSkeleton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formik changes each render so cannot be declared as a dependency
  }, [camera]);

  /**
   * Construct header text
   */
  const headerText =
    loading || camera ? (
      loading ? (
        <LocalizedText text="loading" />
      ) : (
        <>
          <LocalizedText text="editing" />: {camera?.providerId}
        </>
      )
    ) : (
      <LocalizedText text="create_camera" />
    );
  const header = (
    <PanelHeader icon={<CameraIcon />} title={headerText}>
      <CloseButton
        onClick={() => history.push(`/accounts/summary/${accountId}/cameras`)}
      />
    </PanelHeader>
  );

  return (
    <Panel header={header}>
      {error && (
        <Alert className={classes.alert} severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}
      {success && (
        <Alert className={classes.alert} severity="info">
          <LocalizedText text="changes_saved" />
        </Alert>
      )}

      {showSkeleton ? (
        <SkeletonForm numFields={6} />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="deviceNumber"
              name="deviceNumber"
              label="device_number"
              value={formik.values.deviceNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.deviceNumber &&
                Boolean(formik.errors.deviceNumber)
              }
              helperText={
                formik.touched.deviceNumber &&
                formik.errors.deviceNumber &&
                "device_number_required"
              }
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedSelect
              fullWidth
              label="provider"
              id="provider"
              name="provider"
              value={formik.values.provider}
              onChange={formik.handleChange}
              error={formik.touched.provider && Boolean(formik.errors.provider)}
              helperText={
                formik.touched.provider ? formik.errors.provider : undefined
              }
              /**
               * The provider list is hard-coded for now as we have only one
               * one provider and no providers API
               */
              options={[
                { label: "MFL", value: "MFL" },
                { label: "RAM (Queclink)", value: "RAM" },
              ]}
            />
          </FieldWrapper>

          <fieldset className={classes.channels}>
            <FormikProvider value={formik}>
              <FieldArray name="channels">
                {({ remove, push }) => (
                  <>
                    <div className={classes.channelsActions}>
                      <legend>
                        <LocalizedText text="channels" />
                      </legend>
                      <AddButton
                        label={t("add_channel")}
                        onClick={(e) => {
                          e.preventDefault();
                          push({ number: "", description: "" });
                        }}
                      />
                    </div>

                    {typeof formik.errors.channels === "string" && (
                      <Alert severity="error">
                        <LocalizedText text="at_least_one_channel_required" />
                      </Alert>
                    )}

                    {formik.values.channels.map((channel, i) => {
                      const numberKey = `channels.${i}.number`;
                      const descriptionKey = `channels.${i}.description`;
                      const error = formik.errors.channels?.[i];
                      const numberError =
                        typeof error === "string" ? error : error?.number;
                      const descriptionError =
                        typeof error === "string" ? error : error?.description;

                      return (
                        <div
                          data-testid="camera-form__channel"
                          key={i}
                          className={classes.channelRow}
                        >
                          <FieldWrapper>
                            <LocalizedTextField
                              id={numberKey}
                              name={numberKey}
                              label="channel_number"
                              type="number"
                              value={channel.number}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.channels?.[i]?.number &&
                                Boolean(numberError)
                              }
                              helperText={
                                formik.touched.channels?.[i]?.number &&
                                numberError
                              }
                            />
                          </FieldWrapper>

                          <FieldWrapper>
                            <LocalizedTextField
                              fullWidth
                              id={descriptionKey}
                              name={descriptionKey}
                              label="channel_description"
                              value={channel.description}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.channels?.[i]?.description &&
                                Boolean(descriptionError)
                              }
                              helperText={
                                formik.touched.channels?.[i]?.description &&
                                descriptionError
                              }
                            />
                          </FieldWrapper>

                          <LocalizedIconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.preventDefault();
                              remove(i);
                            }}
                          >
                            <DeleteIcon />
                          </LocalizedIconButton>
                        </div>
                      );
                    })}
                  </>
                )}
              </FieldArray>
            </FormikProvider>
          </fieldset>

          <LocalizedButton fullWidth disabled={isSubmitting} type="submit">
            {isSubmitting ? "saving" : "save"}
          </LocalizedButton>
        </form>
      )}
    </Panel>
  );
};
