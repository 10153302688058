import { useState } from "react";
import useLiveApi from "./use-live-api";
import { Account, AccountAlert } from "../interfaces";

interface AccountsApi {
  getAccount: (id: string) => Promise<Account | undefined>;
  updateAccountAlerts: (accountId: string, data: AccountAlert) => Promise<void>;
  updating: boolean;
  updateSuccess: boolean;
  loading: boolean;
  error: boolean;
}

export const useAccountsApi = (): AccountsApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const getAccount = async (id: string) => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch(`/account/${id}`, "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const account = result as Account;

      setLoading(false);
      return account;
    } catch (e) {
      console.error("error fetching account", e);
      setError(true);
      setLoading(false);

      return undefined;
    }
  };

  const updateAccountAlerts = async (accountId: string, data: AccountAlert) => {
    try {
      setIsUpdating(true);
      const response = await apiGatewayFetch(
        `/account/${accountId}/alerts`,
        "put",
        JSON.stringify(data)
      );
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      setUpdateSuccess(true);
    } catch (e) {
      console.error("error updating account alerts", e);
      setError(true);
      setUpdateSuccess(false);
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    getAccount,
    updateAccountAlerts,
    loading,
    error,
    updating: isUpdating,
    updateSuccess,
  };
};
