import { Box } from "@material-ui/core";
import CarIcon from "@material-ui/icons/DriveEta";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { CameraBadge } from "../../components/CameraBadge/views";
import { LocalizedText } from "../../components/LocalizedText";
import { CloseButton, Panel, PanelHeader } from "../../components/Panel";
import { Layout } from "../Layout";
import { useStyles } from "./styles";
import { JourneyTabView } from "./views";
import { LivestreamGoToButton } from "../../components/Livestream/LivestreamGoToButton";
import { useVehicleApi } from "../../hooks/use-vehicle";
import { Vehicle } from "../../interfaces";

interface VehiclePageParams {
  vehicleId: string;
  journeyId?: string;
}

/**
 * The landing page for the vehicles journey. Shows a paginated. searchable
 * list of all vehicles in the user's account and their last known positions on
 * a map
 */
export const VehiclePage: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  // const tabs = ["journeys"];

  const { vehicleId, journeyId } = useParams<VehiclePageParams>();
  const { getVehicle } = useVehicleApi();
  const [selectedJourney, setSelectedJourney] = React.useState<
    string | undefined
  >(journeyId);

  const [vehicleData, setVehicleData] = React.useState<Vehicle | undefined>(
    undefined
  );

  React.useEffect(() => {
    async function fetchData() {
      const data = await getVehicle(vehicleId);
      setVehicleData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId]);

  const handleBack = () => {
    history.push("/vehicles");
  };

  React.useEffect(() => {
    if (selectedJourney) {
      history.push(`/vehicles/${vehicleId}/journeys/${selectedJourney}`);
    }
  }, [history, selectedJourney, vehicleId]);

  const handleJourneyClick = (journeyId: string) => {
    setSelectedJourney(journeyId);
  };

  const title = vehicleData ? (
    <Box display="flex" alignItems="center">
      {vehicleData.registration}
      <Box display="inline-block" marginLeft={2}>
        <CameraBadge vehicle={vehicleData} useTooltip />
      </Box>
    </Box>
  ) : (
    <LocalizedText text="loading" />
  );

  return (
    <Layout menuCollapsed>
      <div className={classes.container}>
        <Panel
          header={
            <PanelHeader icon={<CarIcon />} title={title}>
              {vehicleData && <LivestreamGoToButton vehicle={vehicleData} />}
              <CloseButton onClick={handleBack} />
            </PanelHeader>
          }
        >
          {vehicleData && (
            <JourneyTabView
              vehicle={vehicleData}
              onJourneyClick={handleJourneyClick}
              selectedJourney={selectedJourney}
            />
          )}
        </Panel>
      </div>
    </Layout>
  );
};
