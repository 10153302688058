import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      zIndex: 1,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,

      "& a": {
        color: "inherit",
      },
    },
    /**
     * Bold highlight for heading text
     */
    detailsHeading: {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    /**
     * Legal text
     */
    legal: {
      /**
       * Line text up with RHS border on larger viewports
       */
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      [theme.breakpoints.up("lg")]: {
        textAlign: "right",
      },
    },

    regionContainer: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        justifyContent: "unset",
      },
    },
  })
);
