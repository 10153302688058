import React from "react";
import { Layout } from "../Layout";
import { useStyles } from "./styles";
import { Account, Vehicle } from "../../interfaces";
import CalendarRecordingsList from "../../components/CalendarRecordings/views";
import CalendarSearchBar from "../../components/CalendarSearchBar/views";
import { TablePaging } from "../../components/TablePaging/views";
import { useCalendarRecordingsApi } from "../../hooks/use-calendar-recordings";
import { useVehiclesForCurrentAccount } from "../../hooks/use-vehicles-for-current-account";
import { useAccountsApi } from "../../hooks/use-accounts";

export const CalendarPage: React.FC = () => {
  const classes = useStyles();
  const { getAccount } = useAccountsApi();
  const { data: vehiclesData } = useVehiclesForCurrentAccount(undefined, 300);

  const firstVehicle = vehiclesData?.[0];
  const [accountData, setAccountData] = React.useState<Account | undefined>(
    undefined
  );
  const [vehicle, setVehicle] = React.useState<Vehicle | undefined>(
    firstVehicle
  );
  const [selectedVehicleId, setSelectedVehicleId] = React.useState<string>(
    firstVehicle?.id || ""
  );

  React.useEffect(() => {
    if (vehicle) {
      setSelectedVehicleId(vehicle.id);
    }
  }, [vehicle]);

  const {
    data,
    loading,
    error,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = useCalendarRecordingsApi(selectedVehicleId!);

  React.useEffect(() => {
    if (!vehicle || !vehicle.accountId) return;

    async function fetchData() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const data = await getAccount(vehicle!.accountId);
      setAccountData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicle?.accountId]);

  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.searchBarContainer}>
          <CalendarSearchBar
            vehiclesData={vehiclesData}
            setVehicleId={setSelectedVehicleId}
            setRequestedVehicle={setVehicle}
          />
        </div>
        <CalendarRecordingsList
          loading={loading}
          recordings={data}
          account={accountData}
          selectedVehicleId={selectedVehicleId}
          vehicle={vehicle}
        />
        <TablePaging
          onNext={nextPage}
          onPrev={prevPage}
          showNext={hasNextPage}
          showPrev={hasPrevPage}
          loading={loading && !error}
        />
      </div>
    </Layout>
  );
};
