import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: "100%",
      position: "relative",
    },
  })
);
export const usePingPopupStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      /**
       * A fixed width helps keep consistent width when swapping button texts
       * between request/view video
       */
      minWidth: 160,
    },
    icon: {
      minWidth: 32,
    },
    /**
     * Pending media badges. We adjust padding here to make
     * them the same height as the request media buttons
     */
    alert: {
      "& .MuiAlert-icon": {
        padding: 0,
      },
      "& .MuiAlert-message": {
        paddingTop: 1,
        paddingBottom: 1,
      },
    },
    button: {
      marginBottom: "10px",
    },
    /**
     * Pending media tooltip
     * This is opened on hover, so we disable pointer events
     * to prevent an infinite hover/unhover loop
     */
    popover: {
      pointerEvents: "none",
    },
    popoverPaper: {
      width: "20rem",
      padding: theme.spacing(2),
    },
    tooltip: {
      backgroundColor: "white",
      boxShadow: theme.shadows[1],
      color: "black",
      width: "20rem",
      padding: theme.spacing(2),
    },
  })
);

export const useMapLegendStyles = makeStyles((theme: Theme) =>
  createStyles({
    legend: {
      maxHeight: "80%",
      position: "absolute",
      top: 80,
      left: 0,
      margin: theme.spacing(1.3),
      textAlign: "center",
      zIndex: 99,
      opacity: 0.85,
      color: "white",
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.7),
      borderRadius: "4px",
      fontSize: "80%",

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    legends: {
      color: theme.palette.text.primary,
    },
  })
);

export const useVideoLegendStyles = makeStyles((theme: Theme) =>
  createStyles({
    legend: {
      maxHeight: "80%",
      position: "absolute",
      left: "auto",
      right: 0,
      bottom: "auto",
      top: 80,
      margin: theme.spacing(1.3),
      textAlign: "center",
      zIndex: 99,
      opacity: 0.85,
      color: "white",
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.7),
      borderRadius: "4px",
      fontSize: "80%",

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    legends: {
      color: theme.palette.text.primary,
    },
  })
);
