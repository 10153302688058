import React from "react";
import { SignInForm } from "./views";

/**
 * Sign in form container
 * Shows either the sign in or force change new password forms
 */
export const SignIn: React.FC = () => {
  return <SignInForm />;
};
