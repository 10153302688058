import React from "react";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";

import { SimpleInputProps, SelectProps, CheckboxProps } from "./interfaces";
import {
  useSimpleInputStyles,
  useSelectStyles,
  useFieldWrapperStyles,
} from "./styles";

/**
 * Simple input for use on dark backgrounds, where an outlined input
 * with a floating label wouldn't work visually, or anywhere where an
 * input with an accessibly hidden label is useful.
 *
 * In general use of this component should be limited to the few situtations
 * where its purpose is obvious even when the placeholder text (which will
 * vanish once text is entered) is not visible, for example in a sign in form.
 *
 */
export const SimpleInput: React.FC<SimpleInputProps> = (props) => {
  const classes = useSimpleInputStyles(props);

  return (
    <>
      <label htmlFor={props.id} className={classes.label}>
        <Typography>{props.label}</Typography>
      </label>

      <InputBase {...props} className={classes.input} />
    </>
  );
};

/**
 * A select input with a label. Material doesn't provide a unified component
 * for this use-case (for text inputs it has TextField) so this component
 * provides just that to reduce boilerplate code
 *
 */
export const Select: React.FC<SelectProps> = ({
  children,
  helperText,
  error,
  fullWidth,
  options,
  ...props
}) => {
  const classes = useSelectStyles(props);
  const labelId = `${props.id}-label`;

  return (
    <FormControl variant="outlined" fullWidth={fullWidth}>
      <InputLabel id={labelId}>{props.label}</InputLabel>

      <MaterialSelect
        {...props}
        classes={{ select: classes.preview }}
        error={error}
        fullWidth={fullWidth}
        labelId={labelId}
        MenuProps={{
          getContentAnchorEl: null,
        }}
        renderValue={
          props.multiple
            ? (selected) =>
                (selected as string[])
                  .map(
                    (value) =>
                      options.find((option) => option.value === value)?.label
                  )
                  .join(", ")
            : undefined
        }
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {props.multiple ? (
              <>
                <MaterialCheckbox
                  checked={(props.value as string[])?.includes(value)}
                />
                <ListItemText primary={label} />
              </>
            ) : (
              label
            )}
          </MenuItem>
        ))}
      </MaterialSelect>

      {helperText && (
        <FormHelperText error={error} variant="outlined">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

/**
 * A checkbox with a label and optional helper text
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  name,
  helperText,
}) => {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <MaterialCheckbox checked={checked} onChange={onChange} name={name} />
        }
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
/**
 * A simple wrapper for an input to space it from others
 */
export const FieldWrapper: React.FC = ({ children }) => {
  const classes = useFieldWrapperStyles();
  return <div className={classes.container}>{children}</div>;
};
