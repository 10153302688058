import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layout: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    productSelector: {
      /**
       * Header contains logo, user image and burger menu
       */
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      /**
       * Maintain same height when collapsed and avatar is not rendered
       */
      minHeight: "10vh",
      padding: theme.spacing(2, 1),
      backgroundColor: "#fff",

      /**
       * Rounded corners on large viewports only (component is full width on small viewports)
       */
      [theme.breakpoints.up("lg")]: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },

      /**
       * Set logo size
       */
      "& img": {
        maxWidth: "100%",
        width: 72,
      },
    },
    container: {
      flex: 1,
      overflow: "auto",

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(12, 1, 1, 1),
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        alignItems: "stretch",

        /**
         * Add spacing after sidebar
         */
        "& > :nth-child(1)": {
          marginRight: theme.spacing(2),
        },

        /**
         * Stretch second child (i.e. not the sidebar)
         * to fill remaining space
         *
         * This means if you want more than one view
         * showing in the main area you should wrap it
         * in a container (you may often want to use
         * <ListDetailLayout />)
         */
        "& > :nth-child(2)": {
          flex: 1,
        },
      },
    },
  })
);

export const useListDetailStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      /**
       * Hide list views on tablet and smaller screens
       */
      [theme.breakpoints.down("md")]: {
        "& > :first-child": {
          display: "none",
        },
      },

      [theme.breakpoints.up("lg")]: {
        display: "grid",
        gap: 16,
        gridTemplateColumns: "1fr 1.5fr",
        alignItems: "stretch",
        flex: 1,

        "& > *": {
          minHeight: 0,
        },
      },
    },
  })
);
