import React from "react";
import { useParams } from "react-router-dom";

import { AccountSummaryParams } from "./interfaces";
import { AccountSummaryView } from "./views";
import { useAccountsApi } from "../../hooks/use-accounts";
import { Account } from "../../interfaces";

/**
 * A container to load a summary of an account (fleet)
 */
export const AccountSummary: React.FC = () => {
  const { accountId } = useParams<AccountSummaryParams>();
  const [data, setData] = React.useState<Account | undefined>(undefined);
  const { loading, error, getAccount } = useAccountsApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getAccount(accountId);
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [accountId]);

  return <AccountSummaryView account={data} loading={loading} error={error} />;
};
