import faker from "faker";
import { DateTime } from "luxon";
import { MediaStatuses, MediaTypes } from "../components/EventDetail/constants";
import { DrivingEvent, DrivingEventMediaItem } from "../interfaces";
import { randomArrayElement } from "./helpers";
import { makeUser } from "./users";
import { makeVehicle } from "./vehicle";

const types = [
  "ACCELERATION",
  "BRAKING",
  "CORNERING",
  "BUMP",
  "IMPACT",
  "USER",
] as DrivingEvent["type"][];
const severities = ["MILD", "MODERATE", "SEVERE"] as DrivingEvent["severity"][];

export function makeDrivingEventMediaItem(
  data: Partial<DrivingEventMediaItem> = {}
): DrivingEventMediaItem {
  return {
    channel: 1,
    channelDesc: "Front facing camera",
    mediaType: MediaTypes.image,
    status: MediaStatuses.available,
    link: "/example-media/image1.jpg",
    requestedBy: makeUser(),
    ...data,
  };
}

export function makeDrivingEventImage(
  data: Partial<DrivingEventMediaItem> = {}
): DrivingEventMediaItem {
  return {
    ...makeDrivingEventMediaItem(),
    mediaType: MediaTypes.image,
    link: "/example-media/image1.jpg",
    ...data,
  };
}

export function makeDrivingEventVideo(
  data: Partial<DrivingEventMediaItem> = {}
): DrivingEventMediaItem {
  return {
    ...makeDrivingEventMediaItem(),
    mediaType: MediaTypes.video,
    link: "/example-media/video.mp4",
    ...data,
  };
}

export function makeDrivingEvent(
  data: Partial<DrivingEvent> = {}
): DrivingEvent {
  const numImages = data.images || Math.round(Math.random() * 1 + 1);
  const numVideos = data.videos || Math.round(Math.random() * 1 + 1);
  return {
    id: faker.datatype.uuid(),
    cameraId: faker.datatype.uuid(),
    vehicle: makeVehicle({}, false),
    type: data.type
      ? data.type
      : randomArrayElement(types.filter((f) => f !== "HYPERLAPSE")),
    location: `${faker.address.latitude(54, 53)},${faker.address.longitude(
      -1,
      -2
    )}`,
    address: faker.address.streetAddress(),
    date: DateTime.fromISO(faker.date.recent().toISOString()).toUTC().toISO(),
    severity: randomArrayElement(severities),
    media: [
      ...Array(numImages)
        .fill("")
        .map((_, i) => makeDrivingEventImage({ channel: i + 1 })),
      ...Array(numVideos)
        .fill("")
        .map((_, i) => makeDrivingEventVideo({ channel: i + 1 })),
    ],
    images: numImages,
    videos: numVideos,
    heading: 45,
    ...data,
  };
}

export function makeDrivingEvents(
  num: number,
  dataFunc?: () => Partial<DrivingEvent>
): DrivingEvent[] {
  return Array(num)
    .fill("")
    .map((_) => makeDrivingEvent(dataFunc ? dataFunc() : {}));
}
