import React, { useState, useCallback } from "react";
import { Box, Grid } from "@material-ui/core";
import { LivestreamProps } from "../../pages/Livestream/interfaces";
import { LivestreamControls } from "./LivestreamControls";
import { useStyles } from "./styles";
import ReactPlayer from "react-player";
import { FieldWrapper } from "../Input/views";
import { LocalizedSelect } from "../Input/containers";
import {
  StartStreamResponse,
  useLivestreamApi,
} from "../../hooks/use-livestream";

export const Livestream: React.FC<LivestreamProps> = ({ camera }) => {
  const classes = useStyles();

  const { requestStream, stopStream, sendHeartbeat } = useLivestreamApi();
  const [isLivestreaming, setIsLivestreaming] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [channel, setChannel] = useState<string>(
    camera.channels[0].number.toString()
  );
  const [error, setError] = useState<string | undefined>();
  const [retryCount, setRetryCount] = useState<number>(0);

  const handleStartLivestream = async () => {
    setLoading(true);
    setError(undefined);

    try {
      const numberChannel = Number(channel);
      const res = await requestStream(camera.id, numberChannel);

      if (res) {
        const response = (res as unknown) as StartStreamResponse;
        setUrl(response.data.streamingUrl);
        // setUrl("http://sample.vodobox.net/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8");
        setIsLivestreaming(true);
      }
    } catch (e) {
      console.error(e);
      if (e.response) {
        const { message } = e.response.data;

        setError(message);
        setLoading(false);
      }
    }
  };

  const handleCancelLivestream = () => {
    setLoading(false);
    setIsLivestreaming(false);
  };

  const handleStopLivestream = useCallback(() => {
    setIsLivestreaming(false);
    handleVideoEnded();
    stopStream(camera.id, 0).then((res) => console.log(res));
  }, [camera.id, stopStream]);

  const heartbeat = useCallback(async () => {
    try {
      await sendHeartbeat(camera.id, 1);
    } catch (e) {
      console.error(e);
    }
  }, [camera.id, sendHeartbeat]);

  /**
   * When an error occurs, we first check if it's just the stream starting up still.
   *
   * If not, we assume it has ended and stop the livestream.
   */
  const handleVideoLoadError = () => {
    if (isLivestreaming && loading) {
      setTimeout(() => setRetryCount((prev) => prev + 1), 10000);
    } else {
      handleVideoEnded();
      setError("Livestream has ended.");
      setIsLivestreaming(false);
    }
  };

  /**
   * When the video is ready and started, we officially start the countdown to when the user
   * can start another stream.
   *
   * A heartbeat is sent to make sure the stream lasts at least 30 seconds.
   */
  const handleVideoReady = () => {
    setLoading(false);
    heartbeat().then((res) => {
      console.log("Heartbeat sent, stream should close in 30s");
    });
  };

  const handleVideoEnded = () => {
    localStorage.setItem("lastStreamTime", new Date().getTime().toString());
  };

  const handleCameraDirectionChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    setChannel(value);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box className={classes.root}>
          <FieldWrapper>
            <LocalizedSelect
              fullWidth
              label="Camera Direction"
              id="cameraDirection"
              name="cameraDirection"
              value={channel}
              disabled={isLivestreaming || loading}
              onChange={handleCameraDirectionChange}
              options={camera.channels.map((channel) => {
                return {
                  label: channel.description,
                  value: channel.number.toString(),
                };
              })}
            />
          </FieldWrapper>
          <Box height={360} maxWidth={640} className={classes.playerContainer}>
            {isLivestreaming && (
              <ReactPlayer
                key={`react-player-${retryCount}`}
                data-testid={"livestream-player"}
                url={url || ""}
                muted
                controls
                playing={true}
                className={classes.player}
                width={"100%"}
                height={"100%"}
                onError={handleVideoLoadError}
                onReady={handleVideoReady}
                onEnded={handleVideoEnded}
              />
            )}
            {!isLivestreaming && (
              <Box
                height={360}
                maxWidth={640}
                bgcolor={"#222"}
                data-testid={"livestream-placeholder"}
              />
            )}
          </Box>
          <LivestreamControls
            isLivestreaming={isLivestreaming}
            loading={loading}
            onStart={handleStartLivestream}
            onStop={handleStopLivestream}
            onCancel={handleCancelLivestream}
          />
          <p className={classes.error}>{error}</p>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <h2 className={classes.description}>Live Streaming</h2>
        <br />
        <p>
          RAM Live streaming depends on the camera being online and having good
          mobile data signal and is subject to our fair usage policy.
          <br />
          <br />
          To start a live stream please click on the 'Start Live Stream' button;
          this will connect to the camera and start a live stream. It can take a
          few minutes to start the stream depending on the connection to the
          camera, so please don't refresh the screen.
          <br />
          <br />
          Due to data consumption limitations, the live stream is limited to 30
          seconds. You will have to wait a minute after the end of the stream
          before restarting a live stream.
        </p>
      </Grid>
    </Grid>
  );
};
