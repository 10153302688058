import React from "react";
import { initialSort } from ".";
import { MediaListContextValues } from "../../pages/Media/interfaces";
import { getInitialFilters } from "./MediaLibraryFilters";

export const MediaListContext = React.createContext<MediaListContextValues>({
  filters: getInitialFilters(),
  sort: initialSort,
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- default value must match shape of expected value (@see https://reactjs.org/docs/context.html#updating-context-from-a-nested-component)
  setFilters: (_) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- see above
  setSort: (_) => {},
  selectedMedia: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- see above
  setSelectedMedia: (_) => {},
});
