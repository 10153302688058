import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { LocalizedButton } from "../../Button";
import { LocalizedText } from "../../LocalizedText";
import { HyperlapseSubmitModalProps } from "./interfaces";

export const HyperlapseSubmitModal: React.FC<HyperlapseSubmitModalProps> = ({
  open = false,
  onClose,
}) => {
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="simple-dialog-title">
        <LocalizedText text="hyperlapse_submitted_title" />
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <LocalizedText text="hyperlapse_submitted_body" />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <LocalizedButton onClick={onClose} color="primary" variant="text">
          OK
        </LocalizedButton>
      </DialogActions>
    </Dialog>
  );
};
