import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getIn } from "formik";
import { LocalizedTextField } from "../Input";
import { FieldWrapper } from "../Input/views";
import {
  ProviderAccordionEntryProps,
  ProviderAccordionProps,
} from "./interfaces";
import { useStyles } from "./styles";

export const ProviderAccordion: React.FC<ProviderAccordionProps> = ({
  providers,
  formik,
}) => {
  const classes = useStyles();

  const forms = providers.map((provider) => (
    <ProviderAccordionForm provider={provider} formik={formik} key={provider} />
  ));
  return (
    <Accordion data-testid="provider-accordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Providers</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {forms}
      </AccordionDetails>
    </Accordion>
  );
};

const ProviderAccordionForm: React.FC<ProviderAccordionEntryProps> = ({
  provider,
  formik,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.accordionEntry}>
      <Accordion data-testid={`${provider}-accordion`}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{provider}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <FieldWrapper>
            <LocalizedTextField
              className={classes.hidden}
              fullWidth
              disabled
              id={`providers.${provider}.name`}
              name={`providers.${provider}.name`}
              label="provider"
              value={getIn(formik.values, `providers.${provider}.name`)}
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id={`providers.${provider}.username`}
              name={`providers.${provider}.username`}
              label="provider_username"
              value={formik.values.providers?.[provider]?.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                getIn(formik.touched, `providers.${provider}.username`) &&
                Boolean(getIn(formik.errors, `providers.${provider}.username`))
              }
              helperText={
                getIn(formik.touched, `providers.${provider}.username`) &&
                getIn(formik.errors, `providers.${provider}.username`)
              }
              autoComplete="off"
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id={`providers.${provider}.password`}
              name={`providers.${provider}.password`}
              label="provider_password"
              value={formik.values.providers?.[provider]?.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                getIn(formik.touched, `providers.${provider}.password`) &&
                Boolean(getIn(formik.errors, `providers.${provider}.password`))
              }
              helperText={
                getIn(formik.touched, `providers.${provider}.password`) &&
                getIn(formik.errors, `providers.${provider}.password`)
              }
              autoComplete="off"
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id={`providers.${provider}.fleetId`}
              name={`providers.${provider}.fleetId`}
              label="provider_fleet_id"
              value={formik.values.providers?.[provider]?.fleetId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                getIn(formik.touched, `providers.${provider}.fleetId`) &&
                Boolean(getIn(formik.errors, `providers.${provider}.fleetId`))
              }
              helperText={
                getIn(formik.touched, `providers.${provider}.fleetId`) &&
                getIn(formik.errors, `providers.${provider}.fleetId`)
              }
              autoComplete="off"
            />
          </FieldWrapper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
