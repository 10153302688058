import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import * as Yup from "yup";
import { SubmitButton } from "../../Button";
import { HyperlapseRequestFormProps } from "./interfaces";
import { useStyles } from "./styles";
import { formDateToDate } from "../RequestVideoDialog/transformers";

export const HyperlapseRequestForm: React.FC<HyperlapseRequestFormProps> = ({
  start,
  end,
  onSubmit,
}) => {
  const MAX_DURATION = "60";

  const classes = useStyles();

  const schema = Yup.object().shape({
    date: Yup.string()
      .required("Required")
      .test("validatorDateWithinBounds", "date_invalid", (value) => {
        if (!value || !start || !end) return false;
        const date = formDateToDate(value);

        const format = "yyyy-MM-dd hh:mm:ss";
        const selectedDateTime = DateTime.fromFormat(date, format);
        const startDateTime = DateTime.fromFormat(start, format);
        const endDateTime = DateTime.fromFormat(end, format);

        /**
         * If diff is positive, then d is before comparison
         *
         * If diff is negative, then d is after the comparison
         **/
        if (selectedDateTime.diff(startDateTime).milliseconds < 0) {
          return false;
        }
        if (selectedDateTime.diff(endDateTime).milliseconds > 0) {
          return false;
        }

        return true;
      }),
    duration: Yup.number().required("Required"),
    channel: Yup.number().required("Required"),
  });

  const isInBoundary = (duration: string) => {
    if (!start || !end) return true; //Allow all if no boundaries specified

    const futureTime = DateTime.fromFormat(start, "yyyy-MM-dd HH:mm:ss")
      .plus({ minutes: Number(duration) })
      .minus({ seconds: 10 }); //Allow 10 second buffer to stop overlap of journey
    return (
      futureTime.diff(DateTime.fromFormat(end, "yyyy-MM-dd HH:mm:ss"))
        .milliseconds < 0
    );
  };

  const maxDuration = () => {
    if (!start || !end) return MAX_DURATION;

    const diff = DateTime.fromFormat(end, "yyyy-MM-dd HH:mm:ss").diff(
      DateTime.fromFormat(start, "yyyy-MM-dd HH:mm:ss")
    ).milliseconds; //10s buffer

    const minutes = diff / 1000 / 60;

    if (minutes >= Number(MAX_DURATION)) return MAX_DURATION;

    return minutes.toFixed(1);
  };

  return (
    <Formik
      initialValues={{
        date: start?.slice(0, 19) || "",
        duration: "",
        channel: "",
      }}
      validationSchema={schema}
      validateOnChange={true}
      onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
        onSubmit(values, setSubmitting, setErrors, resetForm);
      }}
    >
      {({ values, errors, handleChange, isSubmitting }) => (
        <Form className={classes.form}>
          <FormControl fullWidth>
            <TextField
              id="time"
              label="Start"
              name="date"
              type="datetime-local"
              value={values.date}
              onChange={handleChange}
              error={Boolean(errors.date)}
              disabled={isSubmitting}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 1, // 1 min
                min: start?.slice(0, 19) || "",
                max: end?.slice(0, 19) || "",
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Duration</InputLabel>
            <Select
              value={values.duration}
              name="duration"
              onChange={handleChange}
              error={Boolean(errors.duration)}
              disabled={isSubmitting}
              label="duration"
            >
              <MenuItem value="">Please select a duration...</MenuItem>
              {isInBoundary("5") && <MenuItem value="5">5 Minutes</MenuItem>}
              {isInBoundary("10") && <MenuItem value="10">10 Minutes</MenuItem>}
              {isInBoundary("15") && <MenuItem value="15">15 Minutes</MenuItem>}
              {isInBoundary("30") && <MenuItem value="30">30 Minutes</MenuItem>}
              <MenuItem value={maxDuration()}>
                {maxDuration()} Minutes (Max)
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Camera</InputLabel>
            <Select
              value={values.channel}
              name="channel"
              onChange={handleChange}
              error={Boolean(errors.channel)}
              disabled={isSubmitting}
              label="channel"
            >
              <MenuItem value="">Please select a camera...</MenuItem>
              <MenuItem value="1">Front Facing</MenuItem>
              <MenuItem value="2">Driver Facing</MenuItem>
            </Select>
          </FormControl>

          <Box mt={2}>
            <FormControl fullWidth>
              <SubmitButton isSubmitting={isSubmitting}>Submit</SubmitButton>
            </FormControl>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
