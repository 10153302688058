import Grid from "@material-ui/core/Grid";
import React from "react";
import { JourneyList } from "../../components/JourneyList";
import { JourneyMap } from "../../components/JourneyMap";
import { JourneyTabViewProps } from "./interfaces";
import { useJourneyTabStyles } from "./styles";

export const JourneyTabView: React.FC<JourneyTabViewProps> = ({
  vehicle,
  onJourneyClick,
  selectedJourney,
}) => {
  const classes = useJourneyTabStyles();

  return (
    <Grid container spacing={2} className={classes.content}>
      <Grid item xs={12} md={6} className={classes.column}>
        <JourneyList
          vehicle={vehicle}
          onJourneyClick={onJourneyClick}
          selectedJourney={selectedJourney}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.column}>
        {selectedJourney && (
          <JourneyMap vehicle={vehicle} journeyId={selectedJourney} />
        )}
      </Grid>
    </Grid>
  );
};
