import React from "react";
import { EventContextValues } from "./interfaces";

export const EventContext = React.createContext<EventContextValues>({
  event: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- default value must match shape of expected value (@see https://reactjs.org/docs/context.html#updating-context-from-a-nested-component)
  requestImage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- see above
  requestVideo: () => {},
});
