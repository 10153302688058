import { useContext } from "react";
import { ssoAdminRole } from "../../constants";
import { UserContext } from "../../contexts/UserContext";
import { Account, Vehicle } from "../../interfaces";
import { blockAccessToHyperlapseAndLivestream } from "../../utils";
import { useAccountsApi } from "../../hooks/use-accounts";
import React from "react";

interface TooltipDetailsProps {
  vehicle: Vehicle;
}

const TooltipDetails: React.FC<TooltipDetailsProps> = ({ vehicle }) => {
  const { ctxUser } = useContext(UserContext);
  const isAdmin = ctxUser?.roles?.includes(ssoAdminRole);
  const { getAccount } = useAccountsApi();
  const [data, setData] = React.useState<Account | undefined>(undefined);

  React.useEffect(() => {
    async function fetchData() {
      const data = await getAccount(vehicle.accountId);
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicle.accountId]);
  const blockAccountAccess = blockAccessToHyperlapseAndLivestream(data);

  const hasQueclinkCamera = (): boolean => {
    const validProviders = ["RAM"];

    return vehicle.cameras.some((camera) => {
      return validProviders.includes(camera.provider);
    });
  };

  const LiveDescription = () => (
    <>
      LIVE - Vehicle is currently in a journey and available to Live Stream.
      Historic media can also be requested.
      <br />
      <br />
    </>
  );

  return (
    <>
      {hasQueclinkCamera() && !blockAccountAccess && <LiveDescription />}
      ONLINE - Historic media is able to be requested directly from the camera
      and any previous requests should have begun processing if they haven't
      already.
      <br />
      <br />
      OFFLINE - Historic media can be requested but will not be processed until
      the vehicle ignition is on.
      <br />
      <br />
      UNKNOWN -
      {isAdmin
        ? " The status of this vehicles could not be obtained from the provider. Double check the provider credentials have been properly inputted and the device is correctly commissioned."
        : " The status of this vehicle could not be obtained. Please try again later. If the problem persists, contact support."}
    </>
  );
};

export default TooltipDetails;
