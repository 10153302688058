const adminEmails = [
  "dano@ramtracking.com",
  "brent@ramtracking.com",
  "antonyr@ramtracking.com",
  "niallw+rl@ramtracking.com",
  "jarede@ramtracking.com",
  "patrickb@ramtracking.com",
  "kais@ramtracking.com",
  "benjaminw@ramtracking.com",
];

export const hasAccessToCustomerMedia = (email?: string): boolean => {
  return email ? adminEmails.includes(email) : false;
};
