import faker from "faker";
import { User } from "../interfaces";

export function makeUser(data: Partial<User> = {}): User {
  return {
    id: faker.datatype.uuid(),
    accountId: faker.datatype.uuid(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.exampleEmail(),
    salesforceContactId: faker.datatype.uuid(),
    ...data,
  };
}

export function makeUsers(num: number, dataFunc?: () => Partial<User>): User[] {
  return Array(num)
    .fill("")
    .map((_) => makeUser(dataFunc ? dataFunc() : {}));
}
