import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
import VideocamIcon from "@material-ui/icons/Videocam";

import { useStyles, StyledTableRow, StyledTableCell } from "./styles";
import { EventsTableProps, EventRowProps } from "./interfaces";
import { LocalizedText } from "../LocalizedText";
import { useTranslation } from "../../hooks/i18n";
import { LocalizedButton } from "../Button";

/**
 * Table view component
 * Displays the events themselves
 *
 * This component is entirely "controlled", meaning that
 * it doesn't hold state about the current sort direction
 * - its parent is responsible for passing this back in
 * on each occurrence of onSort.
 *
 * This makes it easy to load the component with an initial
 * sort (e.g. when deeplinking)
 *
 * @see https://reactjs.org/docs/forms.html#controlled-components
 */
export const EventsTable: React.FC<EventsTableProps> = ({
  events,
  loading,
  error,
  onSort,
  sort,
  numEvents,
  onSelect,
  selected,
}) => {
  const classes = useStyles({ loading });

  const handleSort = () => {
    const newSort = sort === "desc" ? "asc" : "desc";
    onSort(newSort);
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      {loading && (
        <div data-testid="events-loading" className={classes.tableLoading}>
          Loading
        </div>
      )}
      {!loading && !error && events.length === 0 && (
        <div className={classes.emptyMessage}>
          <Alert severity="info">
            <LocalizedText text="no_events_in_date_range" />
          </Alert>
        </div>
      )}
      {error && (
        <div className={classes.emptyMessage}>
          <Alert severity="error">
            <LocalizedText text="error_loading_events" />
          </Alert>
        </div>
      )}

      <Table className={classes.table} aria-label="Driving events" stickyHeader>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>
              <LocalizedText text="media" />
            </StyledTableCell>
            <StyledTableCell>
              <LocalizedButton
                aria-label="sort_by_date"
                onClick={handleSort}
                color="primary"
              >
                date
                {sort === "asc" ? <ArrowDropDown /> : <ArrowDropUp />}
              </LocalizedButton>
            </StyledTableCell>
            <StyledTableCell>
              <LocalizedText text="vehicle" />
            </StyledTableCell>
            <StyledTableCell>
              <LocalizedText text="type" />
            </StyledTableCell>
            <StyledTableCell>
              <LocalizedText text="severity" />
            </StyledTableCell>
            <StyledTableCell>
              <LocalizedText text="location" />
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {loading
            ? Array(numEvents)
                .fill("")
                .map((_, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            : events &&
              events.map((event) => (
                <EventRow
                  key={event.id}
                  event={event}
                  onSelect={onSelect}
                  selected={selected?.id === event.id}
                />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/**
 * An individual event row
 */
const EventRow: React.FC<EventRowProps> = ({ event, onSelect, selected }) => {
  const { t } = useTranslation();
  const classes = useStyles({});

  return (
    <StyledTableRow
      data-testid="driving-event-row"
      onClick={() => onSelect(event)}
    >
      <StyledTableCell className={selected ? classes.selectedRow : undefined}>
        {event.videos > 0 && (
          <span aria-label={t("video_available")}>
            <VideocamIcon />
          </span>
        )}
      </StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        className={selected ? classes.selectedRow : undefined}
      >
        {event.date}
      </StyledTableCell>
      <StyledTableCell className={selected ? classes.selectedRow : undefined}>
        {event.vehicle.registration}{" "}
        {event.vehicle.driver ? "(" + event.vehicle.driver + ")" : ""}{" "}
      </StyledTableCell>
      <StyledTableCell className={selected ? classes.selectedRow : undefined}>
        {event.type}
      </StyledTableCell>
      <StyledTableCell className={selected ? classes.selectedRow : undefined}>
        {event.severity}
      </StyledTableCell>
      <StyledTableCell className={selected ? classes.selectedRow : undefined}>
        {event.address || event.location}
      </StyledTableCell>
    </StyledTableRow>
  );
};
