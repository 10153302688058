import { DateTime } from "luxon";

import { Filters } from "./interfaces";

export const getInitialFilters = (): Filters => ({
  type: [],
  severity: [],
  user: [],
  vehicle: [],
  videoOnly: true,
  date: [
    DateTime.now().startOf("day").minus({ days: 30 }).toISO(),
    DateTime.now().endOf("day").toISO(),
  ],
});
