import React from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "../../hooks/i18n";
import { formatEventDataForDisplay } from "../EventList/transformers";

import { EventDetailParams, EventDetailProps } from "./interfaces";
import { EventDetailView } from "./views";
import { useStyles } from "./styles";
import { EventContext } from "./EventContext";
import { useEventApi } from "../../hooks/use-event";
import { DrivingEvent } from "../../interfaces";

export const EventDetail: React.FC<EventDetailProps> = ({ onBack }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { vehicleId, eventId } = useParams<EventDetailParams>();
  const [event, setEvent] = React.useState<DrivingEvent | undefined>(undefined);
  const {
    loading,
    error,
    requestImage,
    requestVideo,
    getEvent,
  } = useEventApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getEvent({ vehicleId, eventId });
      setEvent(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId, eventId]);

  const localizedEvent = event
    ? formatEventDataForDisplay(t, event)
    : undefined;

  return (
    <EventContext.Provider
      value={{
        event,
        requestImage,
        requestVideo,
      }}
    >
      <div className={classes.container}>
        <EventDetailView
          loading={loading}
          error={error}
          event={localizedEvent}
          onBack={onBack}
        />
      </div>
    </EventContext.Provider>
  );
};
