import React from "react";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "../../hooks/i18n";
import { MapView } from "../Map";

import { JourneyMapProps } from "./interfaces";
import { JourneyMapView } from "./views";
import { useJourneysApi } from "../../hooks/use-journeys";
import { Journey } from "../../interfaces";

export const JourneyMap: React.FC<JourneyMapProps> = ({
  vehicle,
  journeyId,
}) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState<Journey | undefined>(undefined);
  const { loading, error, requestVideoForDate, getJourney } = useJourneysApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getJourney({
        vehicleId: vehicle.id,
        journeyId,
      });
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [journeyId, vehicle.id]);

  const { ctxUser } = React.useContext(UserContext);

  const handleRequestVideo = async (
    date: string,
    vehicleId: string,
    location: string,
    heading: number
  ): Promise<boolean> => {
    try {
      await requestVideoForDate(date, vehicleId, location, heading);

      return true;
    } catch (e) {
      alert(t("error_requesting_video"));
      return false;
    }
  };

  if (loading || error || !data) {
    return (
      <MapView
        center={{ lat: -5, lng: 0 }}
        height="100%"
        loading={loading}
        error={error}
      />
    );
  }

  return (
    <JourneyMapView
      journey={data}
      vehicle={vehicle}
      loading={loading}
      error={error}
      onRequestVideo={handleRequestVideo}
      currentUserId={ctxUser?.id}
    />
  );
};
