import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Pending media tooltip
     * This is opened on hover, so we disable pointer events
     * to prevent an infinite hover/unhover loop
     */
    popover: {
      pointerEvents: "none",
    },
    popoverPaper: {
      maxWidth: "20rem",
      padding: theme.spacing(2),
    },
  })
);
