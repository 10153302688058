import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { speedColors } from "../../constants";

import { CarMarkerProps, PingMarkerProps } from "./interfaces";

const colorsByStatus: Record<string, "success" | "error"> = {
  ONLINE: "success",
  OFFLINE: "error",
};

export const useStyles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      position: "relative",
      width: "100%",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(0, 0, 0, 0.5)",
      color: "#fff",
      fontWeight: "bold",
      zIndex: 1,
    },
  })
);

export const useCarMarkerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "absolute",
    },
    transformDegrees: {
      transform: (props: Partial<CarMarkerProps>) =>
        `translate(-50%, -50%) rotateZ(${props.heading}deg)`,
    },
    statusIcon: {
      fill: (props: Partial<CarMarkerProps>) => {
        return props.status
          ? theme.palette[colorsByStatus[props.status]].dark
          : "black";
      },
      transform: "scale(0.5)",
    },
  })
);

export const useRegistrationLabelStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      position: "absolute",
    },
    regIcon: {
      transform: "scale(2.5) translate(-8px, -22px)",
    },
    reg: {
      fontFamily: theme.typography.fontFamily,
    },
  })
);

export const usePingMarkerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      position: "absolute",
      transform: (props: Partial<PingMarkerProps>) =>
        !props.isEvent
          ? `translate(-50%, -50%) rotateZ(${props.heading}deg)`
          : "translate(-50%, -50%) ",
      zIndex: (props: Partial<PingMarkerProps>) => (props.isEvent ? 2 : 1),
    },
    icon: {
      fill: (props: Partial<PingMarkerProps>) =>
        props.isEvent ? "purple" : getSpeedColor(props.speed),
      color: "white",
    },

    parent: {
      transform: "translate(-50%,-50%) scale(1.5)",
      display: "flex",
      position: "absolute",
    },
    group: {
      display: "flex",
      justifyContent: "space-between",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
      padding: "18px",
      height: "36px",
      alignItems: "center",
      zIndex: 1500,
      color: theme.palette.primary.main,
    },
  })
);

export const useStartJourneyMarkerStyles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      zIndex: 1 /* Raise start marker above others when zoomed out */,
      width: 50,
      transform: "translate(-50%, -25%)",

      "& img": {
        maxWidth: "100%",
      },
    },
  })
);

export const useEndJourneyMarkerStyles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      width: 25,
      transform: "translate(-50%, -100%)",

      "& img": {
        maxWidth: "100%",
      },
    },
  })
);

export const usePopoverStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
  })
);

const getSpeedColor = (speed = 0) => {
  if (speed >= 0 && speed < 10) return speedColors.speed1;
  if (speed >= 10 && speed < 20) return speedColors.speed2;
  if (speed >= 20 && speed < 30) return speedColors.speed3;
  if (speed >= 30 && speed < 40) return speedColors.speed4;
  if (speed >= 40 && speed < 50) return speedColors.speed5;
  if (speed >= 50 && speed < 60) return speedColors.speed6;
  if (speed >= 60 && speed < 70) return speedColors.speed7;
  if (speed >= 70 && speed < 80) return speedColors.speed8;
  if (speed >= 80) return speedColors.speed9;
  return speedColors.speed1;
};
