import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Fill viewport to allow scrolling within panel
     */
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",

      [theme.breakpoints.up("md")]: {
        height: "100%",
      },
    },
    /**
     * Ensure panel contents stretches to fill height, so if there are only a
     * few vehicles the list and map don't shrink
     */
    content: {
      [theme.breakpoints.up("md")]: {
        height: "100%",
      },
    },
    /**
     * Restrict grid columns (list and map) to height of panel, so that we can
     * scroll within the list itself
     */
    column: {
      height: "100%",
    },
  })
);
