import {
  withStyles,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { visuallyHidden } from "../../theme";
import { EventsTableProps } from "./interfaces";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Container styles
     */
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.up("lg")]: {
        /**
         * Don't allow the container to
         * stretch out its grid parent
         */
        minWidth: 0,
      },
    },
    /**
     * Filter bar styles
     */
    toolbar: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1),

        "& > * + *": {
          marginTop: theme.spacing(1),
        },
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 2),

        flexWrap: "wrap",

        "& > * + *": {
          marginLeft: theme.spacing(2),
        },
      },
    },
    filterWrap: {
      width: 200,
    },
    vehicleFilterWrap: {
      /**
       * Have vehicle filter take up remaining space in toolbar. This allows more
       * items to be shown as selected at once
       */
      flex: 1,
    },
    /**
     * Table styles
     */
    tableContainer: {
      position: "relative",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      /**
       * Ensure there is always enough room to show an error message
       */
      minHeight: 300,
    },
    table: {
      width: "100%",
      "& tbody": {
        transition: "opacity 0.3s",
        opacity: (props: Partial<EventsTableProps> = {}) =>
          props.loading ? 0.5 : 1,
      },
    },
    /**
     * Accessibly hide loading message text
     */
    tableLoading: {
      ...visuallyHidden,
    },
    /**
     * Selected row styles
     */
    selectedRow: {
      backgroundColor: theme.palette.primary.light,
    },
    /**
     * Message shown when no results found
     */
    emptyMessage: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    /**
     * Error styles
     */
    error: {
      display: "flex",
      ">*": {
        flexShrink: 1,
      },
    },
  })
);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(1),
    },
    body: {
      fontSize: 14,
      whiteSpace: "nowrap",
      padding: theme.spacing(1),
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      cursor: "pointer",

      /**
       * Zebra striping
       */
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
