import React from "react";
import { HeaderProps } from "./interfaces";
import { HeaderView } from "./views";

const Intercom = window.Intercom;

export const Header: React.FC<HeaderProps> = ({ collapsed }) => {
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);

  React.useEffect(() => {
    if (Intercom) {
      //Protection during non authed rendering (e.g. Storybook)
      Intercom("onUnreadCountChange", (unreadCount) => {
        setUnreadMessageCount(unreadCount);
      });
    }
  }, []);
  return (
    <HeaderView collapsed={collapsed} unreadIntercomMsgs={unreadMessageCount} />
  );
};
