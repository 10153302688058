import React from "react";
import Grid from "@material-ui/core/Grid";
import CarIcon from "@material-ui/icons/DriveEta";

import { VehicleList } from "../../components/VehicleList";
import { VehicleMap } from "../../components/VehicleMap";
import { Vehicle } from "../../interfaces";
import { Panel, PanelHeader } from "../../components/Panel";
import { LocalizedText } from "../../components/LocalizedText";
import { Layout } from "../Layout";

import { useStyles } from "./styles";
import { useIsMobileSize } from "../../hooks/is-mobile-size";

/**
 * The landing page for the vehicles journey. Shows a paginated. searchable
 * list of all vehicles in the user's account and their last known positions on
 * a map
 */
export const VehiclesPage: React.FC = () => {
  const classes = useStyles();
  const [vehicles, setVehicles] = React.useState<Vehicle[]>([]);
  const { isMobileSize } = useIsMobileSize();

  return (
    <Layout>
      <div className={classes.container}>
        <Panel
          header={
            <PanelHeader
              icon={<CarIcon />}
              title={<LocalizedText text="vehicles" />}
            />
          }
        >
          <Grid className={classes.content} container spacing={2}>
            <Grid
              className={classes.column}
              item
              xs={12}
              sm={isMobileSize ? 12 : 4}
            >
              <VehicleList onVehiclesChange={setVehicles} />
            </Grid>
            {!isMobileSize && (
              <Grid className={classes.column} item xs={12} sm={8}>
                <VehicleMap vehicles={vehicles} />
              </Grid>
            )}
          </Grid>
        </Panel>
      </div>
    </Layout>
  );
};
