import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Popover from "@material-ui/core/Popover";
import { useTheme } from "@material-ui/core/styles";
import { RangePicker, DatePicker } from "react-trip-date";
import { DateTime } from "luxon";

import { Button, LocalizedButton } from "../Button";
import { useTranslation } from "../../hooks/i18n";

import { useStyles } from "./styles";
import { datePagerFormat } from "./constants";
import { DatePagerProps } from "./interfaces";

export const DatePager: React.FC<DatePagerProps> = ({
  onChange,
  dates,
  allowRange = true,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [
    datePickerAnchor,
    setDatePickerAnchor,
  ] = React.useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  /**
   * Determine various states from dates provided
   */
  const startDate = DateTime.fromISO(dates[0]);
  const endDate = dates[1] ? DateTime.fromISO(dates[1]) : undefined;
  const isToday = startDate.hasSame(DateTime.now().startOf("day"), "day");
  const multipleDates = endDate && endDate.diff(startDate).as("days") > 1;
  const now = DateTime.now();
  const disableNext = startDate.hasSame(now, "day");

  /**
   * Construct text to display for "current date". Can be:
   * - Single day e.g. "Thu 13 Jul"
   * - Range e.g. "Thu 13 Jul - Wed 02 Jun"
   * - "Today"
   */
  let dateDisplay = "";
  if (endDate && multipleDates) {
    dateDisplay = `${startDate.toFormat(datePagerFormat)} – ${endDate.toFormat(
      datePagerFormat
    )}`;
  } else {
    dateDisplay = isToday ? t("today") : startDate.toFormat(datePagerFormat);
  }

  /**
   * We keep dates in UTC here as a convenience for any consumers wishing to
   * pass them to a server
   */
  const handleDateChange = (value: string | string[]) => {
    if (value === "prev") {
      return onChange([
        DateTime.fromISO(dates[0]).startOf("day").minus({ days: 1 }).toISO(),
      ]);
    }

    if (value === "next") {
      return onChange([
        DateTime.fromISO(dates[0]).startOf("day").plus({ days: 1 }).toISO(),
      ]);
    }

    if (value === "today") {
      return onChange([DateTime.now().startOf("day").toISO()]);
    }

    onChange([
      value[0],
      ...[value[1] && DateTime.fromISO(value[1]).endOf("day").toISO()],
    ]);
  };

  const handleOpenDatePicker = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDatePickerAnchor(e.currentTarget);
  };
  const handleCloseDatePicker = () => {
    setDatePickerAnchor(null);
  };
  const handleDatePickerChange = (dates: { from: string; to?: string }) => {
    onChange([dates.from, dates.to || dates.from]);
  };
  const showDatePicker = Boolean(datePickerAnchor);

  return (
    <Box display="flex" alignItems="center">
      <LocalizedButton
        className={classes.button}
        onClick={() => handleDateChange("prev")}
        aria-label="previous_day"
        variant="text"
        color="primary"
        size="small"
      >
        <ChevronLeftIcon />
      </LocalizedButton>

      <Button
        onClick={handleOpenDatePicker}
        variant="text"
        color="primary"
        aria-label={t("current_date", { date: dateDisplay })}
      >
        <CalendarTodayIcon className={classes.icon} fontSize="small" />
        <Typography component="span">{dateDisplay}</Typography>
      </Button>
      <Popover
        open={showDatePicker}
        anchorEl={datePickerAnchor}
        onClose={handleCloseDatePicker}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {allowRange ? (
          <RangePicker
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- RangePicker props aren't typed correctly
            // @ts-ignore
            theme={theme.palette}
            onChange={(dates) => handleDatePickerChange(dates)}
          />
        ) : (
          <DatePicker
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- RangePicker props aren't typed correctly
            // @ts-ignore
            theme={theme.palette}
            numberOfSelectableDays={1}
            onChange={(dates) => handleDatePickerChange({ from: dates[0] })}
          />
        )}
      </Popover>

      <LocalizedButton
        className={classes.button}
        onClick={() => handleDateChange("next")}
        aria-label="next_day"
        variant="text"
        color="primary"
        size="small"
        disabled={disableNext}
      >
        <ChevronRightIcon />
      </LocalizedButton>

      <Button
        className={classes.button}
        onClick={() => handleDateChange("today")}
        size="small"
        color="primary"
        disabled={isToday}
      >
        Today
      </Button>
    </Box>
  );
};
