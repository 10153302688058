import { Vehicle } from "../../interfaces";
import {
  convertUtcTimestamp,
  isTimestampOlderThanDays,
  isTimestampYoungerThanDays,
} from "../../utils";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import { Tooltip } from "@material-ui/core";

export const LastUpdateBadge: React.FC<{ vehicle: Vehicle }> = ({
  vehicle,
}) => {
  const vehicleLastConnectionTime = convertUtcTimestamp(
    vehicle?.status?.lastConnection
  );

  const determineIconColor = (time: string) => {
    if (isTimestampOlderThanDays(time, 7)) {
      return "red";
    } else if (isTimestampOlderThanDays(time, 3)) {
      return "orange";
    } else {
      return "none";
    }
  };

  const tooltipTitle = vehicleLastConnectionTime
    ? `Last Update: ${vehicleLastConnectionTime}`
    : "We've not got an update from the device yet";

  const iconColor = vehicleLastConnectionTime
    ? determineIconColor(vehicleLastConnectionTime)
    : "red";

  const showIcon = vehicleLastConnectionTime
    ? !isTimestampYoungerThanDays(vehicleLastConnectionTime, 3)
    : true;

  return showIcon ? (
    <Tooltip title={tooltipTitle}>
      <RssFeedIcon style={{ color: iconColor }} />
    </Tooltip>
  ) : (
    <></>
  );
};
