import { DrivingEventMediaItem } from "../../interfaces";

/**
 * Transforms an array of media items into an array of
 * arrays of media items, grouped by channel number
 */
export function collateMedia(
  media: DrivingEventMediaItem[]
): DrivingEventMediaItem[][] {
  // Determine all available channels
  const channels = Array.from(new Set(media.map(({ channel }) => channel)));

  // Group media by channel
  return channels.map((channel) => {
    return media.filter((item) => item.channel === channel);
  });
}
