export enum Status {
  "UNAVAILABLE",
  "FAILED",
  "REQUESTABLE",
  "PENDING",
  "AVAILABLE",
}

export interface VideoStatusIconProps {
  status: Status;
}
