import { DateTime } from "luxon";

/**
 * Replaces the time of a datetime object with a new time
 */
export const replaceTime = (date: string, time: string): string => {
  const [d] = date.split(" ");

  return `${d} ${time}`;
};

export const dateToFormDate = (date: string): string => {
  return DateTime.fromFormat(date, "yyyy-MM-dd hh:mm:ss").toFormat(
    "yyyy-MM-dd'T'HH:mm:ss"
  );
};

export const formDateToDate = (date: string): string => {
  const parsedDate = DateTime.fromISO(date);

  return parsedDate.toFormat("yyyy-MM-dd HH:mm:ss");
};
