import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { visuallyHidden } from "../../theme";
import { SidebarSubMenuProps } from "./interfaces";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Root element
     */
    container: {
      /**
       * Small viewport styles - component becomes a fixed header bar
       */
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 99,
      flexShrink: 0,
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.primary.main,
      height: (props: SidebarSubMenuProps) =>
        props.menuOpen ? "100vh" : "auto",

      /**
       * On larger viewports, the component is designed to work as a sidebar
       * which can be collapsed to save space
       */
      [theme.breakpoints.up("lg")]: {
        position: "static",
        width: (props: SidebarSubMenuProps) =>
          props.collapsed ? "5.5rem" : "18rem",
        paddingBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
      },

      /**
       * Ensure links around avatar don't have default browser styles
       * (underline, visited color, etc)
       */
      "& a": {
        textDecoration: "none",
      },
    },
    /**
     * Header contains logo, user image and burger menu
     */
    header: {
      position: "sticky",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      /**
       * Maintain same height when collapsed and avatar is not rendered
       */
      // minHeight: 80,
      // padding: (props: SidebarSubMenuProps) =>
      //   props.collapsed ? theme.spacing(2, 1) : theme.spacing(2),
      backgroundColor: "#fff",

      /**
       * Rounded corners on large viewports only (component is full width on small viewports)
       */
      [theme.breakpoints.up("lg")]: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },

      //Hide on desktop as product selector will takeover the navigation at the top
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },

      /**
       * Set logo size
       */
      "& img": {
        maxWidth: "100%",
        width: 72,
      },
    },
    /**
     * Burger menu
     */
    mobileMenuButton: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    /**
     * Hide menu list when collapsed
     */
    hideMobileMenu: {
      [theme.breakpoints.down("md")]: {
        ...visuallyHidden,
      },
    },
    /**
     * Menu list container
     */
    list: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "stretch",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    /**
     * Menu item dividers
     */
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    /**
     * Menu items
     */
    listItem: {
      justifyContent: (props: SidebarSubMenuProps) =>
        props.collapsed ? "center" : "flex-start",
      padding: (props: SidebarSubMenuProps) =>
        props.collapsed ? theme.spacing(1) : theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.contrastText,

      /**
       * Fade icon in on hover
       */
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        "& $listIcon": {
          opacity: 1,
        },
      },
    },
    listItemSelected: {
      backgroundColor: "rgba(255, 255, 255, 0.1) !important",
      color: "#fff",

      /**
       * Fade icon in for selected items
       */
      "& $listIcon": {
        opacity: 1,
      },
    },
    /**
     * Text labels for menu items
     */
    listItemLabel: {
      fontWeight: theme.typography.fontWeightBold,
    },
    /**
     * Menu item icons
     */
    listIconContainer: {
      minWidth: (props: SidebarSubMenuProps) =>
        props.collapsed ? "unset" : 56,
      /**
       * Ensure menu items without text labels take up same space as those with labels
       */
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    listIcon: {
      color: theme.palette.primary.contrastText,
      opacity: 0.75,
      transition: "opacity 0.3s",
    },
    avatarListItem: {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    impersonatingText: {
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        textAlign: "left",
      },
    },
  })
);
