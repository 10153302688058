import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { visuallyHidden } from "../../theme";

/**
 * Styles for <SimpleInput />
 */
export const useSimpleInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      ...visuallyHidden,
    },
    input: {
      padding: theme.spacing(0.5, 1),
      backgroundColor: "#fff",
      borderRadius: theme.shape.borderRadius,
    },
  })
);

/**
 * Styles for <Select />
 */
export const useSelectStyles = makeStyles(() =>
  createStyles({
    /**
     * Allow the preview of selected values to wrap - this prevents the select
     * from outgrowing its container (max-width: 100% seems to have no effect)
     */
    preview: {
      whiteSpace: "initial",
    },
  })
);

/**
 * Styles for <FieldWrapper />
 */
export const useFieldWrapperStyles = makeStyles((theme: Theme) =>
  createStyles({ container: { marginBottom: theme.spacing(2) } })
);
