import { useContext } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";

const usePlatformApi = (): {
  platformFetch: (
    url: string,
    method: string,
    body?: string
  ) => Promise<Response | undefined>;
  checkOk: (response: Response) => Promise<Response>;
  buildHeaders: (bodyPresent: boolean) => Headers;
} => {
  const auth: IAuthContext = useContext(AuthContext);

  const platformFetch = async (url: string, method: string, body?: string) => {
    return await fetch(url, {
      method: method,
      headers: buildHeaders(body !== undefined),
      body: body,
    });
  };

  const buildHeaders = (bodyPresent: boolean) => {
    const headers = new Headers({
      Authorization: "Bearer " + auth.token,
    });

    if (bodyPresent) {
      headers.append("Content-Type", "application/json");
    }

    return headers;
  };

  const checkOk = (response: Response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  };

  return { platformFetch, checkOk, buildHeaders };
};

export default usePlatformApi;
