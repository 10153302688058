import React from "react";
import { useTranslation } from "react-i18next";

import { LocalizedTextProps } from "./interfaces";

/**
 * Renders text passed via children in the currently
 * selected language.
 *
 * Note: This will not translate anything but text - if
 * more complex usage is required (e.g. translating text
 * containing tags then either useTranslation or <Trans />
 * from 'react-i18next' should be used (or we can wrap them
 * into our own components/functions)
 */
export const LocalizedText: React.FC<LocalizedTextProps> = ({ text }) => {
  const { t } = useTranslation();
  return t(text);
};
