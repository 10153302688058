import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { useTranslation } from "../../hooks/i18n";
import { Button } from "../Button";

import { PaginationProps } from "./interfaces";
import { useStyles } from "./styles";

export const Pagination: React.FC<PaginationProps> = ({
  onNext,
  onPrev,
  showNext,
  showPrev,
  loading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      {showPrev && (
        <Button
          onClick={onPrev}
          disabled={loading}
          variant="text"
          startIcon={<ChevronLeftIcon />}
        >
          {t("previous_n_events", { count: 25 })}
        </Button>
      )}

      {showNext && (
        <Button
          onClick={onNext}
          disabled={loading}
          variant="text"
          endIcon={<ChevronRightIcon />}
        >
          {t("next_n_events", { count: 25 })}
        </Button>
      )}
    </div>
  );
};
