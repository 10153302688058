import { User } from "../../interfaces";
import { Item } from "../ItemList";

/**
 * Transforms datas from an API response to a shape usable by <ItemList />
 */
export function dataToItems(data: User[]): Item[] {
  return data
    ? data.map(({ id, firstName, lastName }) => ({
        id,
        label: `${firstName} ${lastName}`,
      }))
    : [];
}
