import React from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { DateTime } from "luxon";

import { LocalizedButton, LocalizedIconButton } from "../Button";
import { DatePager } from "../DatePager";
import { LocalizedFilter, LocalizedAutocompleteFilter } from "../Filter";

import { types, severities } from "../../constants";
import { useStyles } from "./styles";
import { FilterBarProps } from "./interfaces";
import { useTranslation } from "../../hooks/i18n";

/**
 * Toolbar view
 * Contains filters for the events table
 *
 * This component is entirely "controlled", meaning that
 * it doesn't hold state about the current filters - its
 * parent is responsible for passing these back in on
 * each occurrence of onFilter.
 *
 * This makes it easy to load the component with an initial
 * set of filters (e.g. when deeplinking)
 *
 * @see https://reactjs.org/docs/forms.html#controlled-components
 */
export const FilterBar: React.FC<FilterBarProps> = ({
  vehicles,
  onFilter,
  filters,
}) => {
  const classes = useStyles();
  const [showMobileFilters, setShowMobileFilters] = React.useState(false);
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (filter: string, values: string[]) => {
    const newFilters = {
      ...filters,
      [filter]: values,
    };
    onFilter(newFilters);
  };

  const handleDateChange = (value: string[]) => {
    const startDate = value[0];
    let endDate = value[1];
    if (!endDate) {
      endDate = DateTime.fromISO(startDate).plus({ days: 1 }).toISO();
    }
    if (startDate === endDate) {
      endDate = DateTime.fromISO(startDate).plus({ days: 1 }).toISO();
    }

    handleChange("date", [startDate, endDate]);
  };

  return (
    <div>
      {isMobile && (
        <LocalizedButton
          color="primary"
          variant="text"
          onClick={() => setShowMobileFilters(true)}
          startIcon={<FilterListIcon />}
        >
          show_filters
        </LocalizedButton>
      )}
      {!isMobile || showMobileFilters ? (
        <div className={isMobile ? classes.popup : classes.toolbar}>
          {isMobile && (
            <LocalizedIconButton
              aria-label="close"
              className={classes.popupClose}
              onClick={() => setShowMobileFilters(false)}
            >
              <CloseIcon />
            </LocalizedIconButton>
          )}
          <Box>
            <DatePager onChange={handleDateChange} dates={filters.date} />
          </Box>
          <Box className={classes.filterWrap}>
            <LocalizedFilter
              id="event-type"
              label={t("type")}
              options={types}
              onChange={(values) => {
                handleChange("type", values);
              }}
              value={filters.type}
            />
          </Box>
          <Box className={classes.filterWrap}>
            <LocalizedFilter
              id="event-severity"
              label={t("severity")}
              options={severities}
              onChange={(values) => {
                handleChange("severity", values);
              }}
              value={filters.severity}
            />
          </Box>
          <Box className={classes.vehicleFilterWrap}>
            <LocalizedAutocompleteFilter
              id="event-vehicles"
              label={t("vehicles")}
              options={vehicles}
              onChange={(values) => {
                handleChange("vehicle", values);
              }}
              value={filters.vehicle}
            />
          </Box>

          {isMobile && (
            <LocalizedButton
              onClick={() => setShowMobileFilters(false)}
              fullWidth
            >
              apply_filters
            </LocalizedButton>
          )}
        </div>
      ) : null}
    </div>
  );
};
