import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Container styles
     */
    container: {
      display: "flex",
      padding: theme.spacing(1),

      [theme.breakpoints.down("sm")]: {
        "& > *": {
          flex: 1,
        },
      },

      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-end",
      },
    },
  })
);
