import React from "react";
import GoogleMapReact from "google-map-react";
import Popover from "@material-ui/core/Popover";

import { googleMapsKey } from "../../constants";
import { CloseButton } from "../Panel";
import { LocalizedText } from "../LocalizedText";

import {
  CarMarkerProps,
  PingMarkerProps,
  MapViewProps,
  MarkerWithPopoverProps,
  Coord,
  RegistrationLabelProps,
} from "./interfaces";
import {
  useStyles,
  useCarMarkerStyles,
  usePingMarkerStyles,
  usePopoverStyles,
  useStartJourneyMarkerStyles,
  useEndJourneyMarkerStyles,
  useRegistrationLabelStyles,
} from "./styles";
import OfflineCarIcon from "./assets/car-red.png";
import OnlineCarIcon from "./assets/car-green.png";
import NoStatusCarIcon from "./assets/car-black.png";
import startJourneyImage from "./assets/start-journey-marker.png";
import endJourneyImage from "./assets/end-journey-marker.png";
import { DateTime } from "luxon";
import Box from "@material-ui/core/Box";
import { VideoStatusIcon } from "../VideoStatusIcon";
import { Status } from "../VideoStatusIcon/interfaces";

/**
 * A Label SVG to display a cars registration on a map
 */
export const RegistrationLabel: React.FC<RegistrationLabelProps> = ({
  registration,
}) => {
  const classes = useRegistrationLabelStyles();

  return (
    <svg viewBox="73.912 112.239 190.574 50.451" className={classes.regIcon}>
      <rect
        stroke="rgb(0, 0, 0)"
        fill="rgb(255, 255, 255)"
        strokeWidth="2px"
        x="73.912"
        y="112.239"
        width="190.574"
        height="50.451"
        rx="25.2"
        ry="25.2"
      />
      <text
        className={classes.reg}
        transform="matrix(2.031559, 0, 0, 1.637251, -70.255142, -84.715584)"
        fontSize="15.7px"
        textAnchor="middle"
        x="117.872"
        y="141.786"
      >
        {registration?.toUpperCase() || "UNKNOWN"}
      </text>
    </svg>
  );
};

/**
 * A car-shaped map marker. If children are passed, they are displayed in a
 * popover
 */
export const CarMarker: React.FC<CarMarkerProps> = ({
  heading,
  children,
  status,
  registration,
  onClick,
}) => {
  const classes = useCarMarkerStyles({ status, heading });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classes.container}
      onClick={handleClick}
      style={{ cursor: onClick ? "pointer" : undefined }}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <div className={classes.transformDegrees}>
        {status === "ONLINE" && (
          <img
            src={OnlineCarIcon}
            className={classes.statusIcon}
            alt="Online Vehicle"
          />
        )}
        {status === "OFFLINE" && (
          <img
            src={OfflineCarIcon}
            className={classes.statusIcon}
            alt="Offline Vehicle"
          />
        )}
        {!status && (
          <img
            src={NoStatusCarIcon}
            className={classes.statusIcon}
            alt="Vehicle"
          />
        )}
      </div>
      {!!registration && <RegistrationLabel registration={registration} />}
    </div>
  );
};

export const PingMarker: React.FC<PingMarkerProps> = ({
  heading,
  speed,
  time,
  children,
  event,
  status,
  isEvent,
  icon,
}) => {
  const classes = usePingMarkerStyles({ heading, speed, event, isEvent });
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const showTooltipPopover = Boolean(anchorEl);

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const formattedTime = DateTime.fromISO(time).toLocaleString(
    DateTime.TIME_WITH_SECONDS
  );

  const type = isEvent ? "event" : "journey";

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseLeave}
    >
      <MarkerWithPopover
        type={type}
        markerContent={
          <div style={{ position: "relative" }}>
            <div
              data-testid={"markermarker"}
              className={classes.container}
              style={{ cursor: children ? "pointer" : undefined }}
            >
              {React.cloneElement(icon, { className: classes.icon })}
            </div>
            <Box bottom={0} position="absolute">
              {status && <VideoStatusIcon status={Status[status]} />}
            </Box>
          </div>
        }
        popoverContent={children}
      />
      {anchorEl && (
        <Popover
          id={"marker__tooltip"}
          open={showTooltipPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{
            transform: "translateY(-40px)",
            pointerEvents: "none",
          }}
        >
          <Box padding={1}>{formattedTime}</Box>
        </Popover>
      )}
    </div>
  );
};

export const StartJourneyMarker: React.FC<Coord> = ({ children }) => {
  const classes = useStartJourneyMarkerStyles();

  return (
    <MarkerWithPopover
      type="journey"
      markerContent={
        <div
          className={classes.container}
          style={{ cursor: children ? "pointer" : undefined }}
        >
          <img alt="" src={startJourneyImage} />
        </div>
      }
      popoverContent={children}
    />
  );
};

export const EndJourneyMarker: React.FC<Coord> = ({ children }) => {
  const classes = useEndJourneyMarkerStyles();

  return (
    <MarkerWithPopover
      type="journey"
      markerContent={
        <div
          className={classes.container}
          style={{ cursor: children ? "pointer" : undefined }}
        >
          <img alt="" src={endJourneyImage} />
        </div>
      }
      popoverContent={children}
    />
  );
};

/**
 * Renders a Google Map using google-map-react,
 * preconfigured with our Maps API key
 */
export const MapView: React.FC<MapViewProps> = ({
  children,
  center,
  zoom = 11,
  height = 320,
  heading = 0,
  streetView = false,
  onGoogleApiLoaded,
  loading,
  error,
}) => {
  const classes = useStyles();
  const ref = React.useRef<HTMLDivElement | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Google don't provide a type for the top-level maps object - it's a namespace instead
  const apiIsLoaded = async (props: any) => {
    const { maps } = props;

    const sv: google.maps.StreetViewService = new maps.StreetViewService();
    const panorama = new maps.StreetViewPanorama(ref.current);
    sv.getPanorama(
      {
        location: center,
        radius: 50,
        source: google.maps.StreetViewSource.OUTDOOR,
      },
      processSVData
    );

    function processSVData(data: google.maps.StreetViewPanoramaData | null) {
      if (!data) return;
      panorama.setPano(data.location?.pano);
      panorama.setPov({
        heading,
        pitch: 0,
      });
      panorama.setVisible(true);
    }
  };

  return (
    <div className={classes.container} style={{ height }} ref={ref}>
      {(loading || error) && (
        <div className={classes.overlay}>
          {loading && <LocalizedText text="loading" />}
          {error && <LocalizedText text="an_error_occurred" />}
        </div>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsKey || "" }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={{
          streetViewControl: true,
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: ["roadmap", "hybrid"],
          },
        }}
        onGoogleApiLoaded={streetView ? apiIsLoaded : onGoogleApiLoaded}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};

export const MarkerWithPopover: React.FC<MarkerWithPopoverProps> = ({
  markerContent,
  popoverContent,
  type,
}) => {
  const classes = usePopoverStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "car-marker__popover" : undefined;

  const markerId = `map__${type}__marker`;
  return (
    <>
      <div
        aria-owns={open ? "car-marker__popover" : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        data-testid={markerId}
      >
        {markerContent}
      </div>
      {popoverContent && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{
            transform: "translateY(-40px)",
          }}
        >
          <div className={classes.container}>
            <div
              style={{ position: "absolute", right: 16, top: 16, zIndex: 1 }}
            >
              <CloseButton onClick={handlePopoverClose} />
            </div>
            {popoverContent}
          </div>
        </Popover>
      )}
    </>
  );
};
