import { RequestHandler, rest } from "msw";
import { DateTime } from "luxon";

import { MediaStatuses } from "../components/EventDetail/constants";
import { apiUrl } from "../constants";
import {
  DrivingEvent,
  Vehicle,
  Account,
  User,
  Camera,
  JourneySummary,
  Journey,
} from "../interfaces";

interface MockData {
  drivingEvents: DrivingEvent[];
  vehicles: Vehicle[];
  /**
   * Our mock collection of journey summaries are keyed by vehicle to allow us
   * to look them up quickly
   */
  journeySummaries: Record<string, JourneySummary[]>;
  journeys: Journey[];
  accounts: Account[];
  users: User[];
  cameras: Camera[];
}

interface PagedApiListResponse<Resource> {
  items: Resource[];
  count: number;
  paginationToken?: string;
}

/**
 * Helper to wrap data in paging structure.
 *
 * Allows setting a custom pagination token to allow writing custom MSW
 * resolvers which handle paging
 *
 * To have the response omit the pagination token entirely (to simulate that
 * there is no next page), pass a `null` custom token
 */
export function wrapResponseInPaging<Resource>(
  response: Resource[],
  customToken?: string | null
): PagedApiListResponse<Resource> {
  return {
    items: response,
    count: response.length,
    ...(customToken !== null && { paginationToken: customToken || "abc" }),
  };
}

/**
 * Creates handlers for MSW using the provided data
 */
export function makeHandlers(data: MockData, delay = 0): RequestHandler[] {
  /**
   * Helper to create a basic set of crud handlers for a resource. Use this
   * unless a resource has special requirements.
   *
   *
   * @example
   * [
   *   // Your other handlers...
   *   ...makeCrudHandlers<House>('house', 'houses')
   * ]
   *
   * If the GET URL for the resource doesn't conform to the same pattern as
   * its other verbs (perhaps because it is scoped by another resource), then
   * you can specify that too:
   *
   * @example
   * [
   *   // Your other handlers...
   *   ...makeCrudHandlers<House>('house', 'houses', 'streets/:id/houses)
   * ]
   */
  function makeCrudHandlers<
    Resource extends DrivingEvent | Vehicle | Account | User | Camera
  >(
    resource: string,
    resourceKey: keyof MockData,
    resourcePlural: string = resourceKey,
    { paged, omitGet }: { paged?: boolean; omitGet?: boolean } = {}
  ) {
    return [
      /**
       * Get all items endpoint
       */
      ...(omitGet
        ? []
        : [
            rest.get(`${apiUrl}/${resourcePlural}`, (_, res, ctx) => {
              return res(
                ctx.json(
                  paged
                    ? wrapResponseInPaging<Resource>(
                        data[resourceKey] as Resource[]
                      )
                    : data[resourceKey]
                )
              );
            }),
          ]),

      /**
       * Get single item endpoint
       */
      rest.get(`${apiUrl}/${resource}/:id`, (req, res, ctx) => {
        const { id } = req.params;

        // Find matching item in data
        const response = (data[resourceKey] as Resource[]).find(
          (item) => id === item.id
        );

        // Send 404 if no match
        const statusCode = response ? 200 : 404;

        return res(
          ctx.delay(delay),
          ctx.status(statusCode),
          ctx.json(response || null)
        );
      }),

      /**
       * Create item endpoint
       */
      rest.post(`${apiUrl}/${resource}`, (req, res, ctx) => {
        return res(ctx.status(204), ctx.body(JSON.stringify({})));
      }),

      /**
       * Update item endpoint
       */
      rest.put(`${apiUrl}/${resource}/:id`, (req, res, ctx) => {
        const { id } = req.params;

        // Find matching item in data
        const item = (data[resourceKey] as Resource[]).find(
          (item) => id === item.id
        );

        // Send 404 if no match
        const statusCode = item ? 200 : 404;
        return res(ctx.status(statusCode));
      }),

      /**
       * Delete item endpoint
       */
      rest.delete(`${apiUrl}/${resource}/:id`, (req, res, ctx) => {
        const { id } = req.params;

        // Find matching item in data
        const item = (data[resourceKey as keyof MockData] as Resource[]).find(
          (item) => id === item.id
        );

        // Send 404 if no match
        const statusCode = item ? 204 : 404;
        return res(ctx.status(statusCode));
      }),
    ];
  }

  /**
   * Shared resolver to get all vehicles; used by admin and non-admin endpoints below
   */
  // eslint-disable-next-line
  // @ts-ignore
  function getAllVehiclesResolver(req, res, ctx) {
    // Get filters
    const { searchParams } = req.url;
    const search = searchParams.get("registration");

    // Apply filters
    let response: Vehicle[] = data.vehicles;
    if (search)
      response = response.filter(({ registration }) =>
        registration.toLowerCase().includes(search.toLowerCase())
      );

    // Apply page size
    const limit = searchParams.get("limit") || "25";
    if (limit) response = response.slice(0, parseInt(limit));

    // Wrap response in paging structure
    const pagedResponse = wrapResponseInPaging(response);

    return res(ctx.delay(delay), ctx.json(pagedResponse));
  }

  const eventsResolvers = [
    /**
     * Events endpoint
     * @see https://app.swaggerhub.com/apis/MCCALCO/RAM-Live/1.0.0-oas3#/events/getEvents
     */
    rest.get(`${apiUrl}/events`, (req, res, ctx) => {
      // Get filters
      const { searchParams } = req.url;
      const typeFilter = searchParams.getAll("type");
      const severityFilter = searchParams.getAll("severity");
      const vehicleFilter = searchParams.getAll("vehicle");
      const dateStart = searchParams.get("start");
      const dateEnd = searchParams.get("end");
      const sort = searchParams.get("sort");

      // Apply filters
      let response: DrivingEvent[] = data.drivingEvents;
      // Event details filters
      if (typeFilter.length)
        response = response.filter(({ type }) => typeFilter.includes(type));
      if (severityFilter.length)
        response = response.filter(({ severity }) =>
          severityFilter.includes(severity)
        );
      if (vehicleFilter.length)
        response = response.filter(({ vehicle }) =>
          vehicleFilter.includes(vehicle.id)
        );
      // Date range filters
      if (dateStart)
        response = response.filter(({ date }) => date >= dateStart);
      if (dateEnd) response = response.filter(({ date }) => date <= dateEnd);

      // Apply sort
      // Set sort direction
      const sortIndicator = sort === "asc" ? 1 : -1;
      // Sort response itself
      if (sort)
        response = response.sort((a, b) =>
          b.date > a.date ? sortIndicator : sortIndicator * -1
        );

      // Wrap response in paging structure
      const pagedResponse = wrapResponseInPaging(response);

      return res(ctx.delay(delay), ctx.json(pagedResponse));
    }),

    /**
     * Individual event endpoint
     * @see https://app.swaggerhub.com/apis/MCCALCO/RAM-Live/1.0.0-oas3#/events/getEventById
     */
    rest.get(`${apiUrl}/event/:vehicleId/:eventId`, (req, res, ctx) => {
      const { eventId } = req.params;

      // Find matching event in data
      const response = data.drivingEvents.find(({ id }) => id === eventId);

      // Send 404 if no match
      const statusCode = response ? 200 : 404;

      return res(
        ctx.delay(delay),
        ctx.status(statusCode),
        ctx.json(response || null)
      );
    }),

    /**
     * Request event video endpoint
     * @see https://app.swaggerhub.com/apis/MCCALCO/RAM-Live/1.0.0-oas3#/media/requestEventVideo
     */
    rest.get(`${apiUrl}/event/:vehicleId/:eventId/video`, (_, res, ctx) => {
      return res(ctx.json({ status: MediaStatuses.pending }));
    }),

    /**
     * Update account alert settings endpoint
     */
    rest.put(`${apiUrl}/account/:accountId/alerts`, (_, res, ctx) => {
      return res(ctx.delay(delay), ctx.status(204));
    }),

    /**
     * Request event image endpoint
     * @see https://app.swaggerhub.com/apis/MCCALCO/RAM-Live/1.0.0-oas3#/media/requestEventImage
     */
    rest.get(`${apiUrl}/event/:vehicleId/:eventId/image`, (_, res, ctx) => {
      return res(ctx.json({ status: MediaStatuses.pending }));
    }),

    /**
     * Request arbitrary video
     */
    rest.post(`${apiUrl}/event/vod`, (_, res, ctx) => {
      return res(ctx.status(201), ctx.json({ message: "Created" }));
    }),
  ];

  const vehiclesResolvers = [
    /**
     * Vehicles endpoint for use on non-admin screens (i.e. where user has access to only one account/fleet)
     * @see https://app.swaggerhub.com/apis/MCCALCO/RAM-Live/1.0.0-oas3#/vehicle/getVehicles (doesn't exist yet, but it will)
     */
    rest.get(`${apiUrl}/vehicles`, getAllVehiclesResolver),
  ];

  const journeysResolvers = [
    /**
     * Journey summaries endpoint for a given vehicle
     */
    rest.get(`${apiUrl}/journeys/:vehicleId`, (req, res, ctx) => {
      const { vehicleId } = req.params;
      const date = req.url.searchParams.get("date");
      if (!date)
        return res(
          ctx.delay(delay),
          ctx.status(400),
          ctx.text("Please supply a date")
        );

      // Find matching journey summary in data
      const dateObj = DateTime.fromISO(date);
      const response = data.journeySummaries[vehicleId as string].filter(
        (journeySummary) =>
          dateObj.hasSame(DateTime.fromISO(journeySummary.start.time), "day") ||
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          dateObj.hasSame(DateTime.fromISO(journeySummary.end.time!), "day")
      );

      // Send 404 if no match
      const statusCode = response ? 200 : 404;

      return res(
        ctx.delay(delay),
        ctx.status(statusCode),
        ctx.json(response || null)
      );
    }),

    /**
     * Journey detail endpoint
     */
    rest.get(`${apiUrl}/journey/:vehicleId/:journeyId`, (req, res, ctx) => {
      const { vehicleId, journeyId } = req.params;

      // Ensure that there is a matching journey for this vehicle
      const journeyIdMatchesVehicle = Boolean(
        data.journeySummaries[vehicleId as string]
      );

      if (!journeyIdMatchesVehicle)
        return res(ctx.delay(delay), ctx.status(404), ctx.json(null));

      const response = data.journeys.find(({ id }) => id === journeyId);

      // Send 404 if no match
      const statusCode = response ? 200 : 404;

      return res(
        ctx.delay(delay),
        ctx.status(statusCode),
        ctx.json(response || null)
      );
    }),
  ];

  const supportResolvers = [
    /**
     * Support request endpoint
     */
    rest.post(`${apiUrl}/support`, (req, res, ctx) => {
      /**
       * Ensure required fields are present
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const body = req.body as Record<string, any>;
      const subject = body["subject"];
      const message = body["message"];
      console.log({ body, subject, message });
      if (!subject || !message) return res(ctx.delay(delay), ctx.status(400));

      console.log("Returning 201!");
      return res(
        ctx.delay(delay),
        ctx.status(201),
        ctx.body(JSON.stringify({ message: "Created" }))
      );
    }),
  ];

  const adminResolvers = [
    /**
     * Generic CRUD endpoints for various resources
     */
    ...makeCrudHandlers<Account>("account", "accounts"),
    ...makeCrudHandlers<User>("user", "users", "account/:id/users"),
    ...makeCrudHandlers<Camera>("camera", "cameras", "account/:id/cameras"),
    ...makeCrudHandlers<Vehicle>(
      "vehicle",
      "vehicles",
      "account/:id/vehicles",
      { paged: true, omitGet: true }
    ),
    // Override generic crud GET endpoint for vehicles to allow searching
    rest.get(`${apiUrl}/account/:id/vehicles`, getAllVehiclesResolver),
  ];

  const hyperlapseResolvers = [
    rest.post(`${apiUrl}/event/hyperlapse`, (req, res, ctx) => {
      return res(
        ctx.delay(delay),
        ctx.status(201),
        ctx.body(JSON.stringify({ message: "Created" }))
      );
    }),
  ];

  return [
    ...eventsResolvers,
    ...vehiclesResolvers,
    ...journeysResolvers,
    ...supportResolvers,
    ...adminResolvers,
    ...hyperlapseResolvers,
  ];
}
