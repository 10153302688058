import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    cards: {
      [theme.breakpoints.up("md")]: {
        /**
         * Lay cards out in two-col grid
         */
        display: "grid",
        gap: theme.spacing(1),
        gridTemplateColumns: "1fr 1fr ",
        /**
         * Make all cards same height
         * @see https://stackoverflow.com/a/44490047
         */
        gridAutoRows: "1fr",
      },
    },
    card: {
      display: "flex",
      flexDirection: "column",
    },
    cardHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
    },
    cardContent: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    cardActions: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    cardButton: {
      marginTop: theme.spacing(1),
    },
  })
);
