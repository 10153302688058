import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    /**
     * Success/error messages displayed above form
     */
    alert: {
      marginBottom: theme.spacing(2),
    },
  })
);
