import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

import { SkeletonFormProps } from "./interfaces";
import { useStyles } from "./styles";

/**
 * A skeleton outline of a form with fields and a submit button
 *
 * Useful for showing a loading state for an edit form while values load
 */
export const SkeletonForm: React.FC<SkeletonFormProps> = ({ numFields }) => {
  const classes = useStyles();

  return (
    <>
      {Array(numFields)
        .fill("")
        .map((_, i) => (
          <Skeleton
            key={i}
            data-testid="skeleton-form__field"
            className={classes.field}
            height={53}
            variant="rect"
          />
        ))}

      <Skeleton
        data-testid="skeleton-form__button"
        height={30}
        variant="rect"
      />
    </>
  );
};
