import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import { FilterOption, FilterProps } from "./interfaces";
import { useStyles } from "./styles";
/**
 * A filter is usually used alongside a list of data, providing values to
 * narrow down the results displayed.
 *
 * This is a controlled component - you must supply onChange and value for it
 * to work.
 *
 * The filter will fill its container - to controls its width, set the width
 * of its parent, either expliitly or as a flex or grid item.
 */
export const Filter: React.FC<FilterProps> = ({
  id,
  label,
  options,
  onChange,
  value,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    onChange(value);
  };

  return (
    <>
      <FormControl
        size="small"
        variant="outlined"
        className={classes.container}
      >
        <InputLabel id={`filter-${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`filter-${id}-label`}
          label={label}
          id={id}
          placeholder="type"
          multiple
          value={value}
          onChange={handleChange}
          renderValue={(selected) =>
            (selected as string[])
              .map(
                (value) =>
                  options.find((option) => option.value === value)?.label
              )
              .join(", ")
          }
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={value.includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

/**
 * An autocomplete filter allows narrowing down the list of options
 * before selecting by typing in the filter box
 */
export const AutocompleteFilter: React.FC<FilterProps> = ({
  id,
  label,
  options,
  onChange,
  value,
}) => {
  const classes = useStyles();
  const handleChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types -- This is the type specified by Material - nothing we can do about it
    _: React.ChangeEvent<{}>,
    values: FilterOption[]
  ) => {
    onChange(values.map(({ value }) => value));
  };

  /**
   * Determine number of chips to show based on size
   */
  const ref = React.useRef<HTMLElement>();
  const [numChips, setNumChips] = React.useState(2);
  React.useLayoutEffect(() => {
    const width = ref.current?.clientWidth;
    if (!width) return;
    // Conservative guess at width of each chip
    const chipWidth = 250;
    setNumChips(Math.floor(width / chipWidth));
  }, []);

  return (
    <Autocomplete
      ref={ref}
      className={classes.container}
      id={id}
      options={options}
      onChange={handleChange}
      value={options.filter((option) => value.includes(option.value))}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, currentValue) => {
        return currentValue.value === option.value;
      }}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;

        return (
          <>
            {value.slice(0, numChips).map((option, index) => (
              <Chip
                variant="outlined"
                label={option.label}
                size="small"
                {...getTagProps({ index })}
              />
            ))}

            <Typography className={classes.moreTag} variant="body2">
              {numTags > numChips && ` +${numTags - numChips}`}
            </Typography>
          </>
        );
      }}
      size="small"
      disableCloseOnSelect
      disablePortal
      multiple
    />
  );
};
