import React from "react";
import { v4 as uuid } from "uuid";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { CameraFormView } from "./views";
import {
  CameraFormData,
  EditCameraFormProps,
  CameraFormParams,
  CreateCameraFormProps,
} from "./interfaces";
import { useCamerasApi } from "../../hooks/use-cameras";
import { Camera } from "../../interfaces";

/**
 * A container to handle creating or editing a camera
 *
 * It decides whether to show a create or edit form based on whether
 * there is an camera ID present in the URL.
 */
export const CameraForm: React.FC = () => {
  const { accountId, cameraId } = useParams<CameraFormParams>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Typing this is a bit arduous
  const location = useLocation<any>();

  return cameraId ? (
    <EditCameraForm
      id={cameraId}
      accountId={accountId}
      created={location.state?.created}
    />
  ) : (
    <CreateCameraForm accountId={accountId} />
  );
};

const CreateCameraForm: React.FC<CreateCameraFormProps> = ({ accountId }) => {
  const history = useHistory();
  const { createCamera } = useCamerasApi();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (data: CameraFormData) => {
    setIsSubmitting(true);
    try {
      const id = uuid();
      await createCamera({ ...data, id, accountId });
      setIsSubmitting(false);
      setSuccess(true);
      setError(false);
      /**
       * Navigate to edit screen for the newly-created camera
       */
      history.push(`/accounts/summary/${accountId}/cameras/edit/${id}`, {
        created: true,
      });
    } catch (e) {
      setSuccess(false);
      setError(true);
      setIsSubmitting(false);
    }
  };

  return (
    <CameraFormView
      isSubmitting={isSubmitting}
      accountId={accountId}
      error={error}
      success={success}
      onSubmit={handleSubmit}
    />
  );
};

const EditCameraForm: React.FC<EditCameraFormProps> = ({
  id,
  accountId,
  created,
}) => {
  const [data, setData] = React.useState<Camera | undefined>(undefined);
  const {
    loading,
    error,
    isUpdating,
    updateSuccess,
    getCamera,
    updateCamera,
  } = useCamerasApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getCamera(id);
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [id]);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (values: CameraFormData) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- by the time we get to submitting, there will be camera data
    updateCamera(id, { ...values, id, accountId: data!.accountId });
  };

  return (
    <CameraFormView
      camera={data}
      loading={loading}
      error={error}
      accountId={accountId}
      onSubmit={handleSubmit}
      isSubmitting={isUpdating}
      success={created || updateSuccess}
      isEdit
    />
  );
};
