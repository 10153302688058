import { Box } from "@material-ui/core";
import CarIcon from "@material-ui/icons/DriveEta";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { CameraBadge } from "../../components/CameraBadge/views";
import { LocalizedText } from "../../components/LocalizedText";
import { CloseButton, Panel, PanelHeader } from "../../components/Panel";
import { Layout } from "../Layout";
import { useStyles } from "./styles";
import { Livestream } from "../../components/Livestream";
import { Vehicle } from "../../interfaces";
import { useVehicleApi } from "../../hooks/use-vehicle";

interface LivestreamPageParams {
  vehicleId: string;
}

/**
 * Page to allow a user to view a livestream of a specific vehicle
 */
export const LivestreamPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { vehicleId } = useParams<LivestreamPageParams>();
  const { getVehicle } = useVehicleApi();
  const [vehicleData, setVehicleData] = React.useState<Vehicle | undefined>(
    undefined
  );

  React.useEffect(() => {
    async function fetchData() {
      const data = await getVehicle(vehicleId);
      setVehicleData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId]);

  const handleBack = () => {
    history.push(`/vehicles/${vehicleId}`);
  };

  const title = vehicleData ? (
    <Box display="flex" alignItems="center">
      {vehicleData.registration}
      <Box display="inline-block" marginLeft={2}>
        <CameraBadge vehicle={vehicleData} useTooltip />
      </Box>
    </Box>
  ) : (
    <LocalizedText text="loading" />
  );

  return (
    <Layout menuCollapsed>
      <div className={classes.container}>
        <Panel
          header={
            <PanelHeader icon={<CarIcon />} title={title}>
              <CloseButton onClick={handleBack} />
            </PanelHeader>
          }
        >
          {vehicleData && <Livestream camera={vehicleData.cameras[0]} />}
        </Panel>
      </div>
    </Layout>
  );
};
