import { useContext, useState, useCallback, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import { initIntercomWithUser, shutdown } from "../services/intercom";
import usePlatformApi from "./use-platform-api";
import { useUsersApi } from "./use-users";
import { useAccountsApi } from "./use-accounts";

export interface User {
  id: string;
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  salesforceContactId?: string;
  roles?: string[];
}

export interface SsoUser {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  intercomHash: string;
  salesforceId: string;
  salesforceContactId: string;
  timezone: string;
  roleList: Role[];
}

interface Role {
  id: string;
  name: string;
}

export const useCurrentSsoUser = (): {
  user: User | undefined;
  ssoUser: SsoUser | undefined;
  revalidate: () => Promise<void>;
} => {
  const { ctxUser, setCtxUser } = useContext(UserContext);
  const { getUserByEmail } = useUsersApi();
  const { getAccount } = useAccountsApi();
  const { user } = usePlatformUserApi();
  const ssoUser = (user as unknown) as SsoUser;

  const revalidate = useCallback(async () => {
    if (!user) {
      shutdown();
      return;
    }
    try {
      const dbUser = await getUserByEmail(ssoUser.email);
      if (!dbUser) {
        return;
      }
      const account = await getAccount(dbUser.accountId);
      if (!account) {
        return;
      }
      const combined = combineSsoAndDbUser(ssoUser, dbUser);
      if (JSON.stringify(ctxUser) !== JSON.stringify(combined)) {
        setCtxUser(combined);
        initIntercomWithUser(combined, account);
      }
    } catch (error) {
      console.error("Failed to revalidate user:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [setCtxUser, user, ssoUser, ctxUser]);

  useEffect(() => {
    if (!user) {
      return;
    }

    revalidate();
  }, [user, revalidate]);

  return { user: ctxUser, ssoUser, revalidate };
};

const combineSsoAndDbUser = (ssoUser: SsoUser, dbUser?: User): User => {
  if (!dbUser) {
    console.error("User not found in database");
    throw new Error("User not found in database");
  }

  return {
    id: dbUser.id,
    email: dbUser.email,
    accountId: dbUser.accountId,
    firstName: dbUser.firstName,
    lastName: dbUser.lastName,
    salesforceContactId: dbUser.salesforceContactId,
    roles: ssoUser.roleList.map((role) => role.name),
  };
};

const usePlatformUserApi = (): {
  user: SsoUser | undefined;
  getUser: () => Promise<SsoUser | undefined>;
} => {
  const [user, setUser] = useState<SsoUser | undefined>(undefined);
  const { platformFetch } = usePlatformApi();

  const getUser = useCallback(async () => {
    if (user) {
      return user;
    }

    try {
      const response = await platformFetch(
        process.env.REACT_APP_PLATFORM_API_URL + "/account/v1/users/me",
        "get"
      );
      if (!response || response.status !== 200) {
        throw new Error(response ? response.status.toString() : "No response");
      }
      const result = (await response.json()) as SsoUser;
      setUser(result);

      return user;
    } catch (e) {
      return undefined;
    }
  }, [user, platformFetch]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user, getUser };
};
