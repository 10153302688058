import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
    },
    label: { marginRight: "8px" },
    select: {
      "& .MuiSelect-select": {
        paddingRight: 0,
      },
    },
    selectedIcon: {
      textAlign: "center",
      justifyContent: "flex-end",
      minWidth: 0,
      display: "flex",
    },
  })
);
