import FormControl from "@material-ui/core/FormControl";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { RegionSelectorViewProps } from "./interfaces";

import FlagGB from "./assets/flag-gb.png";
import FlagUS from "./assets/flag-us.png";
import FlagCA from "./assets/flag-ca.png";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";

export const RegionSelectorView: React.FC<RegionSelectorViewProps> = React.memo(
  ({ regions, selected, onRegionChange }) => {
    const flag = (region: string): string => {
      switch (region) {
        case "US":
          return FlagUS;
        case "CA":
          return FlagCA;
        default:
          return FlagGB;
      }
    };

    const classes = useStyles();
    return (
      <FormControl className={classes.form}>
        <Typography className={classes.label}>Select Region:</Typography>
        <Select
          data-testid="region_selector"
          className={classes.select}
          value={selected}
          onChange={onRegionChange}
          label="Region"
          IconComponent={() => null}
          renderValue={(value) => (
            <ListItemIcon className={classes.selectedIcon}>
              <img
                height={16}
                width={16}
                src={flag(value as string)}
                alt="flag"
              />
            </ListItemIcon>
          )}
        >
          {regions.map((r) => (
            <MenuItem value={r} key={r}>
              <ListItemIcon>
                <img height={16} width={16} src={flag(r)} alt="flag" />
              </ListItemIcon>
              <ListItemText primary={r} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);
