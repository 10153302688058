import React from "react";

import { Account, AccountAlert, User } from "../../interfaces";
import { AlertSettingsView } from "./views";
import { EditAlertSettingsProps } from "./interfaces";
import { useCurrentSsoUser } from "../../hooks/auth";
import { useAccountsApi } from "../../hooks/use-accounts";
import { useUsersApi } from "../../hooks/use-users";

/**
 * A container to handle updating alert settings for an account
 *
 * It uses the account for which the current user is signed in
 */
export const AlertSettingsForm: React.FC = () => {
  const { user } = useCurrentSsoUser();

  if (!user) return null;

  return <EditAlertSettings accountId={user.accountId} />;
};

const EditAlertSettings: React.FC<EditAlertSettingsProps> = ({ accountId }) => {
  const [accountData, setAccountData] = React.useState<Account | undefined>(
    undefined
  );
  const [userData, setUserData] = React.useState<User[] | undefined>(undefined);
  const {
    loading,
    error,
    updating,
    updateSuccess,
    getAccount,
    updateAccountAlerts,
  } = useAccountsApi();
  const { loading: usersLoading, getUsers } = useUsersApi();

  React.useEffect(() => {
    async function fetchData() {
      const accountData = await getAccount(accountId);
      const userData = await getUsers(accountId);
      setAccountData(accountData);
      setUserData(userData);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [accountId]);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (accountId: string, values: AccountAlert) => {
    updateAccountAlerts(accountId, values);
  };

  return (
    <AlertSettingsView
      values={accountData?.alerts}
      users={userData}
      accountId={accountData?.id}
      loading={loading || usersLoading}
      error={error}
      onSubmit={handleSubmit}
      isSubmitting={updating}
      success={updateSuccess}
    />
  );
};
