import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import SettingsIcon from "@material-ui/icons/Settings";

import { AccountAlert } from "../../interfaces";
import { Panel, PanelHeader } from "../Panel";
import { LocalizedSelect, FieldWrapper, LocalizedCheckbox } from "../Input";
import { LocalizedButton } from "../Button";
import { LocalizedText } from "../LocalizedText";
import { SkeletonForm } from "../SkeletonForm";

import { AlertSettingsViewProps } from "./interfaces";
import { useStyles } from "./styles";
import { useTranslation } from "../../hooks/i18n";

/**
 * Validation rules for the form
 */
const schema = yup.object({
  impacts: yup.boolean(),
  drivingStyle: yup.array().of(yup.string()),
  recipients: yup.array().of(yup.string()),
});

/**
 * A component to allow updating alert settings for an account
 */
export const AlertSettingsView: React.FC<AlertSettingsViewProps> = ({
  values,
  users,
  loading,
  error,
  success,
  isSubmitting,
  onSubmit,
  accountId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showSkeleton, setShowSkeleton] = React.useState(loading);

  const formik = useFormik<AccountAlert>({
    initialValues: {
      impacts: false,
      drivingStyle: [],
      recipients: [],
    },
    validateOnBlur: true,
    validationSchema: schema,
    onSubmit: (values) => {
      if (!accountId) return;
      onSubmit(accountId, values);
    },
  });

  /**
   * Once data has loaded, fill the form
   */
  React.useEffect(() => {
    if (!values) return;

    formik.setValues(values);

    setShowSkeleton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formik changes each render so cannot be declared as a dependency
  }, [values]);

  const header = (
    <PanelHeader
      icon={<SettingsIcon />}
      title={<LocalizedText text="alert_settings" />}
    ></PanelHeader>
  );

  return (
    <Panel header={header}>
      {error && (
        <Alert className={classes.alert} severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}
      {success && (
        <Alert className={classes.alert} severity="info">
          <LocalizedText text="changes_saved" />
        </Alert>
      )}

      {showSkeleton ? (
        <SkeletonForm numFields={3} />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper>
            <LocalizedCheckbox
              label="enable_impact_alerts"
              name="impacts"
              onChange={formik.handleChange}
              checked={formik.values.impacts}
              helperText="enable_impact_alerts_help"
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedSelect
              fullWidth
              label="driving_style"
              id="drivingStyle"
              name="drivingStyle"
              value={formik.values.drivingStyle}
              onChange={formik.handleChange}
              error={
                formik.touched.drivingStyle &&
                Boolean(formik.errors.drivingStyle)
              }
              helperText={
                formik.touched.drivingStyle
                  ? (formik.errors.drivingStyle as string)
                  : "driving_style_help"
              }
              options={[
                { label: t("moderate"), value: "MODERATE" },
                { label: t("severe"), value: "SEVERE" },
              ]}
              multiple
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedSelect
              fullWidth
              label="recipients"
              id="recipients"
              name="recipients"
              value={formik.values.recipients}
              onChange={formik.handleChange}
              error={
                formik.touched.recipients && Boolean(formik.errors.recipients)
              }
              helperText={
                formik.touched.recipients
                  ? (formik.errors.recipients as string)
                  : "recipients_help"
              }
              options={
                users?.map((user) => ({
                  label: `${user.firstName} ${user.lastName} (${user.email})`,
                  value: user.email,
                })) || []
              }
              multiple
            />
          </FieldWrapper>

          <LocalizedButton fullWidth disabled={isSubmitting} type="submit">
            {isSubmitting ? "saving" : "save"}
          </LocalizedButton>
        </form>
      )}
    </Panel>
  );
};
