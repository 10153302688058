import { DateTime } from "luxon";
import React from "react";
import { Item } from "../ItemList";
import {
  JourneyContainerProps,
  JourneyContainerWithSelectedProps,
  JourneyListProps,
} from "./interfaces";
import { dataToItems } from "./transformers";
import { JourneyListView } from "./views";
import { useJourneysApi } from "../../hooks/use-journeys";
import { Journey, JourneySummary } from "../../interfaces";

export const JourneyList: React.FC<JourneyListProps> = ({
  vehicle,
  onJourneyClick,
  selectedJourney,
}) => {
  const handleJourneyClick = (item: Item) => {
    onJourneyClick(item.id);
  };

  return (
    <>
      {selectedJourney ? (
        <JourneyListWithSelected
          vehicle={vehicle}
          onJourneyClick={handleJourneyClick}
          selectedJourney={selectedJourney}
        />
      ) : (
        <JourneyListNoSelected
          onJourneyClick={handleJourneyClick}
          vehicle={vehicle}
        />
      )}
    </>
  );
};

const JourneyListNoSelected: React.FC<JourneyContainerProps> = ({
  vehicle,
  onJourneyClick,
}) => {
  const vehicleId = vehicle.id;
  const [date, setDate] = React.useState(DateTime.now().startOf("day").toISO());

  const [data, setData] = React.useState<JourneySummary[] | undefined>(
    undefined
  );
  const { loading, error, getJourneySummaries } = useJourneysApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getJourneySummaries({ vehicleId, date });
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId, date]);

  const dataAsItems = React.useMemo(
    () => (data ? dataToItems(data, undefined) : []),
    [data]
  );

  const handleDateChange = (dates: string[]) => {
    setDate(dates[0]);
  };

  /**
   * Select first item in list if available
   */
  React.useEffect(() => {
    if (dataAsItems.length) {
      onJourneyClick(dataAsItems[0]);
    }
  }, [dataAsItems, onJourneyClick]);
  return (
    <JourneyListView
      vehicle={vehicle}
      data={dataAsItems}
      loading={loading}
      error={error}
      onJourneyClick={onJourneyClick}
      dates={[date]}
      onDatesChange={handleDateChange}
    />
  );
};

/**
 * Seperate component due to useJourney hook unable to be used conditionally due to React limitations.
 *
 * It has to be done this way as the deep-linked journey has to be loaded to change the calendar date.
 */
const JourneyListWithSelected: React.FC<JourneyContainerWithSelectedProps> = ({
  vehicle,
  onJourneyClick,
  selectedJourney,
}) => {
  const vehicleId = vehicle.id;
  const [journeyData, setJourneyData] = React.useState<Journey | undefined>(
    undefined
  );
  const [journeySummarydata, setJourneySummaryData] = React.useState<
    JourneySummary[] | undefined
  >(undefined);
  const { loading, error, getJourney, getJourneySummaries } = useJourneysApi();

  React.useEffect(() => {
    async function fetchData() {
      const journeyData = await getJourney({
        vehicleId,
        journeyId: selectedJourney,
      });
      setJourneyData(journeyData);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId, selectedJourney]);

  const [date, setDate] = React.useState(
    journeyData?.tracks[0]
      ? journeyData?.tracks[0].time
      : DateTime.now().startOf("day").toISO()
  );

  React.useEffect(() => {
    async function fetchData() {
      const journeySummarydata = await getJourneySummaries({ vehicleId, date });
      setJourneySummaryData(journeySummarydata);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId, date]);

  const dataAsItems = journeySummarydata
    ? dataToItems(journeySummarydata, selectedJourney)
    : [];

  React.useEffect(() => {
    const journeyDate = journeyData?.tracks[0]?.time;
    if (journeyDate !== undefined) {
      setDate(journeyDate);
    }
  }, [journeyData?.tracks]);

  const handleDateChange = (dates: string[]) => {
    setDate(dates[0]);
  };

  return (
    <JourneyListView
      vehicle={vehicle}
      data={dataAsItems}
      loading={loading}
      error={error}
      onJourneyClick={onJourneyClick}
      dates={[date]}
      onDatesChange={handleDateChange}
    />
  );
};
