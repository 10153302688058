import React from "react";

import { CarMarker, MapView } from "../Map";

import { VehicleMapProps } from "./interfaces";
import { useHistory } from "react-router-dom";

export const VehicleMap: React.FC<VehicleMapProps> = ({ vehicles }) => {
  const [map, setMap] = React.useState<google.maps.Map>();
  const history = useHistory();

  function handleGoogleApiLoaded({ map }: { map: google.maps.Map }) {
    setMap(map);
  }

  React.useEffect(() => {
    if (!map || vehicles.length === 0) return;

    /**
     * Create an area encompassing all markers
     */
    const latlngbounds = new google.maps.LatLngBounds();
    for (const vehicle of vehicles) {
      if (vehicle.status) {
        const [lat, lng] = vehicle.status.lastKnownLocation.split(",");
        if (lat && lng && lat !== "null" && lng !== "null") {
          latlngbounds.extend({ lat: parseFloat(lat), lng: parseFloat(lng) });
        }
      }
    }

    /**
     * If only one marker (or multiple markers in same location), zoom out a
     * little, otherwise the map is too zoomed to be useful
     */
    if (latlngbounds.getNorthEast().equals(latlngbounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        latlngbounds.getNorthEast().lat() + 0.01,
        latlngbounds.getNorthEast().lng() + 0.01
      );
      const extendPoint2 = new google.maps.LatLng(
        latlngbounds.getNorthEast().lat() - 0.01,
        latlngbounds.getNorthEast().lng() - 0.01
      );
      latlngbounds.extend(extendPoint1);
      latlngbounds.extend(extendPoint2);
    }

    // Zoom map to fit created area
    map.fitBounds(latlngbounds);
  }, [vehicles, map]);

  /**
   * Navigate to specific item when selected
   */
  const handleItemClick = (vehicleId: string) => {
    history.push(`/vehicles/${vehicleId}`);
  };

  return (
    <MapView
      center={{ lat: -5, lng: 0 }}
      height="100%"
      onGoogleApiLoaded={handleGoogleApiLoaded}
    >
      {vehicles.map((vehicle) => {
        if (vehicle.status && vehicle.status.status) {
          const { status, heading, lastKnownLocation } = vehicle.status;
          const [lat, lng] = lastKnownLocation.split(",");

          if (!lat || !lng || lat === "null" || lng === "null") return null;
          return (
            <CarMarker
              key={vehicle.registration}
              lat={parseFloat(lat)}
              lng={parseFloat(lng)}
              heading={heading}
              status={status}
              registration={vehicle.registration}
              onClick={() => handleItemClick(vehicle.id)}
            />
          );
        }
        return null;
      })}
    </MapView>
  );
};
