import React from "react";
import Alert from "@material-ui/lab/Alert";
import CarIcon from "@material-ui/icons/DriveEta";
import { useHistory } from "react-router-dom";

import { LocalizedText } from "../LocalizedText";
import { LocalizedTextField } from "../Input";
import { Item, ItemList } from "../ItemList";
import { AddButton, DeleteButton, Panel, PanelHeader } from "../Panel";
import { useTranslation } from "../../hooks/i18n";
import { Pagination } from "../Pagination";
import { Vehicle } from "../../interfaces";
import {
  VehicleListAdminPanelViewProps,
  VehicleListViewProps,
} from "./interfaces";
import { useStyles, useVehicleRowStyles } from "./styles";
import { CameraBadge } from "../CameraBadge/views";
import { LastUpdateBadge } from "../LastUpdateBadge/views";

export const VehicleListAdminPanelView: React.FC<VehicleListAdminPanelViewProps> = ({
  data,
  loading,
  error,
  accountId,
  onDelete,
  deleteSuccess,
  onSearch,
  onNextPage,
  onPrevPage,
  hasNextPage,
  hasPrevPage,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [checkedItems, setCheckedItems] = React.useState<Item[]>([]);

  /**
   * Navigate to specific item when selected
   */
  const handleItemClick = (item: Item) => {
    history.push(`/accounts/summary/${accountId}/vehicles/edit/${item.id}`);
  };

  /**
   * Add or remove an item from state when checked/unchecked
   */
  const handleItemCheck = (item: Item, checked: boolean) => {
    setCheckedItems((checkedItems) => {
      const newCheckedItems = [...checkedItems];
      if (checked) {
        newCheckedItems.push(item);
      } else {
        newCheckedItems.splice(checkedItems.indexOf(item), 1);
      }
      return newCheckedItems;
    });
  };

  /**
   * Handle delete button click
   */
  const handleDelete = async () => {
    if (!window.confirm(t("confirm_delete_items"))) return;
    onDelete?.(checkedItems);
  };

  /**
   * Navigate to create vehicle when create button pressed
   */
  const handleCreate = () => {
    history.push(`/accounts/summary/${accountId}/vehicles/create`);
  };

  const header = (
    <PanelHeader icon={<CarIcon />} title={t("vehicles")}>
      {Boolean(checkedItems.length) && <DeleteButton onClick={handleDelete} />}

      <AddButton label="create_vehicle" onClick={handleCreate} />
    </PanelHeader>
  );

  return (
    <Panel header={header}>
      {deleteSuccess && (
        <Alert severity="info">
          <LocalizedText text="delete_success" />
        </Alert>
      )}

      <VehicleListView
        data={data}
        loading={loading}
        error={error}
        onItemClick={handleItemClick}
        onItemCheck={handleItemCheck}
        onSearch={onSearch}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        checkboxes
      />
    </Panel>
  );
};

/**
 * A paged, searchable vehicle list view, optionally with checkboxes
 */
export const VehicleListView: React.FC<VehicleListViewProps> = ({
  data,
  loading,
  error,
  onSearch,
  onNextPage,
  onPrevPage,
  hasNextPage,
  hasPrevPage,
  onItemCheck,
  onItemClick,
  checkboxes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = React.useState("");

  return (
    <div
      className={classes.container}
      aria-label={loading ? t("loading") : undefined}
    >
      <LocalizedTextField
        id="search"
        label="search"
        type="search"
        onChange={(e) => {
          const { value } = e.target;
          setSearchTerm(value);
          onSearch(value);
        }}
        value={searchTerm}
        placeholder="search_by_registration"
        size="small"
        fullWidth
      />

      {!loading && !error && (!data || data?.length === 0) && (
        <div className={classes.noResults}>
          <Alert severity="info">
            <LocalizedText text="no_vehicles" />
          </Alert>
        </div>
      )}

      {error && (
        <Alert severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}

      {!error && (
        <ItemList
          items={((data as unknown) as Item[]) || []}
          numItems={25}
          loading={loading}
          onItemClick={onItemClick}
          onItemCheck={onItemCheck}
          checkboxes={checkboxes}
          renderItem={(item) => (
            <VehicleRow vehicle={(item as unknown) as Vehicle} />
          )}
        />
      )}

      {!error && (
        <Pagination
          onNext={onNextPage}
          onPrev={onPrevPage}
          showNext={hasNextPage}
          showPrev={hasPrevPage}
          loading={Boolean(loading)}
        />
      )}
    </div>
  );
};

const VehicleRow = ({ vehicle }: { vehicle: Vehicle }) => {
  const classes = useVehicleRowStyles();

  return (
    <div className={classes.container} data-testid="vehicle-row">
      <span>{vehicle.registration}</span>

      <div className={classes.statuses}>
        <LastUpdateBadge vehicle={vehicle} />
        <CameraBadge vehicle={vehicle} useTooltip />
      </div>
    </div>
  );
};
