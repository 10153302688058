export enum MediaTypes {
  thumb = "THUMBNAIL",
  image = "IMAGE",
  video = "VIDEO",
}
export enum MediaStatuses {
  unavailable = "UNAVAILABLE",
  failed = "FAILED",
  requestable = "REQUESTABLE",
  pending = "PENDING",
  available = "AVAILABLE",
}

export const mediaTypesWithDetailLevel = {
  [MediaTypes.thumb]: 1,
  [MediaTypes.image]: 2,
  [MediaTypes.video]: 3,
};
