import { useCallback, useEffect, useState } from "react";
import { RamCalendarEntry } from "../interfaces";
import useLiveApi from "./use-live-api";

interface CalendarRecordingsApi {
  data: RamCalendarEntry[];
  loading: boolean;
  error: boolean;
  nextPage: () => Promise<void>;
  prevPage: () => Promise<void>;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

const fetchItems = async (
  apiGatewayFetch: (
    url: string,
    method: string,
    body?: string
  ) => Promise<Response>,
  vehicleId: string,
  limit?: number,
  paginationToken?: string
) => {
  let url = `/calendar/${vehicleId}`;
  if (limit) url += `?limit=${limit}`;
  if (paginationToken) url += `?token=${paginationToken}`;
  const response = await apiGatewayFetch(url, "get");
  if (response.status !== 200) {
    throw new Error(response.status.toString());
  }
  return await response.json();
};

export const useCalendarRecordingsApi = (
  vehicleId: string,
  limit?: number
): CalendarRecordingsApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [data, setData] = useState<RamCalendarEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | undefined>(undefined);
  const [prevTokens, setPrevTokens] = useState<string[]>([]);

  const loadPage = useCallback(
    async (paginationToken?: string) => {
      setLoading(true);
      try {
        const data = await fetchItems(
          apiGatewayFetch,
          vehicleId,
          limit,
          paginationToken
        );
        setData(data.items as RamCalendarEntry[]);
        setNextToken(data.paginationToken);
        if (paginationToken) {
          setPrevTokens((prev) => [...prev, paginationToken]);
        } else {
          setPrevTokens([]);
        }
        setLoading(false);
      } catch (e) {
        setError(e.message || "An error occurred");
        setLoading(false);
      }
    },
    [apiGatewayFetch, vehicleId, limit]
  );

  const nextPage = useCallback(async () => {
    if (nextToken) {
      await loadPage(nextToken);
    }
  }, [loadPage, nextToken]);

  const prevPage = useCallback(async () => {
    const prevToken = prevTokens[prevTokens.length - 1];
    if (prevToken) {
      await loadPage(prevToken);
      setPrevTokens((prev) => prev.slice(0, -1));
      return;
    }
    loadPage();
  }, [loadPage, prevTokens]);

  const hasNextPage = !!nextToken;
  const hasPrevPage = prevTokens.length > 0;

  useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId, limit]);

  return { data, nextPage, prevPage, hasNextPage, hasPrevPage, loading, error };
};
