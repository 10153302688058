import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ghostButton: {
      appearance: "none",
      margin: 0,
      padding: 0,
      border: "none",
      backgroundColor: "transparent",
      color: "inherit",
      fontSize: "inherit",
      cursor: "pointer",
    },
  })
);
