import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Skeleton placeholder for form fields
     */
    field: {
      marginBottom: theme.spacing(2),
    },
  })
);
