import { DateTime } from "luxon";
import { DrivingEvent, JourneyPing } from "../../interfaces";

export const eventsToJourneyPingArray = (
  events: DrivingEvent[]
): JourneyPing[] => {
  return events.map((e) => {
    return {
      location: e.location,
      speed: 0,
      heading: e.heading,
      time: e.date,
      vehicleId: e.vehicle.id,
      event: {
        id: e.id,
        media: e.media?.map((m) => {
          return {
            channel: m.channel,
            mediaType: m.mediaType,
            status: m.status,
            requestedBy: m.requestedBy,
          };
        }),
        type: e.type,
      },
      isEvent: true,
    };
  });
};

/**
 * Removes event pings without a location, and those where the event type doesn't want to be shown
 * @param pings JourneyPing[]
 * @returns Filtered JourneyPing[]
 */
export const filterEventPings = (pings: JourneyPing[]): JourneyPing[] => {
  return pings.filter(
    (e) =>
      e.location &&
      !["vod", "user", "hyperlapse"].includes(e.event.type?.toLowerCase() || "")
  );
};

export const combineTracksAndEvents = (
  tracks: JourneyPing[],
  events: JourneyPing[]
): JourneyPing[] => {
  // Extract start and end points
  const start = tracks[0];
  const end = tracks[tracks.length - 1];

  //If size > 2, extract middle parts of tracks
  let middle: JourneyPing[] = [];

  if (tracks.length > 2) {
    middle = [...tracks.slice(1, -1)];
  }
  //combine middle tracks and events
  middle = [...middle, ...events];

  //sort by time
  const sorted = middle.sort((p1, p2) => {
    return DateTime.fromISO(p1.time).diff(DateTime.fromISO(p2.time))
      .milliseconds;
  });

  //reconstruct array with start and end points with sorted middle
  return [start, ...sorted, end];
};

/**
 * Remove pings from a list evenly until it has reached the maximum amount
 *
 * Note: I believe this to be quite inefficient due to the limitations of whole integers, if you can improve this,
 * go for it!
 * @param pings
 * @returns
 */
export const reducePingsToMaxLength = (pings: JourneyPing[]): JourneyPing[] => {
  const MAX_PINGS = 540 - 2; //Should be enough to cover ~3h of pings

  let array = [...pings];

  if (array.length <= 3) {
    return array;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const start = array.shift()!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const end = array.pop()!;

  while (array.length > MAX_PINGS) {
    const toRemove = array.length - MAX_PINGS;
    const removeEvery = Math.ceil(array.length / toRemove);
    if (removeEvery <= 1) {
      break;
    }

    if (removeEvery !== array.length) {
      const filtered = array.filter((_, i) => i % removeEvery);
      array = filtered;
    } else {
      //Need to remove 1 to make limit, but cannot remove first/last element, so remove second to last
      array.splice(array.length - 2, 1);
    }
  }

  return [start, ...array, end];
};
