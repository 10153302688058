import React from "react";
import { useStyles } from "./styles";

import { ReactComponent as VideoSvg } from "./assets/video.svg";
import { VideoStatusIconProps } from "./interfaces";

export const VideoStatusIcon: React.FC<VideoStatusIconProps> = ({ status }) => {
  const classes = useStyles({ status });
  return <VideoSvg height={16} width={16} className={classes.icon} />;
};
