import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Status } from "./interfaces";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      // position: "absolute",
      // bottom: 0,
      // left: 0,
      display: ({ status }: { status: Status }) => {
        if (!status) return "none";

        switch (status) {
          case Status.AVAILABLE:
          case Status.PENDING:
            return;
          default:
            return "none";
        }
      },
      fill: ({ status }: { status: Status }) => {
        if (!status) return;

        switch (status) {
          case Status.AVAILABLE:
            return "green";
          case Status.PENDING:
            return theme.palette.primary.main;
          default:
            return;
        }
      },
    },
  })
);
