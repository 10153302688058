import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { Account, AccountUpdateBody } from "../../interfaces";
import {
  AccountFormParams,
  EditAccountFormProps,
  SubmittingAccountFormData,
} from "./interfaces";
import { formValuesToRequestBody } from "./transformers";
import { AccountFormView } from "./views";
import { useAccountsAsAdminApi } from "../../hooks/use-accounts-as-admin";
import { useAccountsApi } from "../../hooks/use-accounts";

/**
 * A container to handle creating or editing an account (fleet)
 *
 * It decides whether to show a create or edit form based on whether
 * there is an account ID present in the URL.
 */
export const AccountForm: React.FC = () => {
  const { accountId } = useParams<AccountFormParams>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Typing this is a bit arduous
  const location = useLocation<any>();

  return accountId ? (
    <EditAccountForm id={accountId} created={location.state?.created} />
  ) : (
    <CreateAccountForm />
  );
};

const CreateAccountForm: React.FC = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success] = React.useState(false);

  const { createAccount } = useAccountsAsAdminApi();

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (data: SubmittingAccountFormData) => {
    setIsSubmitting(true);
    try {
      const id = uuid();
      await createAccount({
        ...formValuesToRequestBody(data),
        id,
      } as AccountUpdateBody);
      /**
       * Navigate to summary screen for the newly-created account
       */
      history.push(`/accounts/summary/${id}`);
    } catch (e) {
      setError(true);
      setIsSubmitting(false);
    }
  };

  return (
    <AccountFormView
      isSubmitting={isSubmitting}
      error={error}
      success={success}
      onSubmit={handleSubmit}
    />
  );
};

const EditAccountForm: React.FC<EditAccountFormProps> = ({ id, created }) => {
  const [data, setData] = React.useState<Account | undefined>(undefined);
  const {
    loading,
    error,
    isUpdating,
    updateSuccess,
    updateAccount,
  } = useAccountsAsAdminApi();

  const { getAccount } = useAccountsApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getAccount(id);
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [id]);

  /**
   * Handle form submit
   *
   * Transforms the form data into the shape required by the API and posts it
   */
  const handleSubmit = async (values: SubmittingAccountFormData) => {
    updateAccount({
      ...formValuesToRequestBody(values),
      id,
    } as AccountUpdateBody);
  };

  return (
    <AccountFormView
      account={data ?? undefined}
      accountId={data?.id}
      loading={loading}
      error={error}
      onSubmit={handleSubmit}
      isSubmitting={isUpdating}
      success={created || updateSuccess}
      isEdit
    />
  );
};
