import React from "react";
import { useHistory } from "react-router-dom";
import { DrivingEvent } from "../../interfaces";
import { useTranslation } from "../../hooks/i18n";
import { EventListContext } from "./EventListContext";

import { pageSize } from "./constants";
import { EventListProps } from "./interfaces";
import { useStyles } from "./styles";
import { EventsTable } from "./views";
import { formatEventDataForDisplay } from "./transformers";
import { TablePaging } from "../TablePaging";
import { useEventsApi } from "../../hooks/use-events";

export const EventList: React.FC<EventListProps> = () => {
  const classes = useStyles();

  const history = useHistory();
  const { t } = useTranslation();

  /**
   * We get the currently selected event from context, but this is set _after_
   * that event loads, so to keep the UI snappy when switching between events,
   * we optimistically set this state locally as soon as an event row is
   * clicked
   */
  const [
    optimisticallySelectedEvent,
    setOptimisticallySelectedEvent,
  ] = React.useState<DrivingEvent>();

  /**
   * Get filters, sort and selected event from context, along with a function
   * to update the current sort when a user clicks the table's date header
   */
  const { filters, sort, setSort, selectedEvent } = React.useContext(
    EventListContext
  );
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = useEventsApi({ filters, sort });

  const localizedEventsData = eventsData?.map((event) =>
    formatEventDataForDisplay(t, event, { truncateAddress: true })
  );

  return (
    <div className={classes.container}>
      <EventsTable
        numEvents={pageSize}
        loading={!eventsError && eventsLoading}
        error={eventsError}
        events={localizedEventsData}
        sort={sort}
        onSort={setSort}
        onSelect={(event) => {
          setOptimisticallySelectedEvent(event);
          history.push(`/events/${event.vehicle.id}/${event.id}`);
        }}
        selected={optimisticallySelectedEvent || selectedEvent}
      />
      <TablePaging
        onNext={nextPage}
        onPrev={prevPage}
        showNext={hasNextPage}
        showPrev={hasPrevPage}
        loading={!eventsError && eventsLoading}
      />
    </div>
  );
};
