import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";
import { contactDetails } from "../../constants";
import { RegionSelector } from "../RegionSelector";
import { getRegion } from "../../services/region";
import Box from "@material-ui/core/Box";

/**
 * The global footer, containing contact details, links, etc
 */
export const Footer: React.FC = () => {
  const classes = useStyles();

  const [region, setRegion] = React.useState(localStorage.getItem("region"));

  const handleRegionChange = (r: string) => {
    setRegion(r);
  };

  React.useEffect(() => {
    if (!region) {
      getRegion()
        .then((res) => {
          setRegion(res);
        })
        .catch((err) => {
          setRegion("GB");
        });
    }
  }, [region]);

  React.useEffect(() => {
    if (region) {
      localStorage.setItem("region", region);
    }
  }, [region]);

  const details = contactDetails(region || "GB");
  return (
    <div className={classes.container} data-testid="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Typography variant="h6" component="h2" color="secondary">
              Contact Details
            </Typography>

            <Grid container spacing={4}>
              <Grid item lg={6}>
                <Typography variant="body1" className={classes.detailsHeading}>
                  RAM Tracking
                </Typography>
                <Typography variant="body1">
                  {details.line1}
                  <br />
                  {details.line2}
                  <br />
                  {details.city}
                  <br />
                  {details.postcode}
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography variant="body1">
                  {details.phone && (
                    <>
                      <span
                        className={classes.detailsHeading}
                        aria-label="Telephone"
                      >
                        T.
                      </span>{" "}
                      <span>{details.phone}</span>
                      <br />
                    </>
                  )}
                  {details.fax && (
                    <>
                      <span className={classes.detailsHeading} aria-label="Fax">
                        F.
                      </span>{" "}
                      <span>{details.fax}</span>
                      <br />
                    </>
                  )}
                  {details.email && (
                    <>
                      <span
                        className={classes.detailsHeading}
                        aria-label="Email"
                      >
                        E.
                      </span>{" "}
                      <span>{details.email}</span>
                      <br />
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.legal} item md={6}>
            <Typography variant="body1">
              <div
                dangerouslySetInnerHTML={{ __html: details.description || "" }}
              />
            </Typography>

            <Typography variant="body1">
              &copy; RAM Tracking {new Date().getUTCFullYear()}. All rights
              reserved.
              <br />
              View our{" "}
              <a
                href="https://www.ramtracking.com/gdpr/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Typography>
            <Box className={classes.regionContainer}>
              <RegionSelector
                selected={region || "GB"}
                onRegionChange={handleRegionChange}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
