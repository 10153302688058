import { Sort } from "../../interfaces";

/**
 * The initial sort order (by date) for events
 */
export const initialSort: Sort = "desc";
/**
 * The number of events to show on one page
 */
export const pageSize = 25;
