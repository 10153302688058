import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { LocalizedText } from "../LocalizedText";
import { CloseButton } from "../Panel";
import React from "react";
import { LocalizedButton } from "../Button";
import { UserContext } from "../../contexts/UserContext";

export interface OutOfDialogProps {
  show: boolean;
  onClose: () => void;
  salesforceId: string;
}

export const OutOfTrialDialogView: React.FC<OutOfDialogProps> = ({
  show,
  onClose,
  salesforceId,
}) => {
  const { ctxUser } = React.useContext(UserContext);

  const OutOfTrailContent =
    "You have reached the limit of your trial period. Please contact us to continue using this feature.";

  const handleUpgrade = () => {
    Intercom("trackEvent", "livecamera-watchdemo-requested_v2");

    const url =
      "https://ram2ams.azurewebsites.net/api/RAMMarketing?" +
      "code=FZDqro1VuczY2-HPWgRzRoqwDUCfh0rMvKg9jRfrCjhYAzFu01v-tw==" +
      "&sf_campaign=Camera%20Icon" +
      "&utm_medium=Watch%20Demo&utm_campaign=Upgrade&utm_source=From%20RAM%20Tracking" +
      "&destinationURL=https%3A%2F%2Fwww2.ramtracking.com%2Fram-live-thank-you" +
      "&account_id=" +
      salesforceId +
      "&user_id=" +
      ctxUser?.salesforceContactId;

    window.open(url, "_blank", "noopener");
  };

  const upgradeButton = (
    <LocalizedButton
      fullWidth
      variant="contained"
      color="secondary"
      onClick={handleUpgrade}
    >
      Upgrade Now
    </LocalizedButton>
  );

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      aria-describedby="alert-dialog-description"
      keepMounted
      open={show}
      onClose={onClose}
    >
      <DialogTitle id="simple-dialog-title" disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            <LocalizedText text="Oh no! You're out of trial" />
          </Typography>
          <CloseButton onClick={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">{OutOfTrailContent}</Typography>
      </DialogContent>
      <DialogContent dividers>
        <Typography variant="body1">{upgradeButton}</Typography>
      </DialogContent>
    </Dialog>
  );
};
