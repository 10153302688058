import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: "grid",
      gridAutoFlow: "row",
      gridRowGap: "10px",
    },
  })
);
