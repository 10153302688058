import React from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";

import { useTranslation } from "../../hooks/i18n";
import { ButtonProps } from "./interfaces";
import { Button } from "./views";

/**
 * Button with automatic translation of text. Translation will only work
 * if children is a text string. For for more complex translation, use `<Button />`
 * with `react-i18next`'s `<Trans />` component.
 */
export const LocalizedButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  const localizedProps = { ...props };
  if (localizedProps["aria-label"])
    localizedProps["aria-label"] = t(localizedProps["aria-label"] as string);

  localizedProps.children = React.Children.map(
    props.children,
    function (child) {
      return typeof child === "string" ? t(child) : child;
    }
  );

  return <Button {...localizedProps} />;
};

/**
 * IconButton with automatic translation of aria label
 */
export const LocalizedIconButton: React.FC<IconButtonProps> = (props) => {
  const { t } = useTranslation();

  const localizedProps = { ...props };
  if (localizedProps["aria-label"])
    localizedProps["aria-label"] = t(localizedProps["aria-label"] as string);

  return <IconButton {...localizedProps} />;
};
