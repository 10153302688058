import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Fill viewport to allow scrolling within panel
     */
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      height: `calc(100vh - ${theme.spacing(20)}px)`,

      [theme.breakpoints.up("md")]: {
        height: "100%",
      },
    },
    row: {
      width: "100%",
    },
  })
);

export const useSummaryTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      gap: theme.spacing(2),
      paddingTop: theme.spacing(2),

      [theme.breakpoints.up("md")]: {
        display: "grid",
        gridTemplateRows: "auto 1fr",
      },
    },
    journeyContainer: {
      display: "grid",
      gridTemplateRows: "auto 1fr",

      [theme.breakpoints.down("sm")]: {
        minHeight: "50vh",
      },
    },
  })
);

export const useJourneyTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Ensure panel contents stretches to fill height, so if there are only a
     * few vehicles the list and map don't shrink
     */
    content: {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "grid",
        gridTemplateRows: "0.5fr 0.5fr",
      },
    },

    /**
     * Restrict grid columns (list and map) to height of panel, so that we can
     * scroll within the list itself
     */
    column: {
      height: "100%",
    },
  })
);
