import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    close: {
      marginLeft: "auto",
    },
  })
);

export const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      // width: "100%",
      marginTop: "30px",
    },
    submit: {
      marginLeft: "auto",
    },
  })
);
