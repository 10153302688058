import { Camera } from "../../interfaces";
import { Item } from "../ItemList";

/**
 * Transforms datas from an API response to a shape usable by <ItemList />
 */
export function dataToItems(data: Camera[]): Item[] {
  return data
    ? data.map(({ id, providerId }) => ({
        id,
        label: providerId,
      }))
    : [];
}
