import { makeDrivingEvents } from "./events";
import { makeVehicles } from "./vehicle";
import { makeAccounts } from "./accounts";
import { makeUsers } from "./users";
import { makeCameras } from "./cameras";
import { makeJourneyFromJourneySummary, makeJourneySummaries } from "./journey";
import { DrivingEvent, JourneySummary } from "../interfaces";

/**
 * Create mock fleet of vehicles
 */
export const vehicles = makeVehicles(500, true);

/**
 * Create mock events list.
 *
 * We assign each event a random vehicle from the fleet generated above
 */

const start: DrivingEvent[] = [];
export const drivingEvents = vehicles.reduce((acc, vehicle): DrivingEvent[] => {
  return [
    ...acc,
    ...makeDrivingEvents(3, () => ({
      vehicle: vehicle,
      images: 2,
      videos: 1,
    })),
  ];
}, start);

/**
 * Create mock journeys for above vehicles
 */
export const journeySummaries = vehicles.reduce((acc, vehicle) => {
  return {
    ...acc,
    [vehicle.id]: makeJourneySummaries(15).sort((a, b) =>
      a.start.time > b.start.time ? -1 : 1
    ),
  };
}, {});

/**
 * A map of vehicle IDs against journey IDs. Used in stories that need to be
 * able to pass a journey Id and vehicle Id to a component (they need to match
 * up - the mock API resolvers will reject calls for a journey that use an
 * incorrect vehicle ID
 */
export const vehicleIdsByJourneyId: Record<string, string> = {};

export const journeys = Object.entries(journeySummaries)
  .map(([vehicleId, journeySummariesForVehicle]) => {
    return [
      ...(journeySummariesForVehicle as JourneySummary[]).map(
        (journeySummary) => {
          const journey = makeJourneyFromJourneySummary(journeySummary);
          // Store the journey ID with the vehicle ID in the hash above
          vehicleIdsByJourneyId[journey.id] = vehicleId;
          return journey;
        }
      ),
    ];
  })
  .reduce((acc, el) => [...acc, ...el], []);

/**
 * Create mock accounts (fleets)
 */
export const accounts = makeAccounts(10);

/**
 * Create mock users
 */
export const users = makeUsers(10);

/**
 * Create mock cameras
 */
export const cameras = makeCameras(10);
