import { Account } from "./interfaces";

export const convertUtcTimestamp = (
  utcTimeString?: string
): string | undefined => {
  if (!utcTimeString) {
    return undefined;
  }
  const utcDate = new Date(utcTimeString);
  const localDateString = utcDate.toDateString();
  const localTimeString = utcDate.toLocaleTimeString();

  return localDateString + ", " + localTimeString;
};

export const isTimestampOlderThanDays = (
  timestamp: string,
  daysDifference: number
): boolean => {
  const currentTimestamp: number = Date.now();
  const timestampDate: Date = new Date(timestamp);
  const difference: number = currentTimestamp - timestampDate.getTime();

  // Convert milliseconds to days
  const differnceInDays: number = difference / (1000 * 60 * 60) / 24;

  return differnceInDays >= daysDifference;
};

export const isTimestampYoungerThanDays = (
  timestamp: string,
  daysDifference: number
): boolean => {
  const currentTimestamp: number = Date.now();
  const timestampDate: Date = new Date(timestamp);
  const difference: number = currentTimestamp - timestampDate.getTime();

  // Convert milliseconds to days
  const differnceInDays: number = difference / (1000 * 60 * 60) / 24;

  return differnceInDays <= daysDifference;
};

export const blockAccessToHyperlapseAndLivestream = (
  account: Account | undefined
): boolean => {
  if (!account) {
    return false;
  }

  return account.providers.length > 1
    ? !account.enableMultiProviderFeatures
    : false;
};
