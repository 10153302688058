import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { AvatarProps } from "./interfaces";
import { sizes } from "./constants";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      backgroundColor: (props: Partial<AvatarProps>) =>
        props.variant === "light"
          ? theme.palette.primary.contrastText
          : theme.palette.primary.main,
      color: (props: Partial<AvatarProps>) =>
        props.variant === "light"
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText,
      width: ({ size = "m" }: Partial<AvatarProps>) => `${sizes[size]}px`,
      height: ({ size = "m" }: Partial<AvatarProps>) => `${sizes[size]}px`,
      fontWeight: theme.typography.fontWeightBold,
      border: ({ impersonating }: Partial<AvatarProps>) =>
        impersonating ? `3px solid ${theme.palette.secondary.main}` : "",
    },
  })
);
