import { DateTime } from "luxon";
import { TFunction } from "react-i18next";

import { DrivingEvent, Vehicle } from "../../interfaces";
import { types, severities } from "../../constants";
import { FilterOption } from "../Filter";

/**
 * @see https://stackoverflow.com/a/51885364/4887407
 */
const ukPostcodeRegex = /([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})/;

/**
 * Formats event data for display, including:
 *
 * - Converting the ISO date to a localized, friendly format
 * - Converting values in enums like type and severity to
 *   human-friendly values
 * - Truncating long addresses (to avoid stretching the event
 *   table out)
 */
export function formatEventDataForDisplay(
  t: TFunction,
  event: DrivingEvent,
  { truncateAddress }: { truncateAddress?: boolean } = {}
): DrivingEvent {
  /**
   * Truncate the address if required
   */
  let truncatedAddress = "";
  const { address } = event;
  if (address && truncateAddress) {
    /**
     * Get the postcode (assumes a UK address)
     */
    const postcode = (address && ukPostcodeRegex.exec(address)?.[0]) || "";
    const hasPostcode = Boolean(postcode);

    /**
     * Truncate the string preceding the postcode to 57 chars (so that the
     * total address length won't exceed 65 chars)
     */
    if (hasPostcode) {
      const postcodeIndex = address.indexOf(postcode);
      const addressBeforePostcode = address.substr(0, postcodeIndex);
      truncatedAddress =
        addressBeforePostcode.length > 57
          ? `${addressBeforePostcode.substr(0, 57)} … ${postcode}`
          : `${addressBeforePostcode}${postcode}`;
    } else {
      truncatedAddress =
        address.length > 65 ? `${address.substring(0, 65)}…` : address;
    }
  }

  return {
    ...event,
    date: DateTime.fromISO(event.date).toLocaleString(
      DateTime.DATETIME_SHORT_WITH_SECONDS
    ),
    type: t(types.find(({ value }) => value === event.type)?.label || ""),
    severity: t(
      severities.find(({ value }) => value === event.severity)?.label || ""
    ),
    address: truncateAddress ? truncatedAddress : address || "",
  };
}

/**
 * Create a list of options from a vehicle list
 */
export function vehiclesToOptions(vehicles: Vehicle[]): FilterOption[] {
  return vehicles.map(({ id, registration }) => ({
    label: registration,
    value: id,
  }));
}
