import { DrivingEvent, HyperlapseRequestBody, Json } from "../interfaces";
import useLiveApi from "./use-live-api";
import { v4 as uuid } from "uuid";
import { useContext, useState } from "react";
import { Filters } from "../components/EventFilters";
import { Sort } from "../components/EventList";
import { DateTime } from "luxon";
import { MediaStatuses, MediaTypes } from "../components/EventDetail/constants";
import { UserContext } from "../contexts/UserContext";

interface EventApi {
  requestHyperlapse: (request: HyperlapseRequestBody) => Promise<Json>;
  requestVod: (request: {
    vehicleId: string;
    date: string;
    location?: string;
    heading: number;
    address?: string | null;
    utc: string;
    channel: number;
    userId: string;
  }) => Promise<Json>;
  getEvent: ({
    vehicleId,
    eventId,
  }: UseEvent) => Promise<DrivingEvent | undefined>;
  requestImage: (channel: number, vehicleId: string, eventId: string) => void;
  requestVideo: (channel: number, vehicleId: string, eventId: string) => void;
  loading: boolean;
  error: boolean;
}

export const useEventApi = (): EventApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const { ctxUser: user } = useContext(UserContext);
  const [imageRequesting, setImageRequesting] = useState<number[]>([]);
  const [videoRequesting, setVideoRequesting] = useState<number[]>([]);
  const [event, setEvent] = useState<DrivingEvent | undefined>(undefined);

  const requestHyperlapse = async (
    request: HyperlapseRequestBody
  ): Promise<Json> => {
    const url = buildHyperlapseUrl();
    const body = {
      id: uuid(),
      vehicleId: request.vehicleId,
      userId: request.userId,
      startDate: request.startDate,
      endDate: request.endDate,
      channel: request.channel,
      ...(request.startHeading && { startHeading: request.startHeading }),
      ...(request.startLocation && { startLocation: request.startLocation }),
      ...(request.startAddress && { startAddress: request.startAddress }),
      ...(request.journeyId && { journeyId: request.journeyId }),
    };

    return ((await apiGatewayFetch(
      url,
      "post",
      JSON.stringify(body)
    )) as unknown) as Promise<Json>;
  };

  const requestVod = async (request: {
    vehicleId: string;
    date: string;
    location?: string;
    heading: number;
    address?: string | null;
    utc: string;
    channel: number;
    userId: string;
  }): Promise<Json> => {
    const url = buildVoDUrl();
    const body = {
      id: uuid(),
      vehicleId: request.vehicleId,
      date: request.date,
      ...(request.location && { location: request.location }),
      ...(request.address && { address: request.address }),
      heading: request.heading,
      utc: request.utc,
      channel: request.channel,
      userId: request.userId,
    };

    return ((await apiGatewayFetch(
      url,
      "post",
      JSON.stringify(body)
    )) as unknown) as Promise<Json>;
  };

  const getEvent = async ({ vehicleId, eventId }: UseEvent) => {
    setLoading(true);
    try {
      const url = buildEventUrl({ vehicleId, eventId });
      const response = await apiGatewayFetch(url, "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const event = result as DrivingEvent;
      setEvent(event);
      setLoading(false);

      return event;
    } catch (e) {
      setError(e.message || "An error occurred");
      setLoading(false);

      return undefined;
    }
  };

  const requestMedia = async (
    type: MediaTypes.image | MediaTypes.video,
    channel: number,
    vehicleId: string,
    eventId: string
  ) => {
    if (type === MediaTypes.image) {
      setImageRequesting((prev) => [...prev, channel]);
    } else {
      setVideoRequesting((prev) => [...prev, channel]);
    }

    try {
      await apiGatewayFetch(
        `/event/${vehicleId}/${eventId}/${
          type === MediaTypes.image ? "image" : "video"
        }?channel=${channel}&userId=${user?.id}`,
        "get"
      );
    } catch (e) {
      console.error(`Error while requesting ${type} media`, e);
      if (type === MediaTypes.image) {
        setImageRequesting((prev) => prev.filter((c) => c !== channel));
      } else {
        setVideoRequesting((prev) => prev.filter((c) => c !== channel));
      }
    }

    if (event) {
      setEvent({
        ...event,
        media: event.media.map((item) => {
          if (
            (item.mediaType === MediaTypes.image &&
              imageRequesting.includes(item.channel)) ||
            (item.mediaType === MediaTypes.video &&
              videoRequesting.includes(item.channel))
          ) {
            item.status = MediaStatuses.pending;
            item.requestedBy = user;
          }
          return item;
        }),
      });
    }
  };

  const requestImage = (channel: number, vehicleId: string, eventId: string) =>
    requestMedia(MediaTypes.image, channel, vehicleId, eventId);

  const requestVideo = (channel: number, vehicleId: string, eventId: string) =>
    requestMedia(MediaTypes.video, channel, vehicleId, eventId);

  return {
    requestHyperlapse,
    requestVod,
    getEvent,
    requestImage,
    requestVideo,
    loading,
    error,
  };
};

const buildHyperlapseUrl = (): string => {
  return `/event/hyperlapse`;
};

const buildVoDUrl = () => {
  return `/event/vod`;
};

const buildEventUrl = ({ vehicleId, eventId }: UseEvent): string => {
  return `/event/${vehicleId}/${eventId}`;
};

interface UseEvent {
  vehicleId: string;
  eventId: string;
}

interface UseEvents {
  filters: Filters;
  sort: Sort;
  token?: string;
}

/**
 * Builds a URL to fetch driving events, applying the provided
 * filters and sort
 */
export function buildEventsUrl({ filters, sort, token }: UseEvents): string {
  let url = `/events`;
  const filterStrings: string[] = [];
  // Event type
  if (filters.type.length) {
    filterStrings.push(filters.type.map((value) => `type=${value}`).join("&"));
  }
  // Event severity
  if (filters.severity.length) {
    filterStrings.push(
      filters.severity.map((value) => `severity=${value}`).join("&")
    );
  }
  // Vehicle(s)
  if (filters.vehicle.length) {
    filterStrings.push(
      filters.vehicle.map((value) => `vehicle=${value}`).join("&")
    );
  }
  // Date range
  const [start, end] = filters.date;
  if (start) {
    filterStrings.push(`start=${DateTime.fromISO(start).toUTC().toISO()}`);
  }
  if (end) {
    filterStrings.push(`end=${DateTime.fromISO(end).toUTC().toISO()}`);
  }
  if (filters.videoOnly) {
    filterStrings.push(`videoOnly=${filters.videoOnly}`);
  }
  // Sorting
  filterStrings.push(`sort=${sort}`);

  // Build final string
  const filterString = filterStrings.join("&");
  if (filterString) url = `${url}?${filterString}`;

  if (token) {
    url = `${url}&token=${token}`;
  }

  return url;
}
