import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButtonProps } from "@material-ui/core/IconButton";

import { LocalizedIconButton } from "../Button";

import { PanelProps, PanelHeaderProps, PanelButtonProps } from "./interfaces";
import { useStyles, useHeaderStyles } from "./styles";

/**
 * A "window" like component with a title bar and scrollable body area
 */
export const Panel: React.FC<PanelProps> = ({ header, compact, children }) => {
  const classes = useStyles({ compact });

  return (
    <>
      <Paper className={classes.container} elevation={1}>
        <div className={classes.header}>{header}</div>
        <div className={classes.body}>{children}</div>
      </Paper>
    </>
  );
};

/**
 * A helper component to construct a header with action buttons for use in <Panel />
 *
 * Pass your action buttons as children and they will be displayed on the right-hand
 * side.
 *
 * Note: to keep all your <Panel />'s headers the same height whether or not they
 * contain buttons, always specify variant="small" on the buttons. This is
 * handled for you if you use the <PanelButton /> component.
 */
export const PanelHeader: React.FC<PanelHeaderProps> = ({
  title,
  icon,
  children,
}) => {
  const classes = useHeaderStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleAndIcon}>
        {icon}{" "}
        <Typography className={classes.title} variant="body1" component="h2">
          {title}
        </Typography>
      </div>
      <div>{children}</div>
    </div>
  );
};

/**
 * Renders an icon button with the correct size and color for display in a panel header.
 *
 * The icon is passed as children
 */
export const PanelButton: React.FC<IconButtonProps> = ({
  onClick,
  children,
  ...props
}) => (
  <LocalizedIconButton
    size="small"
    color="inherit"
    onClick={onClick}
    {...props}
  >
    {children}
  </LocalizedIconButton>
);

/**
 * Convenience components providing common header buttons
 */
export const CloseButton: React.FC<PanelButtonProps> = ({
  onClick,
  label = "close",
}) => (
  <PanelButton aria-label={label} onClick={onClick}>
    <CloseIcon color="inherit" />
  </PanelButton>
);

export const AddButton: React.FC<PanelButtonProps> = ({ onClick, label }) => (
  <PanelButton aria-label={label} onClick={onClick}>
    <AddIcon color="inherit" />
  </PanelButton>
);

export const DeleteButton: React.FC<PanelButtonProps> = ({ onClick }) => (
  <PanelButton aria-label="delete" onClick={onClick}>
    <DeleteIcon color="inherit" />
  </PanelButton>
);
