import { useState } from "react";
import { CameraStatus } from "../interfaces";
import useLiveApi from "./use-live-api";

interface CameraStatusApi {
  cameraStatuses: CameraStatus[];
  loading: boolean;
  error: boolean;
  getCameraStatuses: () => Promise<CameraStatus[] | undefined>;
}

export const useCameraStatusApi = (): CameraStatusApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [cameraStatuses, setCameraStatuses] = useState<CameraStatus[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getCameraStatuses = async () => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch("/camera-statuses", "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const cameraStatuses = result as CameraStatus[];
      setCameraStatuses(cameraStatuses);
      return cameraStatuses;
    } catch (e) {
      console.error("error fetching camera statuses", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return { cameraStatuses, loading, error, getCameraStatuses };
};
