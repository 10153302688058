import { Camera } from "../../interfaces";
import { CameraFormData } from "./interfaces";

/**
 * Transforms a camera into the shape required for the form.
 *
 */
export function cameraToFormValues(camera: Camera): CameraFormData {
  const cameraWithoutProviderId = { ...camera } as Partial<Camera>;
  delete cameraWithoutProviderId.providerId;
  return cameraWithoutProviderId as CameraFormData;
}
