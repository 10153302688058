import Box from "@material-ui/core/Box";
import React from "react";
import { Button } from "../../Button";
import StopIcon from "@material-ui/icons/Stop";
import PlayIcon from "@material-ui/icons/PlayArrow";
import CancelIcon from "@material-ui/icons/Cancel";
import { LivestreamControlViewProps } from "./interfaces";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useButtonStyles } from "./styles";

export const LiveStreamControlsView: React.FC<LivestreamControlViewProps> = ({
  isLivestreaming,
  loading,
  waitingTime,
  onStart,
  onStop,
  onCancel,
}) => {
  const classes = useButtonStyles();

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 1000 / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((time / 1000) % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const startButtonDisabled = !isLivestreaming && !loading && waitingTime > 0;

  return (
    <Box display="flex" justifyContent="center">
      <Box marginRight={1}>
        {startButtonDisabled ? (
          <Button
            className={classes.root}
            disabled
            onClick={onStart}
            data-testid={"request-button"}
          >
            <PlayIcon /> <p>{formatTime(waitingTime)}</p>
          </Button>
        ) : (
          <>
            {!isLivestreaming && !loading && (
              <Button
                className={classes.root}
                onClick={onStart}
                data-testid={"request-button"}
              >
                <PlayIcon /> <p>Start Live Stream</p>
              </Button>
            )}
            {!isLivestreaming && loading && (
              <Button
                className={classes.root}
                onClick={onCancel}
                data-testid={"cancel-button"}
              >
                <CancelIcon /> Cancel Request
              </Button>
            )}
            {isLivestreaming && loading && (
              <Button
                className={classes.root}
                onClick={onStop}
                data-testid={"loading-button"}
              >
                <CircularProgress size="1rem" color="inherit" />
                <p style={{ marginLeft: "4px" }}>Loading...</p>
              </Button>
            )}
            {isLivestreaming && !loading && (
              <Button
                className={classes.root}
                onClick={onStop}
                data-testid={"stop-button"}
              >
                <StopIcon /> Stop Live Stream
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
