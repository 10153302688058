import { Box } from "@material-ui/core";
import { LocalizedSelect } from "../Input";
import React, { useCallback, useMemo } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { CalendarSearchBarProps } from "./interfaces";

const CalendarSearchBar: React.FC<CalendarSearchBarProps> = ({
  vehiclesData,
  setRequestedVehicle,
  setVehicleId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Initialize state with the first vehicle ID or an empty string.
  const [vehicle, setVehicle] = React.useState<string>(
    vehiclesData?.[0]?.id || ""
  );

  // Memoize the findVehicleFromVehicleId function for optimization.
  const findVehicleFromVehicleId = useCallback(
    (vehicleId: string) => {
      const selectedVehicle = vehiclesData?.find((v) => vehicleId === v.id);
      if (selectedVehicle) {
        setRequestedVehicle(selectedVehicle);
      }
    },
    [vehiclesData, setRequestedVehicle]
  );

  // Memoize the options generation to prevent recalculation on every render.
  const vehicleOptions = useMemo(
    () =>
      vehiclesData?.map((v) => ({
        value: v.id,
        label: v.registration,
      })) || [],
    [vehiclesData]
  );

  const handleVehicleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedVehicleId = e.target.value as string;
    setVehicle(selectedVehicleId); // Update local state
    setVehicleId(selectedVehicleId); // Lift state up to parent
    findVehicleFromVehicleId(selectedVehicleId); // Find and set requested vehicle
  };

  return (
    <div className={classes.toolbar}>
      <Box className={classes.vehicleFilterWrap}>
        <LocalizedSelect
          fullWidth
          id="vehicle"
          name="vehicle"
          value={vehicle}
          label={t("vehicle")}
          onChange={handleVehicleChange}
          options={vehicleOptions} // Using memoized options
        />
      </Box>
    </div>
  );
};

export default CalendarSearchBar;
