import React from "react";
import { useCameraStatusApi } from "../../hooks/use-camera-status";
import { CameraStatus } from "../../interfaces";
import { ReportListView } from "./views";

export const ReportList: React.FC = () => {
  const [data, setData] = React.useState<CameraStatus[] | undefined>(undefined);
  const { loading, error, getCameraStatuses } = useCameraStatusApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getCameraStatuses();
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, []);

  return (
    <>
      <ReportListView data={data} loading={loading} error={error} />
    </>
  );
};
