import { DateTime } from "luxon";
import React from "react";
import { useHistory, useParams } from "react-router";
import { EventDetail } from "../../components/EventDetail";
import { MediaList } from "../../components/MediaList";
import { initialSort } from "../../components/MediaList/constants";
import {
  getInitialFilters,
  MediaFilterBar,
} from "../../components/MediaList/MediaLibraryFilters";
import { MediaListContext } from "../../components/MediaList/MediaListContext";
import { DrivingEvent } from "../../interfaces";
import { Layout, ListDetailLayout } from "../Layout";
import { vehicleFilterPageSize } from "./constants";
import { MediaPageParams } from "./interfaces";
import { useStyles } from "./styles";
import { vehiclesToOptions } from "./transformers";
import { useEventApi } from "../../hooks/use-event";
import { useVehiclesForCurrentAccount } from "../../hooks/use-vehicles-for-current-account";

export const MediaPage: React.FC = () => {
  const history = useHistory();
  const [filters, setFilters] = React.useState(getInitialFilters());
  const [sort, setSort] = React.useState(initialSort);
  const [selectedMedia, setSelectedMedia] = React.useState<
    DrivingEvent | undefined
  >();

  const { vehicleId, eventId } = useParams<MediaPageParams>();
  const {
    data: vehiclesData,
    loading: vehiclesLoading,
  } = useVehiclesForCurrentAccount(undefined, vehicleFilterPageSize);

  const vehicleOptions =
    vehiclesLoading || !vehiclesData ? [] : vehiclesToOptions(vehiclesData);

  const classes = useStyles({ detailOpen: Boolean(eventId) });

  return (
    <MediaListContext.Provider
      value={{
        filters,
        sort,
        setFilters,
        setSort,
        selectedMedia,
        setSelectedMedia,
      }}
    >
      <Layout menuCollapsed={Boolean(eventId)}>
        <div className={classes.container}>
          <div className={classes.filterContainer}>
            <MediaFilterBar
              vehicles={vehicleOptions}
              filters={filters}
              onFilter={(values) => {
                setFilters(values);
              }}
            />
          </div>
          {eventId && vehicleId ? (
            <ListDetailLayout>
              <MediaList collapsed />
              <MediaPageAutoFilter eventId={eventId} vehicleId={vehicleId} />
              <EventDetail onBack={() => history.push("/video")} />
            </ListDetailLayout>
          ) : (
            <MediaList />
          )}
        </div>
      </Layout>
    </MediaListContext.Provider>
  );
};

export const MediaPageAutoFilter: React.FC<{
  eventId: string;
  vehicleId: string;
}> = ({ eventId, vehicleId }) => {
  const [event, setEvent] = React.useState<DrivingEvent | undefined>(undefined);
  const { getEvent } = useEventApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getEvent({ vehicleId, eventId });
      setEvent(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicleId, eventId]);
  const { filters, setFilters, setSelectedMedia } = React.useContext(
    MediaListContext
  );

  /**
   * Set event list date filter to the day of the selected event when
   * deeplinking (i.e. when first loading a page with a URL in the form
   * /events/:vehicleId/:eventId
   */
  const [
    hasSetFiltersFromDeeplinkedMedia,
    setHasSetFiltersFromDeeplinkedMedia,
  ] = React.useState(false);
  React.useEffect(() => {
    if (!event) return;
    setSelectedMedia(event);

    if (hasSetFiltersFromDeeplinkedMedia) return;

    /**
     * Check if selected event date is already within the range of the current DateSelector
     * @returns true - Event date is outside of range
     * @returns false - Event date is within range of selector
     */
    const dateChangeRequired = () => {
      if (filters.date.length <= 1) return false;

      const [start, end] = filters.date;

      const toDate = (date: string) => {
        const dateString = DateTime.fromISO(date).toFormat("yyyy-MM-dd");
        return DateTime.fromFormat(dateString, "yyyy-MM-dd");
      };
      const eventDateTime = toDate(event.date);
      const startDateTime = toDate(start);
      const endDateTime = toDate(end);

      const isBefore = eventDateTime.diff(startDateTime).milliseconds < 0;
      const isAfter = eventDateTime.diff(endDateTime).milliseconds > 0;

      return isBefore || isAfter;
    };

    /**
     * Get day of event and create date range to cover entire day
     */
    if (dateChangeRequired()) {
      const date = DateTime.fromISO(event.date);
      setFilters({
        ...filters,
        date: [
          date.startOf("day").toISO(),
          date.startOf("day").plus({ days: 1 }).toUTC().toISO(),
        ],
      });
    }

    /**
     * We only want to do this once - the current event is driven by the URL,
     * which is changed each time an event is clicked.
     *
     * By storing that we've done the date range filter, we ensure it only
     * occurs when the page is loaded
     */
    setHasSetFiltersFromDeeplinkedMedia(true);
  }, [
    event,
    setSelectedMedia,
    filters,
    setFilters,
    hasSetFiltersFromDeeplinkedMedia,
  ]);

  return null;
};
