import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusBadgeOnline: {
      width: "7rem",
      borderColor: theme.palette.success.main,
      backgroundColor: theme.palette.success.main,
      color: "#fff",
    },
    statusBadgeOffline: {
      width: "7rem",
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    statusBadgeUnknown: {
      width: "7rem",
      borderColor: theme.palette.error.main,
      backgroundColor: "#888",
      color: theme.palette.error.contrastText,
    },
  })
);
