import { seenMediaDialogKey } from "../../constants";

/**
 * Determines whether user has previous checked
 * the "don't ask again" box in the media request
 * confirmation modal
 */
export function userHasSeenMediaDialog(): boolean {
  return !!window.localStorage.getItem(seenMediaDialogKey);
}

export function setUserHasSeenMediaDialog(): void {
  return window.localStorage.setItem(seenMediaDialogKey, "true");
}
