import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Enable scrolling within by default, so if the height is restricted no
     * additional styles are needed
     */
    container: {
      overflow: "auto",
    },
    itemZebra: {
      backgroundColor: theme.palette.grey[100],
    },
    /**
     * Skeleton placeholder for an item
     */
    itemBone: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),

      /**
       * Margin after checkbox
       */
      "& > * + *": {
        marginLeft: theme.spacing(3),
      },
      /**
       * Make text bone fill space
       */
      "& > :last-child": {
        flex: 1,
      },
    },

    selected: {
      backgroundColor: theme.palette.primary.light,
      //Remove hover effect on selected items
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);
