import { Account, Vehicle } from "../../../interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../Button";
import VideocamIcon from "@material-ui/icons/Videocam";
import { useAccountsApi } from "../../../hooks/use-accounts";
import { DateTime } from "luxon";

interface LivestreamGoToButtonProps {
  vehicle: Vehicle;
}

const supportedProviders = ["RAM"];

export const LivestreamGoToButton: React.FC<LivestreamGoToButtonProps> = ({
  vehicle,
}) => {
  const history = useHistory();
  const { getAccount } = useAccountsApi();

  const [account, setAccount] = React.useState<Account | undefined>(undefined);

  React.useEffect(() => {
    async function fetchData() {
      const data = await getAccount(vehicle.accountId);
      setAccount(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicle.accountId]);

  if (vehicle.cameras.length === 0) {
    return <></>;
  }
  const provider = vehicle.cameras[0]?.provider;

  if (!supportedProviders.includes(provider)) {
    return <></>;
  }
  if (!vehicle.status?.ignitionState || vehicle.status?.status === "OFFLINE") {
    return <></>;
  }

  // Account is out of trial if the package is CORE and the trial start
  const outOfTrial =
    account?.packageType === "CORE" &&
    account.trialStartDate &&
    DateTime.fromISO(account.trialStartDate).diffNow("days").days <= -30;

  if (outOfTrial) {
    return <></>;
  }

  const handleGoToLivestreamClick = () => {
    history.push(`/vehicles/${vehicle.id}/stream`);
  };
  return (
    <Button onClick={handleGoToLivestreamClick}>
      <VideocamIcon fontSize="small" style={{ color: "inherit" }} />
      Live Stream
    </Button>
  );
};
