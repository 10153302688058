import { createContext } from "react";
import { User } from "../hooks/auth";

export type UserContent = {
  ctxUser: User | undefined;
  setCtxUser: (u: User | undefined) => void;
};
export const UserContext = createContext<UserContent>({
  ctxUser: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCtxUser: () => {},
});
