import { DateTime } from "luxon";

import { JourneySummary } from "../../interfaces";
import { Item } from "../ItemList";

export function dataToItems(
  data: JourneySummary[],
  selectedJourneyId: string | undefined
): Item[] {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return data.map((journeySummary) => ({
    ...journeySummary,
    selected: journeySummary.id === selectedJourneyId,
  }));
}

export function calculateDurationFromDates(
  date1: string,
  date2?: string
): string {
  const parsedDuration = DateTime.fromISO(
    date2 ? date2 : new Date().toISOString()
  ).diff(DateTime.fromISO(date1));
  return parsedDuration.toFormat("h'h' m'm'");
}
