import React from "react";
import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

import { fontSizes } from "./constants";
import { AvatarProps } from "./interfaces";
import { useStyles } from "./styles";

/**
 * Represents a user via their profile picture.
 * Can be used as part of a header, or in lists
 */
export const Avatar: React.FC<AvatarProps> = ({
  firstName,
  lastName,
  size = "m",
  variant = "dark",
  impersonating = false,
  ...props
}) => {
  const classes = useStyles({ size, variant, impersonating });
  return (
    <Typography
      component="span"
      {...props}
      className={classes.root}
      variant={fontSizes[size] as Variant}
      aria-label={`${firstName} ${lastName}`}
    >
      {firstName[0]}
      {lastName[0]}
    </Typography>
  );
};
