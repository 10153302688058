import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EventCameraMediaViewProps } from "./interfaces";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      /**
       * Transient highlight effect applied when
       * deeplinking with a particular channel in
       * mind (e.g. from an email alert)
       */
      boxShadow: (props: Partial<EventCameraMediaViewProps>) =>
        props.highlight
          ? `0 0 20px ${theme.palette.secondary.main}`
          : theme.shadows[1],
      transition: "box-shadow 1s",
    },
    /**
     * Image/video area
     */
    media: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      /**
       * Ensure that placeholder is prominent
       * and image-shaped when media not yet requested
       */
      minHeight: 200,
      backgroundColor: theme.palette.grey[100],
    },
    /**
     * Bars on cards for labels and buttons
     */
    toolbar: {
      flex: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      /**
       * Make height consistent when no buttons contained within
       * toolbar
       */
      minHeight: "3.325rem",
      padding: theme.spacing(1),
    },
    /**
     * Pending media badges. We adjust padding here to make
     * them the same height as the request media buttons
     */
    alert: {
      "& .MuiAlert-icon": {
        padding: 0,
      },
      "& .MuiAlert-message": {
        paddingTop: 1,
        paddingBottom: 1,
      },
    },
    /**
     * Pending media tooltip
     * This is opened on hover, so we disable pointer events
     * to prevent an infinite hover/unhover loop
     */
    popover: {
      pointerEvents: "none",
    },
    popoverPaper: {
      width: "20rem",
      padding: theme.spacing(2),
    },
  })
);
