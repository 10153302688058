import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Container
     */
    container: {
      /**
       * Prevent from stretching out any
       * grid parents
       */
      maxHeight: "100%",
      height: "100%",
      /**
       * Lay out header and body vertically
       */
      display: "flex",
      flexDirection: "column",
    },
    /**
     * Header
     */
    header: {
      padding: theme.spacing(1),
      lineHeight: 2.5,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    /**
     * Main content area
     */
    body: {
      /**
       * Fill remaining space after header
       */
      flex: 1,
      /**
       * Don't allow growing beyond height of parent
       */
      minHeight: 0,
      /**
       * Scroll body if needed
       */
      overflow: "auto",
      padding: theme.spacing(1),
    },
  })
);

/**
 * Styles for the helper component to construct a header
 * with some action buttons
 */
export const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Container
     */
    container: {
      /**
       * Align title to left and buttons to right
       */
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    /**
     * Container for title and optional icon
     */
    titleAndIcon: {
      /**
       * Line title and icon up
       */
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      /**
       * Add space after icon
       */
      "& :first-child": {
        marginRight: theme.spacing(1),
      },
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);
