import React from "react";

import { getInitialFilters } from "../EventFilters/constants";

import { initialSort } from "./constants";
import { EventListContextValues } from "./interfaces";

export const EventListContext = React.createContext<EventListContextValues>({
  filters: getInitialFilters(),
  sort: initialSort,
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- default value must match shape of expected value (@see https://reactjs.org/docs/context.html#updating-context-from-a-nested-component)
  setFilters: (_) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- see above
  setSort: (_) => {},
  selectedEvent: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- see above
  setSelectedEvent: (_) => {},
});
