import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

import { ItemListProps } from "./interfaces";
import { useStyles } from "./styles";
import Skeleton from "@material-ui/lab/Skeleton";

/**
 * A skeleton loading placeholder for an item
 */
const ItemBone: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.itemBone}>
      <Skeleton width={24} height={24} variant="circle" />
      <Skeleton height={32} variant="text" />
    </div>
  );
};

/**
 * An zebra-striped list of clickable items, with optional checkboxes
 */
export const ItemList: React.FC<ItemListProps> = ({
  items,
  numItems = 10,
  onItemClick,
  onItemCheck,
  checkboxes,
  loading,
  renderItem,
}) => {
  const classes = useStyles();

  return (
    <List className={classes.container}>
      {loading
        ? Array(numItems)
            .fill("")
            .map((_, i) => <ItemBone key={i} />)
        : items.map((item, i) => {
            const { id, label, checked, selected } = item;
            const labelId = `checkbox-list-label-${id}`;

            const itemClasses = clsx({
              [classes.itemZebra]: i % 2,
              [classes.selected]: selected,
            });
            return (
              <ListItem
                className={itemClasses}
                key={id}
                onClick={(e) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Typescript insists that target.tagName doesn't exist, but it does
                  // @ts-ignore
                  const tag = e.target.tagName;
                  if (tag === "INPUT") return;
                  onItemClick(item);
                }}
                dense
                button
              >
                {checkboxes && (
                  <ListItemIcon>
                    <Checkbox
                      onChange={(e) => onItemCheck?.(item, e.target.checked)}
                      edge="start"
                      checked={checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                )}
                <ListItemText
                  id={labelId}
                  primary={renderItem ? renderItem(item) : label}
                />
              </ListItem>
            );
          })}
    </List>
  );
};
