import React from "react";
import { useParams } from "react-router-dom";
import { Item } from "../ItemList";
import { UserListParams } from "./interfaces";
import { UserListView } from "./views";
import { useUsersApi } from "../../hooks/use-users";
import { User } from "../../interfaces";

/**
 * Shows a list of users and allows:
 * - Navigating to an edit screen for individual accounts
 * - Deleting one or more accounts
 */
export const UserList: React.FC = () => {
  const { accountId } = useParams<UserListParams>();
  const [data, setData] = React.useState<User[]>([]);
  const { loading, error, getUsers, deleteUser, deleting } = useUsersApi();

  React.useEffect(() => {
    async function fetchData() {
      const data = await getUsers(accountId);
      setData(data ? data : []);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [accountId]);
  /**
   * Used to determine whether to show a dismissable badge
   * confirming that a delete operation succeeded
   *
   * TODO: handle error via same mechanism
   */
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);

  const handleDelete = async (items: Item[]) => {
    try {
      for (const item of items) {
        await deleteUser(item.id);
      }

      // Update the vehicle list state to remove the deleted vehicle
      setData((prevList) =>
        prevList.filter(
          (vehicle) => !items.some((item) => item.id === vehicle.id)
        )
      );

      setDeleteSuccess(true);
    } catch (error) {
      console.error("Failed to delete vehicle:", error);
      setDeleteSuccess(false);
      // Handle errors here, possibly set an error state
    }
  };

  return (
    <div>
      <UserListView
        data={data}
        accountId={accountId}
        loading={loading || deleting}
        error={error}
        onDelete={handleDelete}
        deleteSuccess={deleteSuccess}
      />
    </div>
  );
};
