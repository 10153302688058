import { createTheme } from "@material-ui/core/styles";
import { CSSProperties } from "react";

const ramAlpha = "#322c4e";
const ramAlphaLight = "#dbd7ee";
const ramBravo = "#ed6b1e";

export const theme = createTheme({
  typography: {
    button: { textTransform: "none" },
    fontFamily: "DM Sans, sans-serif",
    body1: {
      fontFamily: "DM Sans, sans-serif",
      fontSize: "0.875rem",
    },
    body2: {
      fontFamily: "DM Sans, sans-serif",
      fontSize: "0.875rem",
    },
  },
  shape: { borderRadius: 0 },
  palette: {
    background: { default: "#efefef" },
    primary: { main: ramAlpha, light: ramAlphaLight },
    secondary: { main: ramBravo, contrastText: "#efefef" },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: "outlined",
    },
  },
  /**
   * Per-component style overrides
   */
  overrides: {
    MuiTooltip: {
      tooltip: { fontSize: 14 },
    },
  },
});

// Styling helpers
export const visuallyHidden = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: "1px",
} as CSSProperties;
