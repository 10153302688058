import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Fill viewport to allow scrolling within panel
     */
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      height: `calc(100vh - ${theme.spacing(20)}px)`,

      [theme.breakpoints.up("md")]: {
        height: "100%",
      },
    },
    row: {
      width: "100%",
    },
  })
);
