import { DateTime } from "luxon";

export function randomArrayElement<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function randomEnumValue<T>(anEnum: T): T[keyof T] {
  const enumValues = (Object.keys(anEnum)
    .map((n) => Number.parseInt(n))
    .filter((n) => !Number.isNaN(n)) as unknown) as T[keyof T][];
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  const randomEnumValue = enumValues[randomIndex];
  return randomEnumValue;
}

export function coinFlip(): boolean {
  return Math.random() > 0.5;
}

export const isoDateToTime = (date: string): string => {
  const d = DateTime.fromISO(date);

  return `${d.hour.toString().padStart(2, "0")}:${d.minute
    .toString()
    .padStart(2, "0")}:${d.second.toString().padStart(2, "0")}`;
};

/**
 * Generate a random true/false value based on a given chance
 * @param chance Chance of being TRUE
 */
export function randomBool(chance: number): boolean {
  return Math.random() <= chance / 100;
}
