import React, { useContext } from "react";
import Alert from "@material-ui/lab/Alert";
import { Logo } from "../Logo";
import { useStyles } from "./styles";
import { LocalizedButton } from "../Button";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";

/**
 * Shared wrapper for auth forms
 */
export const FormWrapper: React.FC<FormWrapperProps> = ({
  error,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Logo />
        {error && <Alert severity="warning">{error}</Alert>}
      </div>

      <div className={classes.content}>{children}</div>
    </div>
  );
};

/**
 * Sign in form view
 */
export const SignInForm: React.FC = () => {
  const auth: IAuthContext = useContext(AuthContext);
  const classes = useStyles();

  return (
    <FormWrapper>
      <LocalizedButton
        className={classes.button}
        type="submit"
        fullWidth
        onClick={(e) => {
          /**
           * Don't submit the form
           */
          e.preventDefault();

          auth.login();
        }}
      >
        sign_in
      </LocalizedButton>
    </FormWrapper>
  );
};

export interface SignInFormProps {
  onSubmit: () => void;
}

export interface FormWrapperProps {
  error?: string;
}
